import { gql } from 'apollo-boost'

const ChecklistAnswerUpdateMutation = gql`
    mutation ChecklistAnswerUpdateMutation($input: ChecklistAnswerCreateCreateInput) {
        checklistAnswerCreate(input: $input) {
            errors {
                message
            }
            success
            result {
                id
                value
            }
        }
    }
`

export default ChecklistAnswerUpdateMutation