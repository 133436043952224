import React from 'react'
import renderHTML from 'react-render-html'

import LoadingPane from '../Shared/LoadingPane'
import ModalPane from '../Shared/ModalPane'

const TestRunner = (props) => {
    const { goToCredentialsRoute, handleChange, handleQuestion, startTest, state } = props
    const { countdown, exits, index, loading, isModal, mobile, questions, selected, test } = state

    let question = questions[index],
        minutes = Math.floor(countdown),
        seconds = Math.round(60 * (countdown % 1))

    return (
        <div className='test-runner-view full-screen-view'>
            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-back-button' onClick={goToCredentialsRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button' onClick={goToCredentialsRoute}>Exit</button>
                }

                {mobile ?
                    <div className='page-header-frame'>
                        <div className='page-header bold ellipsis'>{question.category.title}</div>
                        <div className='page-subheader ellipsis'>{`Question ${index + 1} of ${questions.length}`}</div>
                    </div>
                    :
                    <div className='page-header ellipsis'><b>{question.category.title}</b> {`- Question ${index + 1} of ${questions.length}`}</div>
                }
            </div>

            <div className={mobile ? 'content-frame mobile' : 'content-frame'} style={{ bottom: selected ? (mobile ? 58 : 48) : 0 }}>
                {loading ? <LoadingPane /> : null}

                <div className='question-title-frame scroll' style={{ flex: (window.innerHeight < 668) ? '1 1' : 'initial'}}>
                    {question.isHTML ?
                        <div className='question-title' id='question-title'>{renderHTML(question.title)}</div>
                        :
                        <div className='question-title' ref={ref => {
                            if (ref) {
                                let lineHeight = mobile ? 26 : 31.2,
                                    numOfLines = Math.round(ref.clientHeight / lineHeight)

                                if (numOfLines === 1) ref.classList.add('center')
                                else ref.classList.remove('center')
                            }
                        }}>{question.title}</div>
                    }
                </div>

                <div className='answers-frame scroll' style={{ display: (window.innerHeight < 415) ? 'none' : 'initial' }}>
                    <ul className='answers-list-view'>
                        {question.answersForTest.map((answer, i) => {
                            let isAnswer = (answer === selected)

                            return (
                                <li
                                    className={isAnswer ? 'answer-cell selected' : 'answer-cell'}
                                    key={i}
                                    onClick={() => handleChange('selected', answer)}
                                >
                                    <div className='checkmark-icon '>
                                        <img src={require('../../theme/assets/checkmark-icon.png')} alt='checkmark-icon' />
                                    </div>

                                    <div className='answer-label'>{answer}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className='timer-frame'>
                    <div className='timer'>{`${(minutes < 10) ? `0${minutes}` : minutes}:${(seconds < 10) ? `0${seconds}` : seconds}`}</div>
                    <div className='exits-remaining una'>{`Exits Remaining (${2 - exits})`}</div>
                </div>
            </div>

            <div className={selected ? (mobile ? 'button-frame mobile' : 'button-frame') : 'hide'}>
                <button
                    className={selected ? 'next-question-button' : 'hide'}
                    onClick={() => handleQuestion(true)}
                >Next Question</button>
            </div>

            <ModalPane
                content={hide => (
                    <div className='start-test-modal-pane'>
                        <div className='message'>{`Welcome to the ${state.title} test.`}</div>

                        <div className='submessage-frame'>
                            <div className={test.desc && test.desc.includes('__rule-1__') ? 'submessage' : 'hidden'}>IMPORTANT: Each item in this test is accompanied by an image. You will have THREE seconds to view the image before selecting an answer. After 3 seconds, the image will disappear and you will not be able to view it again.</div>

                            <div className='submessage'>You can exit the test twice and continue where you left off. If you exit a third time, the test will be marked as complete.</div>
                        </div>

                        <button className='modal-button' onClick={() => startTest(hide)}>I Understand</button>
                    </div>
                )}
                disableExit={true}
                hide={() => handleChange('isModal', false)}
                show={isModal}
            />
        </div>
    )
}

export default TestRunner