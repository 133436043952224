import React from 'react'

const ChecklistStart = (props) => {
    const { goToCredentialsRoute, handleCategory, handleChange, state } = props
    const { answers, checklist, complete, incomplete, isComplete, mobile } = state

    let data = isComplete ? complete : incomplete

    return (
        <div className='checklist-start-view full-screen-view'>
            {mobile ?
                <div className='toolbar mobile'>
                    <div className='mobile-back-button' onClick={goToCredentialsRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>

                    <div className='page-header-frame'>
                        <div className='page-header ellipsis'>{checklist.title}</div>
                        <div className='page-subheader ellipsis'>Skills Checklist</div>
                    </div>
                </div>
                :
                <div className='toolbar'>
                    <button className='back-button' onClick={goToCredentialsRoute}>Back</button>

                    <div className='page-header ellipsis'><b>{checklist.title}</b>{` - Skills Checklist`}</div>
                </div>
            }

            <div className={mobile ? 'secondary-toolbar mobile' : 'secondary-toolbar'}>
                <button 
                    className={`secondary-toolbar-button ${isComplete ? '' : 'selected'}`} 
                    onClick={() => handleChange('isComplete', false)}
                >{`Incomplete (${incomplete.length})`}</button>

                <button 
                    className={`secondary-toolbar-button ${isComplete ? 'selected' : ''}`} 
                    onClick={() => handleChange('isComplete', true)}
                >{`Complete (${complete.length})`}</button>
            </div>

            <div className={mobile ? 'content-frame mobile scroll' : 'content-frame scroll'}>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    {data.length > 0 ?
                        <ul className='categories-list-view'>
                            {data.map(category => {
                                const { id, title } = category

                                let questions = category.sections.map(s => s.questions).flat(),
                                    answered = questions.filter(q => answers[q.id])

                                return isComplete ?
                                    <li className='category-cell completed' key={id}>
                                        <div className='category-label bold ellipsis'>{title}</div>

                                        <div className='category-right-frame'>
                                            <div className='category-label'>{`${answered.length}/${questions.length}`}</div>
                                        </div>
                                    </li>
                                    :
                                    <li className='category-cell pointer' key={id} onClick={() => handleCategory(category)}>
                                        <div className='category-label bold ellipsis'>{title}</div>

                                        <div className='category-right-frame'>
                                            <div className='category-label'>{`${answered.length}/${questions.length}`}</div>

                                            <div className='arrow-detail-icon'>
                                                <img src={require('../../theme/assets/arrow-detail-icon-2x.png')} alt='arrow-detail-icon' />
                                            </div>
                                        </div>
                                    </li>
                            })}
                        </ul>
                        :
                        <div className='layout-center empty-label'>There are no {isComplete ? 'completed' : 'incomplete'} categories to display.</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ChecklistStart