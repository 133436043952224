import React from 'react'

import _ from 'lodash'

import STRINGS from '../../../../../localization'

const VaricellaDeclination = (props) => {
    const { state: { kind } } = props

    return (
        <div className='key-value-frame full'>
            <div className='form-frame'>
                <div className='form-name'>{STRINGS[_.camelCase(kind)]}</div>

                <div className='form-label bold line-break'>Declination of Immunization:</div>

                <div className='form-label'>I understand that due to my occupational exposure to aerosol transmissible diseases, I may be at risk of acquiring infection with varicella zoster virus (chickenpox). I have been given the opportunity to be vaccinated against this deisease or pathogen, however, I decline the vaccination at this time. I understand that by declining this vaccine, I continue to be at risk of acquiring chickenpox, a serious disease.</div>
            </div>

        </div>
    )
}

export default VaricellaDeclination