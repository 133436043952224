import React from "react";

import moment from "moment";

import DatePicker from "../../../Shared/DatePicker";
import LoadingPane from "../../../Shared/LoadingPane";
import ModalPane from "../../../Shared/ModalPane";
import SelectMenu from "../../../Shared/SelectMenu";

import STRINGS from "../../../../localization";

import TB from "./TB";
import FileInput from "../../../Control/FileInput";
import DocumentDisplay from "../../../Control/DocumentDisplay";

const PPD = (props) => {
  return props.state.edit ? <Edit {...props} /> : <Read {...props} />;
};

const Read = (props) => {
  const { back, documentRemove, handleChange, state, types } = props;
  const {
    expiresOn,
    loading,
    isModal,
    imageUrl,
    imageBackUrl,
    issuedOn,
    mobile,
    title,
    verificationData,
    user,
  } = state;

  let ppd = types.find((o) => o.id === title);

  return (
    <div className="document-read-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${STRINGS.ppd}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className="mobile-button delete bg-una"
              onClick={() => handleChange("isModal", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/trash-can-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>

            <div
              className="mobile-button edit bg-grey-2"
              onClick={() => handleChange("edit", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/edit-mobile-button-teal-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className="save-button bg-una capitalize"
              onClick={() => handleChange("isModal", true)}
            >
              {STRINGS.delete}
            </button>

            <button
              className="save-button bg-aqua capitalize"
              onClick={() => handleChange("edit", true)}
            >
              {STRINGS.edit}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame scroll">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.type}</div>

            {ppd ? (
              <div className="value-label capitalize bold">{ppd.value}</div>
            ) : (
              <div className="value-label capitalize hint italic">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame empty" />

          <div className="read-value-frame empty" />

          {title === "tb_skin_test" ? (
            <div className="flex-frame">
              <div className="read-value-frame">
                <div className="value-label capitalize">{STRINGS.result}</div>
                <div className="value-label capitalize bold">
                  {verificationData.result1}
                </div>
              </div>

              <div className="read-value-frame">
                <div className="value-label capitalize">
                  {STRINGS.dateReceived}
                </div>
                <div className="value-label capitalize bold">
                  {moment(issuedOn).format("MMM DD, YYYY")}
                </div>
              </div>

              <div className="read-value-frame">
                <div className="value-label capitalize">
                  {STRINGS.issueExpireDate}
                </div>
                <div className="value-label capitalize bold">
                  {moment(expiresOn).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-frame">
              <div className="read-value-frame">
                <div className="value-label capitalize">{`${STRINGS.result} ${STRINGS.of} 1st ${STRINGS.injection}`}</div>
                <div className="value-label capitalize bold">
                  {verificationData.result1}
                </div>
              </div>

              <div className="read-value-frame">
                <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 1st ${STRINGS.injection}`}</div>
                <div className="value-label capitalize bold">
                  {moment(issuedOn).format("MMM DD, YYYY")}
                </div>
              </div>

              <div className="read-value-frame empty" />

              <div className="read-value-frame">
                <div className="value-label capitalize">{`${STRINGS.result} ${STRINGS.of} 2nd ${STRINGS.injection}`}</div>
                <div className="value-label capitalize bold">
                  {verificationData.result2}
                </div>
              </div>

              <div className="read-value-frame">
                <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 2nd ${STRINGS.injection}`}</div>
                <div className="value-label capitalize bold">
                  {moment(verificationData.date2).format("MMM DD, YYYY")}
                </div>
              </div>

              <div className="read-value-frame">
                <div className="value-label capitalize">
                  {STRINGS.issueExpireDate}
                </div>
                <div className="value-label capitalize bold">
                  {moment(expiresOn).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          )}

          <div className="document-images-frame">
            <DocumentDisplay
              docUrl={imageUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title}`}
            />

            <DocumentDisplay
              docUrl={imageBackUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title} back`}
            />
          </div>
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.documentDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.cancel}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.delete}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange("isModal", false)}
      />
    </div>
  );
};

const Edit = (props) => {
  const { back, handleChange, match, save, state, types } = props;
  const { expiresOn, issuedOn, loading, mobile, title, verificationData } =
    state;
  const { date2, result1, result2 } = verificationData;

  let existing = match.params.document !== "create",
    valid =
      title === "tb_skin_test"
        ? title && result1 && issuedOn && expiresOn
        : title && result1 && result2 && date2 && issuedOn && expiresOn;

  return (
    <div className="document-edit-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div
            className="mobile-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button
            className="back-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${
          existing ? STRINGS.edit : STRINGS.add
        } ${STRINGS.ppd}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className={`mobile-button save ${
                valid ? "bg-aqua" : "bg-disabled"
              }`}
              onClick={save}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/button-checkmark-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className={`save-button ${valid ? "bg-aqua" : "bg-disabled"}`}
              onClick={save}
            >
              {STRINGS.save}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="key-value-frame">
            <div className="value-label capitalize">{STRINGS.type}</div>

            <SelectMenu
              className="key-value-menu"
              onChange={(e) => handleChange("title", e.target.value)}
              options={types}
              placeholder={`${STRINGS.select} ${STRINGS.type}`}
              value={title || "hint"}
            />
          </div>

          <div className="key-value-frame empty" />

          <div className="key-value-frame empty" />

          {renderContentView(props)}
        </div>
      </div>
    </div>
  );
};

function renderContentView(props) {
  const { handleChange, state } = props;
  const {
    expiresOn,
    image,
    imageBack,
    imageUrl,
    imageBackUrl,
    issuedOn,
    title,
    verificationData,
  } = state;

  if (title === "tb_skin_test") {
    return <TB {...props} />;
  } else {
    return (
      <div className={title ? "flex-frame" : "hidden"}>
        <div className="key-value-frame">
          <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 1st ${STRINGS.injection}`}</div>

          <DatePicker
            defaultValue={issuedOn}
            onChange={(date) => handleChange("issuedOn", date)}
          />
        </div>

        <div className="key-value-frame">
          <div className="value-label capitalize">{`${STRINGS.result} ${STRINGS.of} 1st ${STRINGS.injection}`}</div>

          <SelectMenu
            className="key-value-menu"
            onChange={(e) =>
              handleChange("verificationData.result1", e.target.value)
            }
            options={[
              { id: "positive", value: "Positive" },
              { id: "negative", value: "Negative" },
            ]}
            placeholder={`${STRINGS.select} ${STRINGS.answer}`}
            value={verificationData.result1 || "hint"}
          />
        </div>

        <div className="key-value-frame empty" />

        <div className="key-value-frame">
          <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 2nd ${STRINGS.injection}`}</div>

          <DatePicker
            defaultValue={verificationData.date2}
            onChange={(date) => handleChange("verificationData.date2", date)}
          />
        </div>

        <div className="key-value-frame">
          <div className="value-label capitalize">{`${STRINGS.result} ${STRINGS.of} 2nd ${STRINGS.injection}`}</div>

          <SelectMenu
            className="key-value-menu"
            onChange={(e) =>
              handleChange("verificationData.result2", e.target.value)
            }
            options={[
              { id: "positive", value: "Positive" },
              { id: "negative", value: "Negative" },
            ]}
            placeholder={`${STRINGS.select} ${STRINGS.answer}`}
            value={verificationData.result2 || "hint"}
          />
        </div>

        <div className="key-value-frame">
          <div className="value-label capitalize">
            {STRINGS.issueExpireDate}
          </div>

          <DatePicker
            defaultValue={expiresOn}
            future={true}
            futureAmt={5}
            onChange={(date) => handleChange("expiresOn", date)}
          />
        </div>

        <div className="key-value-frame">
          <div className="value-label capitalize">{`${STRINGS.image} 1`}</div>
          <FileInput
            handleChange={handleChange}
            fileType={"image"}
            image={image}
            imageUrl={imageUrl}
            strings={STRINGS}
          />
        </div>

        <div className="key-value-frame">
          <div className="value-label capitalize">{`${STRINGS.image} 2`}</div>
          <FileInput
            handleChange={handleChange}
            fileType={"imageBack"}
            image={imageBack}
            imageUrl={imageBackUrl}
            strings={STRINGS}
          />
        </div>

        <div className="key-value-frame empty" />
      </div>
    );
  }
}

export default PPD;
