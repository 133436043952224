import React from 'react'
import Select from 'react-select'

const SelectMenu = (props) => {
    const { controlStyle, onChange, options, placeholder, value } = props

    let _value = options.find(o => (o.value === value || o.label === value))

    return (
        <Select
            isClearable={false}
            isSearchable={false}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            styles={{
                control: (provided) => ({
                    ...provided,
                    ...controlStyle,
                    height: 32,
                    minHeight: 32,
                    border: '1px solid #DBDBDB'
                }),
                indicatorSeparator: () => ({
                    display: 'none'
                }),
                menuList: (provided) => ({
                    ...provided,
                    fontSize: 12
                }),
                placeholder: () => ({
                    fontSize: 12,
                    color: '#484848'
                }),
                singleValue: () => ({
                    fontSize: 12,
                    color: '#484848'
                })
            }}
            value={_value}
        />
    )
}

export default SelectMenu