import React from "react";

import _ from "lodash";

import Other from "../../../../components/Documents/Document/Other";

export default class OtherContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.match.params.document === "create",
      isModal: false,
      loading: false,
      user: props.user,
    };
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange("mobile", this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide)
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = (valid) => {
    const { save, saveAttachment } = this.props;
    const { kind, image, imageBack, /*issuingState,*/ issuedOn,expiresOn, title } =
      this.state;

    let data = {
      documentNumber: "Other",
      title: _.startCase(title),
      kind,
      issuedOn,
      issuingState: "none",
      expiresOn
    };

    if (valid) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          if (imageBack) data.imageBack = await saveAttachment(imageBack);

          save(data);
        }
      );
    }
  };

  render() {
    return (
      <Other
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}
