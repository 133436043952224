import { gql } from 'apollo-boost'

const SessionLoginMutation = gql`
    mutation SessionLoginMutation($input: SessionLoginLoginInput) {
        sessionLogin(input: $input) {
            errors {
                message
            }
            token
            user {
                id
                roles {
                    org {
                        id
                    }
                }
            }
        }
    }
`

export default SessionLoginMutation