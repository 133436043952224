import { gql } from 'apollo-boost'

const AttachmentUpdateMutation = gql`
    mutation AttachmentUpdateMutation($input: AttachmentUpdateUpdateInput) {
        attachmentUpdate(input: $input) {
            errors {
                message
            }
            success
            result {
                id
                url
            }
        }
    }
`

export default AttachmentUpdateMutation