import { gql } from 'apollo-boost'

const UserWorkAddMutation = gql`
    mutation UserWorkAddMutation($input: UserWorkExperienceAddInput) {
        userWorkAdd(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserWorkAddMutation