import React from "react";

import _ from "lodash";
import moment from "moment";

import Vaccination from "../../../../components/Documents/Document/Vaccination";

import { vaccinationTypes as types } from "../../../../utils/Constants";

import STRINGS from "../../../../localization";

export default class VaccinationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.match.params.document === "create",
      isModal: false,
      loading: false,
      verificationData: props.state.verificationData || {},
      user: props.user,
    };
  }

  componentDidMount() {
    const { match, state } = this.props;
    const { title, verificationData } = state;

    if (match.params.document !== "create") {
      this.setState({
        title: types.find((o) => title.includes(o.value)).id,
        verificationData,
      });
    }
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange("mobile", this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide)
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  handleTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  save = (valid) => {
    const { save, saveAttachment } = this.props;
    const { kind, image, imageBack, title, verificationData } = this.state;
    const { image1 } = verificationData;

    let data = {
      documentNumber: "n/a",
      kind,
      issuedOn: moment().format("MMM D, YYYY"),
      issuingState: "n/a",
      title: `${types.find((o) => o.id === title).value} ${_.capitalize(
        STRINGS.vaccination
      )}`,
      verificationData,
    };

    if (valid) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          if (imageBack) data.imageBack = await saveAttachment(imageBack);

          if (image1) {
            let result = await saveAttachment(image1);

            delete data.verificationData.image1;

            data.verificationData.image1Url =
              result.metadata.cloudinary.secure_url;
          }

          if (title !== "hepB") delete data.verificationData.date3;

          save(data);
        }
      );
    }
  };

  render() {
    return (
      <Vaccination
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        handleTitle={this.handleTitle}
        save={this.save}
        state={this.state}
        types={types}
      />
    );
  }
}
