import React from "react";

const FileInput = (props) => {
  const { handleChange, fileType, image, imageUrl, strings } = props
  return (
    <div className={`key-value-file-input ${image || imageUrl ? '' : 'bg-placeholder'}`}>
      <input
        className="file-input"
        onChange={(e) => {
          const supportedTypes = [
            "image/png",
            "image/jpeg",
            "application/pdf",
          ];
          const file = e.target.files[0];
          const reader = new FileReader();

          reader.onload = (response) => {
            if (supportedTypes.includes(file.type)) {
              handleChange(fileType, {
                base64: response.target.result,
                name: file.name,
                type: file.type,
              });
            } else {
              window.alert(`${file.type} is not supported.`);
            }
          };

          reader.readAsDataURL(file);
        }}
        type="file"
      />
      <div className='file-label ellipsis'>{image ? image.name : (imageUrl ? imageUrl : strings.selectFile)}</div>

      <button className={`file-button capitalize ${image ? 'bg-grey-2' : 'bg-aqua'}`}>{strings.chooseFile}</button>
    </div>
  );
};

export default FileInput
