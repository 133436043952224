import { gql } from 'apollo-boost'

const UserSpecialtyUpdateMutation = gql`
    mutation UserSpecialtyUpdateMutation($input: UserSpecialtyUpdateInput) {
        userSpecialtyUpdate(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserSpecialtyUpdateMutation