import { gql } from 'apollo-boost'

const AccountQuery = gql`
    query AccountQuery($id: ID) {
        specialties {
            id
            name
        }
        user(id: $id) {
            id
            attachments(labels: ["avatar"]) {
                id
                label
                url
            }
            bio
            email
            firstName
            homeStreet1
            homeStreet2
            homeCity
            homeState
            homeZip
            lastName
            phoneNumber
            profileCompletion
            roles {
                id
                role
                org {
                    id
                    attachment(label: "pdf_logo") {
                        id
                        url
                    }
                    brandColor
                    title
                }
            }
            specialties {
                id
                isPrimary
                name
                specialty {
                    id
                }
            }
        }
    }
`

export default AccountQuery