import { gql } from 'apollo-boost'

const UserCourseStartMutation = gql`
    mutation UserCourseStartMutation($input: IDInput) {
        userCourseStart(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserCourseStartMutation