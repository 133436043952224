import React from 'react'

const ReferenceComplete = (props) => {
    const { firstName, lastName, mobile } = props.state
    
    return (
        <div className={`${mobile ? 'reference-complete-mobile-view' : 'reference-complete-view'} full-screen-view`}>
            <div className='centered-frame'>
                <div className='una-logo'>
                    <img src={require('../../theme/assets/una-logo-2x.png')} alt='una-logo' />
                </div>

                <div className='success-label bold'>{`${firstName} ${lastName},`}</div>
                <div className='success-label'>Thank you for completing the reference form.</div>
            </div>
        </div>
    )
}

export default ReferenceComplete