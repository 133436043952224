import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import Cookie from "../../utils/js-cookie.overrides";

import SessionLoginMutation from "../../graphql/mutations/Session/Login";

import Login from "../../components/Login";

import LoadingPane from "../../components/Shared/LoadingPane";

const refs = {};

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: window.innerWidth < 1000,
      onload: true,
    };
  }

  componentDidMount() {
    const { history, sessionLoginMutation } = this.props;

    let searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has("e") && searchParams.has("p")) {
      let email = decodeURI(searchParams.get("e")),
        password = searchParams.get("p");

      sessionLoginMutation({
        variables: {
          input: {
            email,
            password,
          },
        },
      }).then((response) => {
        const { user } = response.data.sessionLogin;

        if (user) {
          history.push(`/getting_started${history.location.search}`);
        } else {
          history.push(history.location.pathname);

          this.handleChange("onload", false);
        }
      });
    } else {
      this.handleChange("onload", false);

      window.addEventListener("resize", this.handleResize);
      window.addEventListener("keypress", this.handleKeyPress);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("keypress", this.handleKeyPress);
  }

  forgotPassword = () => {
    this.props.history.push("/forgot_password");
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") this.login();
  };

  handleResize = () => {
    this.handleChange("mobile", window.innerWidth < 1000);
  };

  login = () => {
    const { sessionLoginMutation } = this.props;
    const { email, password } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        sessionLoginMutation({
          variables: {
            input: {
              email,
              password,
            },
          },
        })
          .then((response) => {
            const {
              data: { sessionLogin },
            } = response;

            if (sessionLogin && sessionLogin.user) {
              Cookie.set(
                process.env.REACT_APP_COOKIE_NAME,
                sessionLogin.user.id
              );
              Cookie.set(
                `${process.env.REACT_APP_COOKIE_NAME}-token`,
                sessionLogin.token
              );
              if (sessionLogin.user.roles[0].org.id) {
                Cookie.set('org', sessionLogin.user.roles[0].org.id);
              }
              
              window.location.reload();
            } else {
              this.setState({
                error: "Email or password is incorrect. Try again.",
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              error: "Invalid credentials. Try again.",
              loading: false,
            });
          });
      }
    );
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Login
        forgotPassword={this.forgotPassword}
        handleChange={this.handleChange}
        login={this.login}
        refs={refs}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(SessionLoginMutation, {
    name: "sessionLoginMutation",
    options: (props) => ({
      client: window.client2,
    }),
  })
)(LoginContainer);
