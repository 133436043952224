import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import Cookie from "../../utils/js-cookie.overrides";

import ReferenceQuery from "../../graphql/queries/Reference";

import ReferenceCompleteMutation from "../../graphql/mutations/Reference/Complete";
import ReferenceUpdateMutation from "../../graphql/mutations/Reference/Update";

import NotFound from "../NotFound";

import Reference from "../../components/Reference";
import ReferenceComplete from "../../components/Reference/Complete";

import LoadingPane from "../../components/Shared/LoadingPane";

const relationships = [
  { value: "supervisor", label: "Supervisor" },
  { value: "nurse_manager", label: "Nurse Manager" },
  { value: "change_nurse", label: "Change Nurse" },
  { value: "educator", label: "Educator" },
  { value: "hr_manager_/_director", label: "HR Manager/Director" },
  { value: "peer_/_co-worker", label: "Peer/Co-Worker" },
  { value: "subordinate", label: "Subordinate" },
  { value: "other", label: "Other (fill in)" },
];

class ReferenceContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      isValid: true,
      mobile: window.innerWidth < 850,
      onload: true,
      expiredReference: false
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    const {
      referenceQuery: { loading, reference },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading && !reference) {
      this.setState({
        onload: false,
        isError: true,
        expiredReference: true
      });
    } else if (onload && !loading) {
      let relationship = relationships.find(
        (r) => r.value === reference.title || r.label === reference.title
      );

      this.setState({
        ...reference,
        onload: false,
        other: relationship ? "" : reference.title,
        relationship:
          relationship === undefined ? null : relationship.value || "other",
      });
    }
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleResize = (e) => {
    this.handleChange("mobile", window.innerWidth < 850);
  };

  referenceComplete = () => {
    const { referenceCompleteMutation } = this.props;
    const { id } = this.state;

    referenceCompleteMutation({
      variables: {
        input: {
          id,
        },
      },
    })
      .then((response) => {
        const {
          data: {
            referenceComplete: { result },
          },
        } = response;

        this.setState({
          loading: false,
          status: result.status,
        });
      })
      .catch((e) => {
        this.handleChange("loading", false);

        window.alert("There was a server issue.");
      });
  };

  referenceUpdate = () => {
    const { referenceUpdateMutation } = this.props;
    const {
      clinicalCompetancy,
      communication,
      city,
      email,
      endDate,
      firstName,
      id,
      lastName,
      organization,
      eligibleForRehire,
      other,
      otherDetails,
      phone,
      professionalism,
      relationship,
      state,
      startDate,
      technologyCompetancy,
      attendancePunctuality,
    } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        referenceUpdateMutation({
          variables: {
            input: {
              id,
              firstName,
              lastName,
              email,
              title: relationship === "other" ? other : relationship,
              organization,
              city,
              state,
              startDate,
              endDate,
              phone,
              eligibleForRehire,
              professionalism: parseInt(professionalism) || 0,
              communication: parseInt(communication) || 0,
              clinicalCompetancy: parseInt(clinicalCompetancy) || 0,
              technologyCompetancy: parseInt(technologyCompetancy) || 0,
              attendancePunctuality: parseInt(attendancePunctuality) || 0,
              otherDetails,
            },
          },
        }).then((response) => {
          const {
            data: {
              referenceUpdate: { errors },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.referenceComplete();
          }
        });
      }
    );
  };

  save = () => {
    if (this.state.attendancePunctuality) {
      this.referenceUpdate();
    } else {
      window.alert("Attendance & Punctuality is required.");
    }
  };

  goToLogin = () => {
    this.props.history.push('/');
  }

  render() {
    const { id, isError, mobile, onload, status, expiredReference } = this.state;

    if (onload) {
      return <LoadingPane />;
    } else if (isError) {
      return <NotFound state={this.state} expiredReference={expiredReference} goToLogin={this.goToLogin}/>;
    } else if (status === "completed") {
      return <ReferenceComplete state={this.state} />;
    } else if (!onload && id) {
      return (
        <Reference
          {...this.props}
          handleChange={this.handleChange}
          save={this.save}
          state={this.state}
        />
      );
    } else {
      return (
        <div
          className={`${
            mobile
              ? "reference-complete-mobile-view"
              : "reference-complete-view"
          } full-screen-view`}
        >
          <div className="centered-frame">
            <div className="una-logo">
              <img
                src={require("../../theme/assets/una-logo-2x.png").default}
                alt="una-logo"
              />
            </div>

            <div className="success-label bold">Error: 404 Not Found</div>
            <div className="success-label">
              This resource is invalid or no longer available.
            </div>
          </div>
        </div>
      );
    }
  }
}

export default compose(
  graphql(ReferenceQuery, {
    name: "referenceQuery",
    options: () => {

      //take query string and set auth token cookie. to add secure token to 
      let route = window.location.pathname,
        routeParts = route.split("/"),
        reference = routeParts[2];
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      if (token) {
        Cookie.set('reference-token', token);
      }
      return {
        variables: {
          id: reference,
        },
      };
    },
  }),
  graphql(ReferenceCompleteMutation, { name: "referenceCompleteMutation" }),
  graphql(ReferenceUpdateMutation, { name: "referenceUpdateMutation" })
)(ReferenceContainer);
