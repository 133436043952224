import React from 'react'

import _ from 'lodash'

import ModalPane from '../../Shared/ModalPane'

import STRINGS from '../../../localization'

const ChangeRolePane = (props) => {
    const { goToRoleRoute, handleChange, state } = props
    const { isRole, mobile, roles, role, selected } = state

    return (
        <ModalPane
            className={`roles-view-pane ${mobile ? 'roles-view mobile' : 'roles-view'}`}
            content={hide => (
                <div className='roles-frame'>
                    <div className='header-frame'>
                        <button className='action-button' onClick={() => {
                            hide()

                            handleChange('selected', state.role)
                        }}>{STRINGS.cancel}</button>

                        <div className='header'>{STRINGS.selectRole}</div>

                        <button className={selected ? 'action-button bg-una' : 'action-button disabled'} onClick={() => goToRoleRoute(hide)}>{STRINGS.done}</button>
                    </div>

                    <ul className='roles-list-view'>
                        {_.orderBy(roles, ['role', 'org.title'], ['asc', 'asc']).map(r => {
                            const { id, org, role: name } = r
                            const { attachment, title } = org

                            let checked = (selected.id === id),
                                current = (selected.id === role.id)

                            return (
                                <li className='role-cell pointer' key={id} onClick={() => handleChange('selected', r)}>
                                    <div className='left-section'>
                                        <input
                                            className='role-radio'
                                            checked={checked}
                                            readOnly={true}
                                            type='radio'
                                        />

                                        <div className='role-details-frame'>
                                            <div className='flex-frame'>
                                                <div className='role-label'>{title}</div>
                                                <div className={(!mobile && current && checked) ? 'role-label aqua' : 'hidden'}>{STRINGS.currentlySelected}</div>
                                            </div>

                                            <div className='role-label hint'>{_.startCase(name)}</div>
                                        </div>
                                    </div>

                                    <div className='right-section'>
                                        {attachment ?
                                            <div className='company-logo'>
                                                <img className={checked ? '' : 'disabled'} src={attachment.url} alt='company-logo' />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            hide={() => handleChange('isRole', false)}
            show={isRole}
        />
    )
}

export default ChangeRolePane