import React from "react";
import { graphql } from "react-apollo";
import { Beforeunload } from "react-beforeunload";

import { flowRight as compose } from "lodash";
import Cookie from "../../utils/js-cookie.overrides";
import moment from "moment";

import TestAnswerCreateMutation from "../../graphql/mutations/Test/Answer/Create";
import TestAnswerUpdateMutation from "../../graphql/mutations/Test/Answer/Update";
import TestStartQuery from "../../graphql/queries/Test/Start";
import UserTestFinishMutation from "../../graphql/mutations/User/Test/Finish";
import UserTestStartMutation from "../../graphql/mutations/User/Test/Start";
import UserTestUpdateMutation from "../../graphql/mutations/User/Test/Update";

import TestRunner from "../../components/Test/Runner";

import LoadingPane from "../../components/Shared/LoadingPane";

var timer;
var timeout;

class TestStartContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: {},
      isComplete: false,
      isModal: false,
      mobile: window.innerWidth < 1000,
      onload: true,
    };
  }

  componentDidMount() {
    const {
      testStartQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(this.setup);
    } else {
      this.setup();
    }

    window.addEventListener("resize", this.handleResize);
    window.addEventListener("beforeunload", this.updateTestTime);
    window.addEventListener("unload", this.updateTestExit);
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    clearInterval(timer);

    window.removeEventListener("resize", this.handleResize);
  }

  setup = () => {
    const {
      match,
      testStartQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      let test = user.testTaken;

      if (test.status === "finished") {
        this.goToRoute(`/tests/${match.params.test}/complete`);
      } else if (test.exits > 2) {
        this.finishTest(test.id);
      } else {
        this.setupTest(test);
      }
    }
  };

  setupTest = (testTaken) => {
    const { answers, status, test, timeSpentSecs } = testTaken;
    const { mobile } = this.state;

    let answerHash = {},
      questions = test.categories
        .map((c) => c.questions.map((q) => ({ ...q, category: c })))
        .flat(),
      maxTime = questions.length > 0 ? questions.length * 60 : 0,
      timeRemaining = maxTime - timeSpentSecs,
      countdown = moment.duration(timeRemaining, "seconds").asMinutes(),
      isRule1 = test.desc
        ? test.desc.includes("__rule-1__")
        : false; /* rule-1 */

    answers.forEach((answer) => {
      const { question } = answer;

      answerHash[question.id] = answer;
    });

    this.setState(
      {
        ...testTaken,
        answers: answerHash,
        countdown,
        onload: false,
        index: questions.findIndex((q) => !answerHash[q.id]),
        questions: questions.map((q) => {
          let regex = /<\/?[a-z][\s\S]*>/i, // determine if its an HTML item
            regex2 = /<img[^>]*>/g, // find img element
            regex3 = /width="(.*?)"/g, // find width attribute
            isHTML = regex.test(q.title);

          return {
            ...q,
            isHTML,
            isRule1 /* rule-1 */,
            title: q.title.replace(regex2, (img) =>
              img.replace(regex3, (width) => {
                return `${isRule1
                    ? `max-width="100%" ${width}`
                    : mobile
                      ? 'width="100%"'
                      : width
                  } ${isRule1 ? ' id="rule-1"' : ""}`;
              })
            ),
          };
        }),
        secs: 0,
      },
      () => {
        if (status === "untaken") {
          this.handleChange("isModal", true);
        } else {
          const { questions, index } = this.state;

          timer = setInterval(this.handleSecs, 1000);

          if (questions[index].isRule1)
            setTimeout(() => this.useRule(1, true), 1000);
        }
      }
    );
  };

  useRule = (rule, apply) => {
    if (rule === 1) {
      let elem = document.getElementById("rule-1");
      clearTimeout(timeout);

      if (apply) {

        timeout = setTimeout(() => elem.classList.add("hide"), 4000);
      } else {
        elem.classList.remove("hide");
      }
    }
  };


  setupCategories = (categories, answers, complete) => {
    return categories.filter((c) => {
      return c.questions.every((q) =>
        complete ? answers[q.id] : !answers[q.id]
      );
    });
  };

  finishTest = (id) => {
    const { history, userTestFinishMutation } = this.props;

    userTestFinishMutation({
      variables: {
        input: {
          id,
        },
      },
    }).then((response) => {
      const {
        data: {
          userTestFinish: { errors, success },
        },
      } = response;

      if (success) {
        let searchParams = new URLSearchParams(history.location.search);

        window.removeEventListener("unload", this.updateTestExit);

        this.goToRoute(
          `/tests/${id}/complete${searchParams.get("bundle")
            ? `?bundle=${searchParams.get("bundle")}`
            : ""
          }`
        );
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  goToCredentialsRoute = () => {
    const { history, userTestFinishMutation, userTestUpdateMutation } =
      this.props;
    const { id, exits, secs } = this.state;

    userTestUpdateMutation({
      variables: {
        input: {
          id,
          exits: exits + 1,
          addSecs: secs,
        },
      },
    }).then((response) => {
      const {
        data: {
          userTestUpdate: { errors, success },
        },
      } = response;

      if (success) {
        let org = Cookie.get('org');

        history.push(
          org ? `/organizations/${org}/credentials` : "/credentials"
        );
      } else {
        window.alert(errors[0].message);
      }
    });

    if (exits + 1 > 2) {
      userTestFinishMutation({
        variables: {
          input: {
            id,
          },
        },
      });
    }
  };

  goToRoute = (route) => {
    this.props.history.push(route);
  };

  handleCategory = (category) => {
    this.setState({
      category,
      qIndex: 0,
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleQuestion = (forward) => {
    const { testAnswerCreateMutation, testAnswerUpdateMutation } = this.props;
    const { answers, id, index, questions, secs, selected, timeSpentSecs } =
      this.state;
    let question = questions[index],
      nextIndex = forward ? index + 1 : index - 1;

    if (questions != undefined && questions[nextIndex] != undefined) {

      if (questions[nextIndex].isHTML) {
        document.getElementById("question-title").innerHTML = questions[nextIndex].title;
      }
    }
    this.setState(
      {
        loading: true,
      },

      () => {

        let existing = answers[question.id],
          mutation = existing
            ? testAnswerUpdateMutation
            : testAnswerCreateMutation,

          data = existing
            ? {
              id: existing.id,
              value: selected,
            }

            : {
              userTestId: id,
              questionId: question.id,
              value: selected,
              secs,
            };

        mutation({
          variables: {
            input: data,
          },

        }).then((response) => {
          const key = Object.keys(response.data)[0];
          const { errors, success } = response.data[key];

          if (success) {

            if (nextIndex === questions.length) {
              this.finishTest(id);

            } else {

              this.setState(
                {
                  index: nextIndex,
                  secs: 0,
                  selected: null,
                  timeSpentSecs: timeSpentSecs + secs,
                },

                () => {

                  /* rule-1 */
                  // reshow image

                  if (question.isRule1) this.useRule(1, false);
                  // read timer

                  if (questions[nextIndex].isRule1) this.useRule(1, true);

                  this.setState({ loading: false });

                }

              );

            }

          } else {

            this.handleChange("loading", false);
            window.alert(errors[0].message);

          }

        });

      }

    );

  };

  handleResize = () => {
    this.handleChange("mobile", window.innerWidth < 1000);
  };

  handleSecs = () => {
    const { id, questions, secs, timeSpentSecs } = this.state;

    let maxTime = questions.length * 60,
      timeRemaining = maxTime - (secs + timeSpentSecs),
      countdown = moment.duration(timeRemaining, "seconds").asMinutes();

    if (countdown >= 0) {
      this.setState({
        countdown,
        secs: this.state.secs + 1,
      });
    } else {
      this.finishTest(id);
    }
  };

  startTest = (hide) => {
    const { userTestStartMutation } = this.props;
    const { id, index, questions } = this.state;

    userTestStartMutation({
      variables: {
        input: {
          id,
        },
      },
    }).then((response) => {
      const {
        data: {
          userTestStart: { errors, result, success },
        },
      } = response;

      if (success) {
        this.handleChange("status", result.status);

        hide();

        timer = setInterval(this.handleSecs, 1000);

        /* rule-1 */
        setTimeout(() => {
          if (questions[index].isRule1) this.useRule(1, true);
        }, 500);
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  updateTestTime = (e) => {
    const { userTestUpdateMutation } = this.props;
    const { id, secs, status } = this.state;

    if (id && status === "started") {
      userTestUpdateMutation({
        variables: {
          input: {
            id,
            addSecs: secs,
          },
        },
      });
    }

    if (e) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  updateTestExit = () => {
    const { userTestUpdateMutation } = this.props;
    const { id, exits, status } = this.state;

    if (status === "started") {
      userTestUpdateMutation({
        variables: {
          input: {
            id,
            exits: exits + 1,
          },
        },
      });
    }
  };

  render() {
    const { onload } = this.state;

    return onload ? (
      <LoadingPane />
    ) : (
      <Beforeunload onBeforeunload={this.updateTestExit}>
        <TestRunner
          goToCredentialsRoute={this.goToCredentialsRoute}
          handleChange={this.handleChange}
          handleQuestion={this.handleQuestion}
          startTest={this.startTest}
          state={this.state}
        />
      </Beforeunload>
    );
  }
}

export default compose(
  graphql(TestStartQuery, {
    name: "testStartQuery",
    options: (props) => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
        test: props.match.params.test,
      },
    }),
  }),
  graphql(TestAnswerCreateMutation, { name: "testAnswerCreateMutation" }),
  graphql(TestAnswerUpdateMutation, { name: "testAnswerUpdateMutation" }),
  graphql(UserTestFinishMutation, { name: "userTestFinishMutation" }),
  graphql(UserTestStartMutation, { name: "userTestStartMutation" }),
  graphql(UserTestUpdateMutation, { name: "userTestUpdateMutation" })
)(TestStartContainer);
