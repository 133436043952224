import React from 'react'

import _ from 'lodash'

import Checkbox from '../../Shared/Checkbox'
import ModalPane from '../../Shared/ModalPane'

import STRINGS from '../../../localization'

const CredentialsDocumentPane = (props) => {
    const { goToNewDocumentRoute, handleChange, state } = props
    const { isModal, mobile, selected2 } = state

    let documents = [
        { route: 'annual_tb_attestation', title: STRINGS.annualTbAttestation },
        { route: 'annual_education_attestation', title: STRINGS.annualEducationAttestation },
        { route: 'award', title: STRINGS.award },
        { route: 'x_ray', title: `Chest ${STRINGS.xRay}` },
        { route: 'drivers_license', title: STRINGS.driversLicense },
        { route: 'hep_b_declination', title: STRINGS.hepBDeclination },
        { route: 'flu_declination', title: STRINGS.fluDeclination },
        { route: 'letter_of_recommendation', title: STRINGS.letterOfRecommendation },
        { route: 'other', title: STRINGS.other },
        { route: 'passport', title: STRINGS.passport },
        { route: 'physical_health_statement', title: STRINGS.physicalHealthStatement },
        { route: 'ppd', title: STRINGS.ppd },
        { route: 'preliminary_background_release', title: STRINGS.preliminaryBackgroundRelease },
        { route: 'resume', title: STRINGS.resume_cv },
        { route: 'social_security_card', title: STRINGS.ssCard },
        { route: 'titer', title: STRINGS.titer },
        { route: 'vaccination', title: STRINGS.vaccination },
        { route: 'varicella_declination', title: STRINGS.varicellaDeclination }
    ]

    return (
        <ModalPane
            className='documents-modal-pane'
            content={hide => (
                <div className='credentials-document-pane'>
                    <div className='modal-toolbar'>
                        {mobile ?
                            <div className='mobile-modal-toolbar-button bg-grey-2' onClick={hide}>
                                <img className='cancel-icon' src={require('../../../theme/assets/buttons/button-x-icon-2x.png')} alt='modal-button-icon' />
                            </div>
                            :
                            <button className='modal-toolbar-button bg-grey-2' onClick={hide}>{STRINGS.cancel}</button>
                        }

                        <div className='modal-toolbar-header capitalize'>{`${STRINGS.select} ${STRINGS.document}`}</div>

                        {mobile ?
                            <div className={`mobile-modal-toolbar-button ${state.selected2 ? 'bg-aqua' : 'bg-disabled'}`} onClick={() => goToNewDocumentRoute(hide)}>
                                <img className='checkmark-icon' src={require('../../../theme/assets/buttons/button-checkmark-icon-2x.png')} alt='modal-button-icon' />
                            </div>
                            :
                            <button className={`modal-toolbar-button ${state.selected2 ? 'bg-aqua' : 'bg-disabled'}`} onClick={() => goToNewDocumentRoute(hide)}>{STRINGS.done}</button>
                        }
                    </div>

                    <ul className='documents-list-view scroll'>
                        {documents.map((document, i) => {
                            const { title } = document

                            let checked = _.isEqual(selected2, document)

                            return (
                                <li
                                    className='document-cell pointer'
                                    key={i}
                                    onClick={() => handleChange('selected2', checked ? null : document)}
                                >
                                    <Checkbox
                                        checked={checked}
                                        readOnly={true}
                                    />

                                    <div className='document-title capitalize'>{title}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            show={isModal}
            hide={() => {
                handleChange('selected', null)
                handleChange('isModal', false)
            }}
        />
    )
}

export default CredentialsDocumentPane