import React from 'react'

const refs = {}

export default class AutoComplete extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            google: window.google
        }
    }

    componentDidMount() {
        let autoComplete = new window.google.maps.places.Autocomplete(refs.input)

        autoComplete.addListener('place_changed', (e) => {
            let place = autoComplete.getPlace(),
                addressParts = place.address_components,
                city = addressParts.find(o => o.types.includes('locality')),
                state = addressParts.find(o => o.types.includes('administrative_area_level_1'))

            this.props.onChange(place.name, city.short_name, state.short_name)
        })
    }

    render() {
        const { className, placeholder, defaultValue } = this.props

        return (
            <div className='autocomplete-input'>
                <input 
                    className={className}
                    defaultValue={defaultValue}
                    placeholder={placeholder || 'Enter location'}
                    ref={ref => refs.input = ref}
                />
            </div>
        )
    }
}