import { gql } from "apollo-boost";

const PaymentQuery = gql`
query Payment($paymentId: String){
  payment(paymentId: $paymentId){
    clientSecret
    productMetadata{
      id
      amount
      title
      creditEarn
    }
  }
}
`;

export default PaymentQuery;