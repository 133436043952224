import React from 'react'

import _ from 'lodash'

import STRINGS from '../../../../../localization'

const FluDeclination = (props) => {
    const { state: { kind } } = props

    let arr = [
        'I understand that, by refusing such vaccination, it is my professional licensing obligation to wear a surgical mask during each direct patient contract in the performance of my professional duties at any health care facility during any declared period in which flu is widespread.  The health care facility is required to provide the surgical face masks.',
        'I understand that the consequence for failing to do so shall result in a one hundred-dollar ($100) fine for each violation.',
        'Failing to do so may also result in a complaint of Unprofessional Conduct being presented to the licensing board that has authority over my professional license.',
        'I understand that such licensing complaint, if proven, may result in a sanction such as reprimand, or suspension or revocation of my professional license.',
        'No healthcare worker shall be required to explain his or her refusal to obtain an annual seasonal influenza vaccination, nor shall any health care facility inquire into the basis of such refusal.',
        'I understand that this Declination is required annually and will be submitted to any client facility/health care facility I am assigned to.',
    ]

    return (
        <div className='key-value-frame full'>
            <div className='form-frame'>
                <div className='form-name'>{STRINGS[_.camelCase(kind)]}</div>

                <div className='form-label bold'>I acknowledge that I understand the following:</div>

                <ul className='form-list-view'>
                    {arr.map((o, i) => {
                        return (
                            <li className='form-label' key={i}>{o}</li>
                        )
                    })}
                </ul>

                <div className='form-label bold line-break'>New York:</div>

                <div className='form-label italic line-break'>
                    Any employee in New York is required to abide by the Public Health Law. 
                    The law requires that during the influenza season healthcare workers not vaccinated against the flu to wear a surgical mask while in areas where patients or residents may be present.
                    This would include, but not be limited to, patient rooms, nurses' stations, hallways and elevators where patients might be present, cafeterias if patients may be present (except when the unvaccinated healthcare worker is eating), and patients' homes when providing home care.
                    As per the regulation masks should be changed after leaving the room or completing care of a patient/resident on isolation precautions, whenever it is soiled or might have become soiled, and per the protocol of the facility/residence/agency.
                </div>

                <div className='form-label italic line-break'>
                    Other states or client facilities may enact the same requirements as the New York regulation. Employees are required to abide by the policies and procedures in their respective state.
                </div>
            </div>

        </div>
    )
}

export default FluDeclination