import React from 'react'

import _ from 'lodash'

const ChecklistSection = (props) => {
    const { goToCategoryRoute, match, state } = props
    const { checklist, answers, mobile } = state

    let category = checklist.categories.find(c => c.id === match.params.category),
        section = category.sections.find(s => s.id === match.params.section)
        
    return (
        <div className='checklist-view full-screen-view'>
            {mobile ?
                <div className='toolbar mobile'>
                    <div className='mobile-back-button' onClick={goToCategoryRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-back-arrow-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>

                    <div className='page-header ellipsis'>{section.title}</div>
                </div>
                :
                <div className='toolbar'>
                    <button className='back-button' onClick={goToCategoryRoute}>Back</button>

                    <div className='page-header ellipsis'>{section.title}</div>
                </div>
            }

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='section-frame'>
                        <div className='section-header'>overall score</div>

                        <div className='score-frame'>
                            <div className='score-label'>{section.score.toFixed(1)}</div>
                        </div>
                    </div>

                    <div className='section-frame categories'>
                        <div className='section-header'>item breakdown</div>

                        <ul className='categories-list-view'>
                            {_.orderBy(section.questions, ['rank']).map(question => {
                                const { id, title } = question

                                let score = answers[id]

                                return (
                                    <li className='category-cell' key={id} style={{ padding: '7.5px 2.5%' }}>
                                        <div className={mobile ? 'category-label bold' : 'category-label ellipsis bold'}>{title}</div>
                                        <div className='question-label' style={{ background: questionColor(score) }}>{score}</div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

// function scoreColor(score) {
//     let color, label

//     if (score > 50 && score <= 80) {
//         color = '#24A4DB'
//         label = 'Good'
//     } else if (score > 80) {
//         color = '#4DD865'
//         label = 'Excellent'
//     } else if (score < 20) {
//         color = '#FF5A5F'
//         label = 'Poor'
//     } else {
//         color = '#EB903D'
//         label = 'Fair'
//     }

//     return <div className='score-label' style={{ color, marginLeft: 5 }}>{label}</div>
// }

function questionColor(value) {
    let score = parseInt(value)

    if (score === 1) {
        return '#FF5A5F'
    } else if (score === 2) {
        return '#EB903D'
    } else if (score === 3) {
        return '#24A4DB'
    } else {
        return '#4DD865'
    }

}

export default ChecklistSection