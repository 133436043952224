import React from 'react'

import LoadingPane from '../Shared/LoadingPane'

import ChangePasswordPane from './panes/ChangePassword'
import ChangeRolePane from './panes/ChangeRole'
import { useQuery } from "react-apollo";

import Navigation from '../Credentials/Navigation'
import SubscriptionsList from "../../graphql/queries/Subscriptions";


const Account = (props) => {
    const { goToEditAccountRoute, handleChange, handleRole, logout, state, hideLogout } = props
    const { bio, email, firstName, homeStreet1, homeStreet2, homeCity, homeState, homeZip, lastName, mobile, phoneNumber, profilePicture, roles, specialties } = state

    let specialty = specialties.find(s => s.isPrimary)

    const { data } = useQuery(SubscriptionsList, {
        variables: { status: ["active"] },
      });
    
      let subscription = null;
      if (data && data.subscriptions && data.subscriptions.edges.length > 0) {
        subscription = data.subscriptions.edges[0].node;
      }
    

    return (
        <div className='account-view full-screen-view'>
            {state.loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <Navigation {...props}  subscription={subscription} />

            <div className={`content-frame scroll ${mobile ? 'mobile' : ''}`} style={{ top: mobile ? 48 : 60 }}>
                <div className='document-frame'>
                    <div className='header-frame'>
                        <div className='header'>Account Details</div>

                        {mobile ?
                            <div className='edit-account-button' onClick={goToEditAccountRoute}>
                                <img src={require('../../theme/assets/edit-mobile-button-teal-2x.png')} alt='edit-button-icon' />
                            </div>
                            :
                            <div>
                                <button className='edit-account-button bg-aqua' onClick={goToEditAccountRoute}>Edit Account</button>
                            </div>
                        }
                    </div>

                    <div className='profile-picture-frame'>
                        {profilePicture ?
                            <div className='profile-picture-icon'>
                                <img src={profilePicture.url} alt='profile-pic-icon' />
                            </div>
                            :
                            <div className='profile-picture-icon'>
                                <div className='initials'>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</div>
                            </div>
                        }
                    </div>

                    <div className='profile-details-frame'>
                        <div className='key-value-frame'>
                            <div className='value-label'>First Name</div>
                            <div className='value-label bold'>{firstName || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Last Name</div>
                            <div className='value-label bold'>{lastName || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Primary Specialty</div>
                            <div className='value-label bold'>{specialty ? specialty.name : ''}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Email</div>
                            <div className='value-label bold'>{email || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Phone</div>
                            <div className='value-label bold'>{phoneNumber || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Street 1</div>
                            <div className='value-label bold'>{homeStreet1 || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Street 2</div>
                            <div className='value-label bold'>{homeStreet2 || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>City</div>
                            <div className='value-label bold'>{homeCity || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>State</div>
                            <div className='value-label bold'>{homeState || '-'}</div>
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Zip</div>
                            <div className='value-label bold'>{homeZip || '-'}</div>
                        </div>

                        <div className='key-value-frame bio' style={{ width: '100%' }}>
                            <div className='value-label'>Full Bio</div>
                            <div className='value-label bold'>{bio || '-'}</div>
                        </div>
                    </div>

                    <div className='button-frame'>
                        <div className='left-section'>
                            <button className='change-password-button aqua' onClick={() => handleChange('isModal', true)}>Change Password</button>
                            <button className={(mobile || roles.length === 1) ? 'hidden' : 'change-role-button aqua'} onClick={() => handleRole(false)}>Change Role</button>
                        </div>
                        {!hideLogout&&(
                        <button className='logout-button bg-una' onClick={logout}>Logout</button>
                        )}
                    </div>
                </div>
            </div>

            <ChangePasswordPane {...props} />
            <ChangeRolePane {...props} />
        </div>
    )
}

export default Account