import React from 'react'

import moment from 'moment'

const Course = (props) => {
    const { goToCredentialsRoute, state } = props
    const { answers, mobile, courseTaken } = state

    return (
        <div className='course-view full-screen-view'>
            <div className='toolbar'>
                <button className='back-button' onClick={goToCredentialsRoute}>Back</button>

                {mobile ?
                    <div className='page-header bold ellipsis'>{courseTaken.title}</div>
                    :
                    <div className='page-header ellipsis'><b>{courseTaken.title}</b>{` - Course - ${moment(courseTaken.endedAt).format('MMM D, YYYY')}`}</div>
                }
            </div>

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='score-details-frame'>
                        <div className='score-header'>OVERALL SCORE</div>

                        <div className='score-frame'>
                            <div className='score'>{`${courseTaken.score}% -`}</div>
                            <div className={`score ${courseTaken.passed ? 'green' : 'orange'}`}>{courseTaken.passed ? 'Pass' : 'Did Not Pass'}</div>
                        </div>
                    </div>

                    {(courseTaken.score < 60) ?
                        <div className='empty-frame'>
                            <div className='layout-center'>
                                <div className='empty-label hint'>{"We are unable to share the incorrectly answered questions for any results under 60% in an effort to preserve the security and integrity of our mandatories."}</div>
                            </div>
                        </div>
                        :
                        <div className='questions-frame'>
                            <div className='question-header'>QUESTION BREAKDOWN</div>

                            <ul className='questions-list-view'>
                                {answers.map((answer, i) => {
                                    const { isCorrect, id, question } = answer

                                    return (
                                        <li className='question-cell' key={id}>
                                            <div className='category-label bold'>{isCorrect ? `Question ${i + 1}` : question.title}</div>
                                            <div className={`correct-label ${isCorrect ? 'green' : 'una'}`}>{isCorrect ? 'Correct' : 'Incorrect'}</div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Course