import React from 'react'
import renderHTML from 'react-render-html'

import LoadingPane from '../Shared/LoadingPane'
import ModalPane from '../Shared/ModalPane'

const CourseStart = (props) => {
    const { goToCourseRoute, handleChange, handleQuestion, setupChapter, state } = props
    const { course, index, isModal, loading, mobile, questions, selected } = state

    let question = questions[index]

    return (
        <div className='course-start-view'>
            {mobile ?
                <div className='toolbar mobile'>
                    <div className='mobile-back-button' onClick={goToCourseRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>

                    <div className='page-header-frame'>
                        <div className='page-header ellipsis'>{course.title}</div>
                        <div className='page-subheader ellipsis'>{`Question ${index + 1} of ${questions.length}`}</div>
                    </div>
                </div>
                :
                <div className='toolbar'>
                    <button className='back-button' onClick={goToCourseRoute}>Exit</button>

                    <div className='page-header ellipsis'><b>{course.title}</b>{` - Question ${index + 1} of ${questions.length}`}</div>
                </div>
            }

            <div className='content-frame'>
                {loading ? <LoadingPane /> : null}

                <div className={question.chapter ? 'banner-frame' : 'hide'}>
                    <div className='banner-label'>Need a hint?</div>
                    <div className='banner-label una bold pointer' onClick={() => handleChange('isModal', true)}>Review the Chapter(s)</div>
                </div>

                <div className='question-frame scroll' style={{ top: question.chapter ? 48 : 0, bottom: selected ? 48 : 0 }}>
                    <div className='question-title-frame scroll'>
                        <div className='question-title' ref={ref => {
                            if (ref) {
                                let lineHeight = mobile ? 26 : 31.2,
                                    numOfLines = Math.round(ref.clientHeight / lineHeight)

                                if (numOfLines === 1) ref.classList.add('center')
                                else ref.classList.remove('center')
                            }
                        }}>{question.title}</div>
                    </div>

                    <div className='answers-frame'>
                        <ul className='answers-list-view'>
                            {question.answersForTest.map((answer, i) => {
                                let isAnswer = (answer === selected)

                                return (
                                    <li
                                        className={isAnswer ? 'answer-cell selected' : 'answer-cell'}
                                        key={i}
                                        onClick={() => handleChange('selected', answer)}
                                    >
                                        <div className='checkmark-icon '>
                                            <img src={require('../../theme/assets/checkmark-icon.png')} alt='checkmark-icon' />
                                        </div>

                                        <div className='answer-label'>{answer}</div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <div className={mobile ? 'button-frame mobile' : 'button-frame'}>
                    <button className={selected ? 'action-button bg-una' : 'hide'} onClick={() => handleQuestion(true)}>Next Question</button>
                </div>
            </div>

            <ModalPane
                className='chapter-modal-pane'
                content={(hide) =>
                    <div className='course-chapter-view'>
                        <div className={mobile ? 'toolbar mobile' : 'toolbar'}>
                            {mobile ?
                                <div className='mobile-back-button' onClick={hide}>
                                    <div className='mobile-button-icon'>
                                        <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                                    </div>
                                </div>
                                :
                                <button className='back-button' onClick={hide}>Exit</button>
                            }

                            {mobile ?
                                <div className='page-header-frame'>
                                    <div className='page-header ellipsis'>{question.chapter.title}</div>
                                    <div className='page-subheader ellipsis'>{`Chapter ${question.chapter.rank} of ${course.chapters.length}`}</div>
                                </div>
                                :
                                <div className='page-header ellipsis'><b>{question.chapter.title}</b>{`
                                - Chapter ${question.chapter.rank} of ${course.chapters.length}`}</div>
                            }
                        </div>

                        <div className='content-frame'>
                            <div className='chapter-content-frame scroll'>
                                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                                    {question.chapter.contentFormatted ?
                                        <div className='chapter-html scroll'>{renderHTML(setupChapter(question.chapter))}</div>
                                        :
                                        <div className='layout-center'>
                                            <div className='empty-chapter-label'>There is no chapter details to display.</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                hide={() => handleChange('isModal', false)}
                show={isModal}
            />
        </div>
    )
}

export default CourseStart