import { gql } from 'apollo-boost'

const SpecialtyQuery = gql`
    query SpecialtyQuery($id: ID) {
        specialties {
            id
            name
        }
        user(id: $id) {
            id
            specialties {
                id
                isPrimary
                name
                specialty {
                    id
                }
                startedOn
            }
        }
    }
`

export default SpecialtyQuery