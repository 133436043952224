import  React from 'react'
import {useRef}  from 'react'
import renderHTML from 'react-render-html'

const CourseChapter = (props) => {
    const { goToChaptersRoute, handleChapter, state } = props
    const { chapter, course, mobile } = state
    const scrollframe = useRef();

    return (
        <div className='course-chapter-view'>
            <div className={mobile ? 'toolbar mobile' : 'toolbar'}>
                {mobile ?
                    <div className='mobile-back-button' onClick={goToChaptersRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button' onClick={goToChaptersRoute}>Exit</button>
                }

                {mobile ?
                    <div className='page-header-frame'>
                        <div className='page-header ellipsis'>{chapter.title}</div>
                        <div className='page-subheader ellipsis'>{`Chapter ${chapter.rank} of ${course.chapters.length}`}</div>
                    </div>
                    :
                    <div className='page-header ellipsis'>{`${chapter.title} - Chapter ${chapter.rank} of ${course.chapters.length}`}</div>
                }
            </div>

            <div className='content-frame'>
                <div ref={scrollframe} className='chapter-content-frame scroll'>
                    <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                        {chapter.contentFormatted ?
                            <div className='chapter-html scroll'>{renderHTML(chapter.contentFormatted)}</div>
                            :
                            <div className='layout-center'>
                                <div className='empty-chapter-label'>There is no chapter details to display.</div>
                            </div>
                        }
                    </div>
                </div>

                <div className={mobile ? 'button-frame mobile' : 'button-frame'}>
                    <button className='action-button bg-una' onClick={() => {handleChapter(chapter); scrollframe.current.scrollTo(0,0);}}>Next Chapter</button>
                </div>
            </div>
        </div>
    )
}

export default CourseChapter