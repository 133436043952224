import { gql } from 'apollo-boost'

const SessionQuery = gql`
    query SessionQuery($id: ID) {
        user(id: $id) {
            id
            firstName
            lastName
        }
    }
`

export default SessionQuery