import { gql } from 'apollo-boost'

const UserWorkUpdateMutation = gql`
    mutation UserWorkUpdateMutation($input: UserWorkExperienceUpdateInput) {
        userWorkUpdate(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserWorkUpdateMutation