import React from 'react'

import Cookie from 'js-cookie'

import STRINGS from '../../localization'

class NotFoundContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            authenticated: Cookie.get(process.env.REACT_APP_COOKIE_NAME) ? true : false,
        }
    }

    goToCredentialsRoute = () => {
        this.props.history.push('/credentials')
    }


    render() {
        const { authenticated } = this.state;
        const { expiredReference, goToLogin } = this.props;

        return (
            <div className='not-found-view full-screen-view'>
                <div className='layout-center'>
                    <div className='una'>{expiredReference ? STRINGS.expiredReference : STRINGS.notFoundMessage}</div>

                    <button className={authenticated ? 'home-button bg-una' : 'hidden'} onClick={this.goToCredentialsRoute}>{STRINGS.backToCredentials}</button>
                    <button className={expiredReference ? 'home-button bg-una' : 'hidden'} onClick={goToLogin}>Back to Login</button>
                </div>
            </div>
        )
    }
}

export default NotFoundContainer