import React from 'react'

import _ from 'lodash'
import ModalPane from '../../Shared/ModalPane'

const SharedResultPane = (props) => {

  const { goToRoute, handleChange, state, shareExistingResult } = props
  const { isShareResultModal, focusedTestId, alreadyCompletedTest } = state

  const { score, passed, org } = alreadyCompletedTest

  return (
    <ModalPane
      className='shared-result-modal-pane'
      content={hide => (
        <div className='shared-result-pane'>
          <div className='message'>{`You have already completed this test for '${org.title}' organization. What would you like to do?`}</div>

          <div className='result-details'>
            <div className='result-score detail-box'>
              <div className='score-title'>Test Score</div>
              <div className='score-value'>{score}%</div>
            </div>
            <div className='result-status detail-box'>
              <div className='score-title'>Test Result</div>
              <div className='score-value'>{passed ? 'Passed' : 'Failed'}</div>
            </div>
          </div>

          <div className='footer-buttons'>
            <button className='share-result-button result-modal-btn' onClick={() => shareExistingResult()}>Share Existing Result</button>
            <button className='retake-test-button result-modal-btn' onClick={() => goToRoute(`/tests/${focusedTestId}/start`)}>Retake Test</button>
          </div>
        </div>
      )}
      disableExit={false}
      hide={() => handleChange('isShareResultModal', false)}
      show={isShareResultModal}
    />
  )
}


export default SharedResultPane