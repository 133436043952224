import React from 'react'
import { graphql } from 'react-apollo'
import { flowRight as compose, set } from 'lodash'

import Cookie from 'js-cookie'

import UserQuery from '../../../graphql/queries/Document/WorkExperience'
import UserWorkAddMutation from '../../../graphql/mutations/User/Work/Add'
import UserWorkRemoveMutation from '../../../graphql/mutations/User/Work/Remove'
import UserWorkUpdateMutation from '../../../graphql/mutations/User/Work/Update'

import WorkExperience from '../../../components/Documents/WorkExperience'

import LoadingPane from '../../../components/Shared/LoadingPane'

class WorkExperienceDocumentContainer extends React.Component {
    constructor(props) {
        super(props)

        const { match: { params: { document } }, mobile } = props

        this.state = {
            current: false,
            edit: (document === 'create'),
            isModal: false,
            loading: false,
            mobile,
            onload: true
        }
    }

    componentDidMount() {
        this.setup()
    }

    componentDidUpdate(prevProps) {
        const { mobile } = this.props

        this.setup(prevProps.mobile !== mobile)
    }

    setup = (mobileDidChange) => {
        const { match, mobile, userQuery: { loading, specialties, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            let workExperience = user.workExperiences.find(o => o.id === match.params.document),
                specialty = workExperience ? specialties.find(s => s.name === workExperience.kind) : null
            
            this.setState({
                ...workExperience,
                current: workExperience && workExperience.endedOn ? false : true,
                kind: specialty ? specialty.id : null,
                mobile: mobileDidChange ? mobile : this.state.mobile,
                onload: false,
                specialties
            })

        } else if (mobileDidChange) {
            this.handleChange('mobile', mobile)
        }
    }

    back = () => {
        this.props.history.goBack()
    }

    documentRemove = (hide) => {
        const { userWorkRemoveMutation } = this.props

        this.setState({
            loading: true
        }, () => {
            userWorkRemoveMutation({
                variables: {
                    input: {
                        id: this.state.id
                    }
                }
            }).then(response => {
                const { errors, success } = response.data.userWorkRemove

                if (success) {
                    setTimeout(() => {
                        hide()

                        setTimeout(() => this.back(), 450)
                    }, 200)
                } else {
                    this.handleChange('loading', false)

                    window.alert(errors[0].message)
                }
            })
        })
    }

    handleChange = (key, value) => {
        this.setState(set(this.state, key, value))
    }

    handlePlace = (name, city, state) => {
        this.setState({
            name,
            city,
            state
        })
    }

    save = () => {
        const { userWorkAddMutation, userWorkUpdateMutation } = this.props
        const { city, current, endedOn, id, kind, name, positionTitle, specialties, startedOn, state } = this.state

        if (name && positionTitle && startedOn && (endedOn || current)) {
            this.setState({
                loading: true
            }, async () => {
                    let mutation = (id ? userWorkUpdateMutation : userWorkAddMutation),
                    data = {
                        city,
                        kind: specialties.find(s => s.id === kind).name,
                        name,
                        positionTitle,
                        state,
                        startedOn
                    }

                if (endedOn && !current) data.endedOn = endedOn

                // document exists
                if (id) {
                    data.id = id
                } else {
                    data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME)
                }

                mutation({
                    variables: {
                        input: data
                    }
                }).then(response => {
                    const key = Object.keys(response.data)[0]
                    const { errors, success } = response.data[key]

                    if (success) {
                        this.back()
                    } else {
                        this.handleChange('loading', false)

                        window.alert(errors[0].message)
                    }
                })
            })
        }
    }

    saveAttachment = () => {
        const { file } = this.state

        return new Promise((success) => {
            let xhr = new XMLHttpRequest(),
                fileName = file.name,
                config = {
                    type: `image/${fileName.split('.')[1]}`
                },
                f = new File([file.base64], fileName, config),
                formData = new FormData()

            formData.append('file', f)

            xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL)

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    success(JSON.parse(xhr.response))
                }
            }

            xhr.send(formData)
        })
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <WorkExperience
                {...this.props}
                back={this.back}
                documentRemove={this.documentRemove}
                handleChange={this.handleChange}
                handlePlace={this.handlePlace}
                save={this.save}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
                document: props.match.params.document
            }
        })
    }),
    graphql(UserWorkAddMutation, { name: 'userWorkAddMutation' }),
    graphql(UserWorkRemoveMutation, { name: 'userWorkRemoveMutation' }),
    graphql(UserWorkUpdateMutation, { name: 'userWorkUpdateMutation' })
)(WorkExperienceDocumentContainer)