import React from "react";

import _ from "lodash";
import moment from "moment";

import Resume from "../../../../components/Documents/Document/Resume";

export default class ResumeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.match.params.document === "create",
      isModal: false,
      loading: false,
      user: props.user,
    };
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange("mobile", this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide)
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { save, saveAttachment } = this.props;
    const { kind, image } = this.state;

    let data = {
      documentNumber: "1 Photo",
      kind,
      issuedOn: moment().format("YYYY-MM-DD"),
      issuingState: "n/a",
      title: kind === "resume" ? "Resume / CV" : "Social Security Card",
    };

    if (image) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          save(data);
        }
      );
    }
  };

  render() {
    return (
      <Resume
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}
