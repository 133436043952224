import { gql } from 'apollo-boost'

const CheckExistingTestQuery = gql`
  query CheckExistingCompletedTests($id: ID, $test_id: ID) {
    user(id: $id){
      id
      testTaken(id: $test_id){
        alreadyPassedTest{
          id
          passed
          score
          assessmentId
          timeSpentMins
          org {
            title
          }
        }
      }
    }
  }
`

export default CheckExistingTestQuery