import React from "react";
import { pdf } from "@react-pdf/renderer";

import _ from "lodash";
import moment from "moment";

import Signature from "../../../../components/Documents/Document/Signature";

import AnnualEducationAttestation from "../../../../components/Documents/Document/Signature/AnnualEducationAttestation/pdf";
import AnnualTBAttestation from "../../../../components/Documents/Document/Signature/AnnualTBAttestation/pdf";
import FluDeclination from "../../../../components/Documents/Document/Signature/FluDeclination/pdf";
import HepBDeclination from "../../../../components/Documents/Document/Signature/HepBDeclination/pdf";
import PreliminaryBackgroundRelease from "../../../../components/Documents/Document/Signature/PreliminaryBackgroundRelease/pdf";
import VaricellaDeclination from "../../../../components/Documents/Document/Signature/VaricellaDeclination/pdf";

import STRINGS from "../../../../localization";

const refs = {};

export default class SignatureContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.match.params.document === "create",
      isModal: false,
      issuedOn: moment().format("YYYY-MM-DD"),
      loading: false,
      user: props.user,
    };
  }

  componentDidMount() {
    this.handleChange("signature", null);
    if (refs && refs.signatureCanvas) {
      refs.signatureCanvas.clear();
    }
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange("mobile", this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide)
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { save } = this.props;
    const { kind, issuedOn, signature } = this.state;

    if (issuedOn && signature) {
      const pdfs = {
        annual_education_attestation: AnnualEducationAttestation,
        annual_tb_attestation: AnnualTBAttestation,
        flu_declination: FluDeclination,
        hep_b_declination: HepBDeclination,
        preliminary_background_release: PreliminaryBackgroundRelease,
        varicella_declination: VaricellaDeclination,
      };

      this.setState(
        {
          loading: true,
        },
        () => {
          let documentPdf = pdfs[kind](this.state),
            data = {
              documentNumber: "n/a",
              kind,
              issuedOn,
              issuingState: "n/a",
              title: STRINGS[_.camelCase(kind)],
            };

          pdf(documentPdf)
            .toBlob()
            .then(async (result) => {
              let attachment = await this.props.saveAttachment({
                name: "test.pdf",
                base64: result,
              });

              data.image = attachment;

              save(data);
            });
        }
      );
    }
  };

  render() {
    return (
      <Signature
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        refs={refs}
        save={this.save}
        state={this.state}
      />
    );
  }
}
