import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import PasswordForgottenMutation from "../../graphql/mutations/Password/Forgotten";

import ForgotPassword from "../../components/ForgotPassword";

class ForgotPasswordContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  save = () => {
    const { passwordForgottenMutation } = this.props;
    const { email } = this.state;

    if (email) {
      this.setState(
        {
          loading: true,
        },
        () => {
          passwordForgottenMutation({
            variables: {
              input: {
                email,
              },
            },
          })
            .then((response) => {
              const { errors, success } = response.data.passwordForgotten;
              this.handleChange("loading", false);
              if (success) {
                window.alert("Email successful. Please check your inbox.");
              } else {
                window.alert(errors[0].message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      );
    } else {
      window.alert("Email address is required.");
    }
  };

  render() {
    return (
      <ForgotPassword
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(PasswordForgottenMutation, {
    name: "passwordForgottenMutation",
    options: () => ({
      client: window.client2,
    }),
  })
)(ForgotPasswordContainer);
