import React from 'react'

// import { VictoryPie } from 'victory'

const CredentialsBio = (props) => {
    const { state } = props
    const { bio, firstName, lastName, mobile, /*org,*/ profilePicture, /*profileCompletion, profileStrengthData,*/ specialties } = state

    let specialty = specialties.find(s => s.isPrimary)
        // isCredentials = org ? org.modules.includes('credentials') : true

    return mobile ?
        <div className='credentials-bio-mobile-view'>
            {/* <div className={isCredentials ? 'profile-strength-frame' : 'hidden'}>
                <div className='profile-strength-label black'>PROFILE STRENGTH -</div>
                <div className='profile-strength-label black bold'>{`${profileCompletion}%`}</div>
            </div> */}

            <div className='profile-frame'>
                {profilePicture && profilePicture.url ?
                    <div className='profile-picture-icon'>
                        <img src={profilePicture.url} alt='profile-pic-icon' />
                    </div>
                    :
                    <div className='profile-picture-icon'>
                        <div className='initials'>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</div>
                    </div>
                }

                <div className='full-name bold'>{`${firstName} ${lastName}`}</div>

                {specialty ?
                    <div className='specialty'>{specialty.name}</div>
                    :
                    <div className='specialty empty'>No primary specialty provided</div>
                }

                <div className='bio'>{bio}</div>
            </div>
        </div>
        :
        <div className='credentials-bio-view'>
            <div className='profile-frame'>
                {profilePicture ?
                    <div className='profile-picture-icon'>
                        <img src={profilePicture.url} alt='profile-pic-icon' />
                    </div>
                    :
                    <div className='profile-picture-icon'>
                        <div className='initials'>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</div>
                    </div>
                }

                <div className='profile-details-frame'>
                    <div className='full-name bold'>{`${firstName} ${lastName}`}</div>

                    {specialty ?
                        <div className='specialty'>{specialty.name}</div>
                        :
                        <div className='specialty empty'>No primary specialty provided</div>
                    }

                    <div className='bio'>{bio}</div>
                </div>
            </div>

            {/* <div className={isCredentials ? 'profile-strength-frame' : 'hidden'}>
                <div className='profile-strength-label black bold'>PROFILE STRENGTH</div>

                <div className='pie-chart-frame'>
                    <VictoryPie
                        animate={{ easing: 'exp' }}
                        colorScale={['#008489', '#F6F8F8']}
                        cornerRadius={(data) => (data.index === 0) ? 10 : 0}
                        innerRadius={80}
                        data={profileStrengthData}
                        labels={() => ''}
                        width={300}
                        height={300}
                    />

                    <div className='profile-strength-label aqua bold'>{`${profileCompletion}%`}</div>
                </div>
            </div> */}
        </div>
}

export default CredentialsBio