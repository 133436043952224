import { gql } from 'apollo-boost'

const TestAnswerCreateMutation = gql`
    mutation TestAnswerCreateMutation($input: TestAnswerCreateCreateInput) {
        testAnswerCreate(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default TestAnswerCreateMutation