import { gql } from 'apollo-boost'

const DocumentRenewMutation = gql`
    mutation DocumentRenewMutation($input: DocumentRenewRenewInput) {
        documentRenew(input: $input) {
            errors {
                message
            }
            result {
                id
            }
            success
        }
    }
`

export default DocumentRenewMutation