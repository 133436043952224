import { gql } from 'apollo-boost'

const UserChecklistStartMutation = gql`
    mutation UserChecklistStartMutation($input: IDInput) {
        userChecklistStart(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserChecklistStartMutation