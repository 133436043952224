/* this functionality overrides the Cookie namespace modules get and set
*  to as to allow iframe integration with odcommunity app
*  if iframe, it gets and sets items in local storage
*/

import Cookie from 'js-cookie';

const originalSet = Cookie.set;
const originalGet = Cookie.get;
const originalRemove = Cookie.remove;

const isInIFrame = window.self !== window.top;


const customSet = (name, value, options) => {
    if (isInIFrame) {
        localStorage.setItem(name + '', value + '');
        return;
    }
    return originalSet(name, value, options);
};

const customGet = (name) => {

    if (isInIFrame) {
        return localStorage.getItem(name + '');
    }
    return originalGet(name);
};


const customRemove = (name, options) => {
    if (isInIFrame) {
        localStorage.removeItem(name);
        return;
    }
    return originalRemove(name, options);

};

// Override the set and get methods
Cookie.set = customSet;
Cookie.get = customGet;
Cookie.remove = customRemove;
Cookie.isInIFrame = isInIFrame;
export default Cookie;
