import React from "react";

const FormInput = (props) => {
  const { className, handleChange, header, hint, state, stateKey } = props;

  let defaultValue = state[stateKey];

  return (
    <div className={`form-input-frame${className ? ` ${className}` : ""}`}>
      <div className={`form-input-header ${defaultValue ? "black" : "red"}`}>
        {header}
      </div>

      <input
        id={stateKey}
        className="form-input"
        defaultValue={defaultValue}
        onChange={(e) => handleChange(stateKey, e.target.value)}
        placeholder={hint}
      />
    </div>
  );
};

export default FormInput;
