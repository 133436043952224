import { gql } from 'apollo-boost'

const SessionVerifyTokenMutation = gql`
    mutation SessionVerifyTokenMutation($tok: String!) {
        sessionVerifyToken(tok: $tok) {
            errors {
                message
            }
            passwordToken
            token
            user {
                id
                email
                roles {
                    org {
                        id
                    }
                }
            }
        }
    }
`

export default SessionVerifyTokenMutation