import { gql } from 'apollo-boost'

const PasswordResetMutation = gql`
    mutation PasswordResetMutation($input: UserPasswordResetInput) {
        passwordReset(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default PasswordResetMutation