import { gql } from 'apollo-boost'

const UserWorkRemoveMutation = gql`
    mutation UserWorkRemoveMutation($input: IDInput) {
        userWorkRemove(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserWorkRemoveMutation