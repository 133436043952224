import { gql } from 'apollo-boost'

const AttachmentCreateMutation = gql`
    mutation AttachmentCreateMutation($input: AttachmentCreateCreateInput) {
        attachmentCreate(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default AttachmentCreateMutation