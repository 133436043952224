import React from 'react'
import { Image, Document, Page, Text, View } from '@react-pdf/renderer'

import _ from 'lodash'
import moment from 'moment'

import STRINGS from '../../../../../localization'

const HepBDeclinationPdf = (state) => {
    const { kind, issuedOn, signature } = state

    return (
        <Document>
            <Page size='A4' style={{ padding: 40 }}>
                {/* header */}
                <View style={{ textAlign: 'center', marginBottom: 15 }}>
                    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{STRINGS[_.camelCase(kind)]}</Text>
                </View>

                {/* subheader */}
                <View style={{ margin: '5px 0px' }}>
                    <Text style={{ fontSize: 10 }}>
                        I understand that due to my occupational exposure to blood or 
                        other potentially infectious materials I may be at risk of 
                        acquiring hepatitis B virus (HBV) infection.
                    </Text>
                </View>

                <View style={{ margin: '5px 0px' }}>
                    <Text style={{ fontSize: 10 }}>
                        I have been given the opportunity to be vaccinated with 
                        hepatitis B vaccine, at no charge to me; however, I 
                        decline hepatitis B vaccination at this time.
                    </Text>
                </View>

                <View style={{ margin: '5px 0px' }}>
                    <Text style={{ fontSize: 10 }}>
                        I understand that by declining this vaccine I continue 
                        to be at risk of acquiring hepatitis B, a serious disease.
                    </Text>
                </View>

                <View style={{ margin: '5px 0px' }}>
                    <Text style={{ fontSize: 10 }}>
                        If, in the future I continue to have occupational exposure 
                        to blood or other potentially infectious materials and I 
                        want to be vaccinated with hepatitis B vaccine, I can 
                        receive the vaccination series at no charge to me, paid 
                        for by my employer.
                    </Text>
                </View>

                {/* signature */}
                <View style={{ flexDirection: 'column', marginBottom: 30, width: '33%' }}>
                    <Image
                        style={{ position: 'relative', top: 5 }}
                        src={{ uri: signature }}
                    />

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text style={{ fontSize: 10 }}>Signature</Text>
                    </View>
                </View>

                {/* date */}
                <View style={{ fontSize: 10, width: '33%' }}>
                    <Text>{moment(issuedOn).format('MMM D, YYYY')}</Text>

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text>Date</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default HepBDeclinationPdf