import React from "react";

const PriceCard = (props) => {
  return (
    <div className={`${props.className} ${"card"}`}>
      <div className={`${props.ValueStyle} ${"bestValue"}`}>
        <h5>{props.bestValue}</h5>
      </div>
      <div className="cardContent">
        <h2>{props.month}</h2>
        <div className="price">
          <del>{props.discountValue}</del> {props.monthValue}${" "}
          <span> /month</span>
        </div>
        <div className="Col2">
          <div>
            <div>
              <div>
                Credit Earn <b>{props.creditEarn}</b> points
              </div>
              <span className="blktxt">{props.ValueMonth} $*</span> every{" "}
              <span className="blktxt">{props.permonth}</span> months
            </div>
            <div>* VAT and local taxes may apply</div>
          </div>
          <button onClick={props.onClick}>Subscription</button>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default PriceCard;
