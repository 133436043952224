import { gql } from 'apollo-boost'

const UserSpecialtyRemoveMutation = gql`
    mutation UserSpecialtyRemoveMutation($input: IDInput) {
        userSpecialtyRemove(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserSpecialtyRemoveMutation