import { gql } from 'apollo-boost'

const UserCourseAnswerCreateMutation = gql`
    mutation UserCourseAnswerCreateMutation($input: CourseAnswerCreateCreateInput) {
        userCourseAnswerCreate(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserCourseAnswerCreateMutation