import { gql } from 'apollo-boost'

const SessionLogoutMutation = gql`
    mutation SessionLogoutMutation {
        sessionLogout {
            errors {
                message
            }
            success
        }
    }
`

export default SessionLogoutMutation