import { gql } from 'apollo-boost'

const ChecklistAnswerUpdateMutation = gql`
    mutation ChecklistAnswerUpdateMutation($input: ChecklistAnswerUpdateUpdateInput) {
        checklistAnswerUpdate(input: $input) {
            errors {
                message
            }
            success
            result {
                id
                value
            }
        }
    }
`

export default ChecklistAnswerUpdateMutation