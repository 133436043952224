import { gql } from 'apollo-boost'

const PaymentIntentCreateMutation = gql`
  mutation PaymentCreate($input: PaymentCreateInput){
    paymentCreate(input:$input){
      success
      errors{
        code
        message
        backtrace
      }
      result{
        id
        orderId
        clientSecret
      }
    }
  }
`

export default PaymentIntentCreateMutation