import React from 'react'

import _ from 'lodash'
import moment from 'moment'

import Checkbox from '../../Shared/Checkbox'
import DatePicker from '../../Shared/DatePicker'
import LoadingPane from '../../Shared/LoadingPane'
import ModalPane from '../../Shared/ModalPane'
import SelectMenu from '../../Shared/SelectMenu'

import STRINGS from '../../../localization'

import { jobTitles } from '../../../utils/Constants'

const ReferenceDocument = (props) => {
    return props.state.edit ? <Edit {...props} /> : <Read {...props} />
}

const Read = (props) => {
    const { back, documentRemove, handleChange, state } = props
    const { /*eligibleForRehire,*/ email, endDate, firstName, isModal, lastName, loading, mobile, organization, phone, startDate, status, title } = state

    let complete = (status === 'completed'),
        jobTitle = jobTitles.find(o => o.id === title)

    return (
        <div className='document-read-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-button bg-grey-2' onClick={back}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button bg-grey-2' onClick={back}>{STRINGS.cancel}</button>
                }

                <div className='page-title'>{`${STRINGS.view} ${STRINGS.reference}`}</div>

                {mobile ?
                    <div className='button-frame'>
                        <div 
                            className='mobile-button delete bg-una' 
                            onClick={() => handleChange('isModal', true)}
                            style={{ marginRight: complete ? 0 : 8 }}
                        >
                            <div className='mobile-button-icon'>
                                <img src={require('../../../theme/assets/buttons/trash-can-icon-2x.png')} alt='button-x-icon' />
                            </div>
                        </div>

                        {complete ?
                            null
                            :
                            <div className='mobile-button edit bg-grey-2' onClick={() => handleChange('edit', true)}>
                                <div className='mobile-button-icon'>
                                    <img src={require('../../../theme/assets/edit-mobile-button-teal-2x.png')} alt='button-x-icon' />
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className='button-frame'>
                        <button 
                            className='save-button bg-una capitalize' 
                            onClick={() => handleChange('isModal', true)}
                            style={{ marginRight: complete ? 0 : 8 }}
                        >{STRINGS.delete}</button>

                        {complete ?
                            null
                            :
                            <button className='save-button bg-aqua capitalize' onClick={() => handleChange('edit', true)}>{STRINGS.edit}</button>
                        }
                    </div>
                }
            </div>

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.firstName}`}</div>
                        <div className='value-label capitalize bold'>{firstName}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.lastName}`}</div>
                        <div className='value-label capitalize bold'>{lastName}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.email}`}</div>
                        <div className='value-label bold'>{email}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.phone}`}</div>

                        {phone ?
                            <div className='value-label capitalize bold'>{phone}</div>
                            :
                            <div className='value-label capitalize italic hint'>{STRINGS.notProvided}</div>
                        }
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.jobTitle}</div>

                        {jobTitle ?
                            <div className='value-label capitalize bold'>{jobTitle.value}</div>
                            :
                            <div className='value-label capitalize italic hint'>{STRINGS.notProvided}</div>
                        }
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.organization}</div>

                        {organization ?
                            <div className='value-label capitalize bold'>{organization}</div>
                            :
                            <div className='value-label capitalize italic hint'>{STRINGS.notProvided}</div>
                        }
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label'>{`${_.startCase(STRINGS.startDate)} ${STRINGS.of} ${_.capitalize(STRINGS.employment)}`}</div>
                        
                        {startDate ?
                            <div className='value-label capitalize bold'>{moment(startDate).format('MMM DD, YYYY')}</div>
                            :
                            <div className='value-label capitalize italic hint'>{STRINGS.notProvided}</div>
                        }
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label'>{`${_.startCase(STRINGS.endDate)} ${STRINGS.of} ${_.capitalize(STRINGS.employment)}`}</div>

                        {endDate ?
                            <div className='value-label capitalize bold'>{moment(endDate).format('MMM DD, YYYY')}</div>
                            :
                            <div className='value-label capitalize italic hint'>{STRINGS.notProvided}</div>
                        }
                    </div>

                    <div className={complete ? 'read-value-frame' : 'read-value-frame empty'}>
                        {/* <div className='value-label capitalize'>{STRINGS.eligibleForRehire}</div> */}

                        {/* <div className='value-label capitalize bold'>{eligibleForRehire ? STRINGS.yes : STRINGS.no}</div> */}
                    </div>
                </div>
            </div>

            <ModalPane
                className='document-delete-pane'
                content={hide => (
                    <div>
                        <div className='modal-header'>{STRINGS.referenceDeletePaneMessage}</div>

                        <div className='modal-button-frame'>
                            <button className='modal-button bg-grey-2' onClick={hide}>{STRINGS.cancel}</button>
                            <button className='modal-button bg-una' onClick={() => documentRemove(hide)}>{STRINGS.delete}</button>
                        </div>
                    </div>
                )}
                show={isModal}
                hide={() => handleChange('isModal', false)}
            />
        </div>
    )
}

const Edit = (props) => {
    const { back, handleChange, match, save, state } = props
    const { current, email, endDate, firstName, invitationNote, lastName, loading, mobile, organization, phone, startDate, title } = state

    let existing = (match.params.document !== 'create'),
        valid = (firstName && lastName && email)

    return (
        <div className='document-edit-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-button bg-grey-2' onClick={() => existing ? handleChange('edit', false) : back()}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button bg-grey-2' onClick={() => existing ? handleChange('edit', false) : back()}>{STRINGS.cancel}</button>
                }

                <div className='page-title'>{`${existing ? STRINGS.edit : STRINGS.add} ${STRINGS.reference}`}</div>

                {mobile ?
                    <div className='button-frame'>
                        <div className={`mobile-button save ${valid ? 'bg-aqua' : 'bg-disabled'}`} onClick={save}>
                            <div className='mobile-button-icon'>
                                <img src={require('../../../theme/assets/buttons/button-checkmark-icon-2x.png')} alt='button-x-icon' />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='button-frame'>
                        <button className={`save-button ${valid ? 'bg-aqua' : 'bg-disabled'}`} onClick={save}>{STRINGS.save}</button>
                    </div>
                }
            </div>

            <div className='content-frame'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.firstName}`}</div>

                        <input
                            className='key-value-input'
                            onChange={e => handleChange('firstName', e.target.value)}
                            placeholder={`${STRINGS.enter} ${STRINGS.firstName}`}
                            value={firstName || ''}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.lastName}`}</div>

                        <input
                            className='key-value-input'
                            onChange={e => handleChange('lastName', e.target.value)}
                            placeholder={`${STRINGS.enter} ${STRINGS.lastName}`}
                            value={lastName || ''}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.email}`}</div>

                        <input
                            className='key-value-input'
                            onChange={e => handleChange('email', e.target.value)}
                            placeholder={`${STRINGS.enter} ${STRINGS.email}`}
                            value={email || ''}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{`${STRINGS.reference}'s ${STRINGS.phone}`}</div>

                        <input
                            className='key-value-input'
                            onChange={e => handleChange('phone', e.target.value)}
                            placeholder={`${STRINGS.enter} ${STRINGS.phone} ${STRINGS.number}`}
                            value={phone || ''}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.jobTitle}</div>

                        <SelectMenu
                            className='key-value-menu'
                            onChange={e => handleChange('title', e.target.value)}
                            options={jobTitles}
                            placeholder={`${STRINGS.select} ${STRINGS.jobTitle}`}
                            value={title || 'hint'}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.organization}</div>

                        <input
                            className='key-value-input'
                            onChange={e => handleChange('organization', e.target.value)}
                            placeholder={`${STRINGS.enter} ${STRINGS.organization}`}
                            value={organization || ''}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label'>{`${_.startCase(STRINGS.startDate)} ${STRINGS.of} ${_.capitalize(STRINGS.employment)}`}</div>

                        <DatePicker
                            defaultValue={startDate}
                            onChange={date => handleChange('startDate', date)}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label'>{`${_.startCase(STRINGS.endDate)} ${STRINGS.of} ${_.capitalize(STRINGS.employment)}`}</div>

                        <div className={current ? 'disabled' : 'hide'} />
                        
                        <DatePicker
                            defaultValue={endDate}
                            onChange={date => handleChange('endDate', date)}
                        />

                        <div className='checkbox-frame'>
                            <Checkbox
                                checked={current}
                                onChange={checked => handleChange('current', checked)}
                            />

                            <div className='checkbox-label'>{STRINGS.iCurrentlyWorkHere}</div>
                        </div>
                    </div>

                    <div className='key-value-frame empty' />

                    <div className='key-value-frame full'>
                        <div className='value-label capitalize'>{STRINGS.invitationNote}</div>

                        <textarea
                            className='key-value-textarea'
                            onChange={e => handleChange('invitationNote', e.target.value)}
                            placeholder={`${STRINGS.enter} ${STRINGS.invitationNote}`}
                            value={invitationNote || ''}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ReferenceDocument