import React from 'react';

const Benefits = (props) => {
  return (
    <div className='description'>
      <h2>Benefits</h2>
      <div className='desc-content' style={{ ...props.style }}>
            <div>The test can adapt in real time to the subject's performance</div>
            <div>Test parameters can be dynamic and vary with time</div>
            <div>The tests are immune to mistakes in interpreting the results</div>
            <div>The variance in test administration is minimized</div>
            <div>They don't suffer from color fading like the pigments/dyes in analog tests.</div>
            <div>Impact of colour vision deficiency on children</div>
            <div>Contributor Information</div>
            <div>The Object color vision test</div>
            <div>Procedure for Testing Color Vision</div>
            <div>Color vision testing in diabetes</div>
            <div>A pseudoisochromatic test of color vision for human infants</div>
            <div>Exceptional color vision on all condition or reflections</div>
            <div>Color vision testing in diabetes</div>
            <div>Color vision testing in diabetes</div>
            <div>Color vision testing in diabetes</div>
        </div>
    </div>
  );
}

export default Benefits;