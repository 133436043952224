import React from 'react'

import _ from 'lodash'

import STRINGS from '../../../../../localization'

const AnnualEducationAttestation = (props) => {
    const { state: { kind } } = props

    let arr = [
        'Abuse & Neglect',
        'Age Specific Care',
        'Body Mechanics',
        'COVID 19',
        'Cultural Diversity',
        'Emergency Preparedness',
        'Environmental Safety',
        'Ethics',
        'Fire Safety',
        'Hazard Communication',
        'HIPAA',
        'Infection Control & Bloodborne Pathogens',
        'National Patient Safety Goals',
        'Pain Management',
        'Patient Rights',
        'Patient Safety Systems',
        'Sexual Harassment',
        'Workplace Violence'
    ]

    return (
        <div className='key-value-frame full'>
            <div className='form-frame'>
                <div className='form-name'>{`${STRINGS[_.camelCase(kind)]} & ${_.capitalize(STRINGS.orientation)}`}</div>

                <div className='form-label'>
                    I acknowledge that I have received training on and always have access to an 
                    online updated copy of the Annual Education and Core Competency Education, which 
                    contains information and verification of procedures included but not limited
                    to the following:
                </div>

                <ul className='form-list-view'>
                    {arr.map((o, i) => {
                        return (
                            <li className='form-label capitalize' key={i}>{o}</li>
                        )
                    })}
                </ul>

                <a 
                    className='form-link una' 
                    href='https://drive.google.com/drive/u/0/folders/1PBRvMP9lpd9vdV4aRNIVikwabQD-W9qJ' 
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <div className='una'>View Modules</div>
                </a>
            </div>
        </div>
    )
}

export default AnnualEducationAttestation