import React from "react";
import { withRouter } from "react-router-dom"; // Import withRouter to access history object

import _ from "lodash";
import hireLogo from "../../theme/assets/hire-logo.png";

class DashboardNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.handleNavigation = this.handleNavigation.bind(this);

    this.state = {
      ...props.state,
      isExpand: false,
      show: false,
      tabs: [
        {
          enabled: true,
          route: "credentials",
          name: "Credentials",
        },
        // {
        //     enabled: false,
        //     route: 'jobs', name: 'Jobs'
        // },
        // {
        //     enabled: false,
        //     route: 'notifications', name: 'Notifications'
        // },
        {
          enabled: true,
          route: "account",
          name: "Account",
        },
      ],
      userSubscription: [],
      isUrlLogoAllowed: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.state, this.props.state)) {
      this.setState({
        ...this.props.state,
      });
    }
  }

  goToTabRoute = (tab) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { mobile } = this.state;

    if (tab.enabled) {
      let route = id ? `/organizations/${id}/${tab.route}` : `/${tab.route}`;

      this.handleChange("isExpand", false);

      let userSubscription;

      if (this.props.location && this.props.location.userSubscription) {
        userSubscription = this.props.location.userSubscription;
      } else {
        userSubscription = this.props.state?.userSubscription;
      }

      if (mobile) {
        setTimeout(() => {
          this.props.history.push({
            pathname: route,
            userSubscription, // Pass userSubscription as state
          });
        }, 300);
      } else {
        this.props.history.push({
          pathname: route,
          userSubscription, // Pass userSubscription as state
        });
      }
    }
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleMenu = (show) => {
    if (show) {
      this.setState({
        show,
        isExpand: show,
      });
    } else {
      this.handleChange("isExpand", show);

      setTimeout(() => this.handleChange("show", show), 300);
    }
  };

  handleNavigation() {
    let userSubscription;

    if (this.props.location && this.props.location.userSubscription) {
      userSubscription = this.props.location.userSubscription;
    } else {
      userSubscription = this.props.state?.userSubscription;
    }
    this.props.history.push({
      pathname: "/subscribe",
      userSubscription, // Pass userSubscription as state
    });
  }

  render() {
    const { handleRole, match } = this.props;
    const { isExpand, mobile, role, show, tabs } = this.state;

    const isUserSubscription = this.props.subscription?.status;

    return mobile ? (
      <div className="dashboard-navigation-mobile-view">
        <div className="navigation-frame">
          <div className="una-logo-frame">
            {this.state.isUrlLogoAllowed && role && role.org.attachment ? (
              <div className="company-logo">
                <img src={role.org.attachment.url} alt="una-logo" />
              </div>
            ) : (
              <div className="una-logo">
                <img
                  src={hireLogo}
                  alt="waggonerHire-logo"
                />
              </div>
            )}
          </div>

          <div className="hamburger-logo" onClick={() => this.toggleMenu(true)}>
            <img
              src={require("../../theme/assets/hamburger-icon-2x.png")}
              alt="hamburger-icon"
            />
          </div>
        </div>

        <div className={show ? "hamburger-menu-view" : "hide"}>
          <div
            className="hamburger-background"
            onClick={() => this.toggleMenu(false)}
          />

          <div
            className="hamburger-menu-frame"
            style={{ transform: `translate(${isExpand ? "0%" : "100%"}, 0%)` }}
          >
            <ul className="navigation-list-view">
              {tabs.map((tab, i) => {
                const { enabled, name, route } = tab;
                const routeParts =
                  this.props.history.location.pathname.split("/");

                let tabName = match.params.id
                  ? match.params.tab
                  : routeParts[1],
                  isTab = tabName === route;

                return (
                  <li
                    className={
                      enabled ? "navigation-cell" : "navigation-cell disabled"
                    }
                    key={i}
                    onClick={() => this.goToTabRoute(tab)}
                  >
                    <div className="nav-icon">
                      {isTab ? (
                        <img
                          src={require(`../../theme/assets/navigation-icons/${route}-icon-2x-selected.png`)}
                          alt="credentials-icon"
                        />
                      ) : (
                        <img
                          src={require(`../../theme/assets/navigation-icons/${route}-icon-2x.png`)}
                          alt="credentials-icon"
                        />
                      )}
                    </div>

                    <div className={`nav-label ${isTab ? "una" : "black"}`}>
                      {name}
                    </div>
                  </li>
                );
              })}
            </ul>

            <button
              className={role ? "change-role-button bg-aqua" : "hidden"}
              onClick={() => handleRole(isExpand, this.toggleMenu)}
            >
              Change Role
            </button>
          </div>
        </div>
      </div>
    ) : (
      <div className="dashboard-navigation-view">
        <div className="navigation-frame">
          {this.state.isUrlLogoAllowed && role && role.org.attachment ? (
            <div className="company-logo">
              <img src={role.org.attachment.url} alt="una-logo" />
            </div>
          ) : (
            <div className="una-logo">
              <img
                src={hireLogo}
                alt="waggonerHire-logo"
              />
            </div>
          )}

          <div className="navigation-tabs-frame">
            <ul className="navigation-list-view">
              {/* {this.props.location.pathname !== "/subscribe" && (
                <>
                  <p style={{ color: "#008489", marginRight: "20px" }}>
                    {isUserSubscription
                      ? `${this.props.subscription.creditEarn} points`
                      : ""}
                  </p>

                  <button
                    className="subscribe-button"
                    onClick={this.handleNavigation}
                  >
                    {isUserSubscription ? "Upgrade" : "Subscribe"}
                  </button>
                </>
              )} */}

              {tabs.map((tab, i) => {
                const { enabled, name, route } = tab;
                const routeParts =
                  this.props.history.location.pathname.split("/");

                let tabName = match.params.id
                  ? match.params.tab
                  : routeParts[1],
                  isTab = tabName === route;

                return (
                  <li
                    className={
                      enabled ? "navigation-cell" : "navigation-cell disabled"
                    }
                    key={i}
                    onClick={() => this.goToTabRoute(tab)}
                  >
                    <div className="nav-icon">
                      {isTab ? (
                        <img
                          src={require(`../../theme/assets/navigation-icons/${route}-icon-2x-selected.png`)}
                          alt="credentials-icon"
                        />
                      ) : (
                        <img
                          src={require(`../../theme/assets/navigation-icons/${route}-icon-2x.png`)}
                          alt="credentials-icon"
                        />
                      )}
                    </div>

                    <div className={`nav-label ${isTab ? "una" : "black"}`}>
                      {name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(DashboardNavigation);
