import { gql } from 'apollo-boost'

const ReferenceRemoveMutation = gql`
    mutation ReferenceRemoveMutation($input: IDInput) {
        referenceRemove(input: $input) {
            errors {
                message
            }
            result {
                id
            }
            success
        }
    }
`

export default ReferenceRemoveMutation