import { gql } from 'apollo-boost'

const ReferenceInviteMutation = gql`
    mutation ReferenceInviteMutation($input: IDInput) {
        referenceInvite(input: $input) {
            errors {
                message
            }
            result {
                id
                token
            }
            success
        }
    }
`

export default ReferenceInviteMutation