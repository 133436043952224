import React from 'react'
import { graphql } from 'react-apollo'

import { flowRight as compose } from 'lodash'
import Cookie from 'js-cookie'

import UserQuery from '../../graphql/queries/Course'

import CourseComplete from '../../components/Course/Complete'

import LoadingPane from '../../components/Shared/LoadingPane'

class CourseContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            onload: true
        }
    }

    componentDidMount() {
        const { userQuery: { loading, refetch } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            refetch().then(this.setup)
        } else {
            this.setup()
        }

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate() {
        this.setup()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    setup = () => {
        const { userQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            this.setState({
                ...user.courseTaken,
                onload: false
            })

        }
    }
    
    goToCredentialsRoute = () => {
        const { history } = this.props

        let org = Cookie.get('org'),
            url = (org ? `/organizations/${org}/credentials` : '/credentials')

        history.push(url)
    }

    goToResultsRoute = () => {
        const { history, match } = this.props

        history.push(`/courses/${match.params.course}`)
    }

    goToRoute = (route) => {
        this.props.history.push(route)
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleResize = () => {
        this.handleChange('mobile', window.innerWidth < 1000)
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <CourseComplete
                goToCredentialsRoute={this.goToCredentialsRoute}
                goToResultsRoute={this.goToResultsRoute}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
                course: props.match.params.course
            }
        })
    })
)(CourseContainer)