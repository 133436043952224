import { gql } from 'apollo-boost'

const UserTestStartMutation = gql`
    mutation UserTestStartMutation($input: IDInput) {
        userTestStart(input: $input) {
            errors {
                message
            }
            result {
                id
                status
            }
            success
        }
    }
`

export default UserTestStartMutation