import React from 'react'
import { Image, Document, Page, Text, View } from '@react-pdf/renderer'

import _ from 'lodash'
import moment from 'moment'

import STRINGS from '../../../../../localization'

const AnnualTBAttestationPdf = (state) => {
    const { kind, issuedOn, signature } = state

    return (
        <Document>
            <Page size='A4' style={{ padding: 40 }}>
                {/* header */}
                <View style={{ textAlign: 'center', marginBottom: 15 }}>
                    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{STRINGS[_.camelCase(kind)]}</Text>
                </View>

                {/* subheader */}
                <View style={{ marginTop: 15 }}>
                    <Text style={{ fontSize: 10 }}>
                        I attest that I have either had a positive TB skin test in the past, 
                        have had the BCG vaccine, or have an allergy to the TB skin test.
                    </Text>
                </View>

                <View style={{ margin: '15px 0px', flexDirection: 'row', fontSize: 10 }}>
                    <Text style={{ textDecoration: 'underline' }}>I have not had any of the following symptoms</Text>
                    <Text>in the past three (3) weeks or longer:</Text>
                </View>

                {/* list view */}
                <View style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Chronic Cough</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Production of Sputum</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Blood Streaked Sputum</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Unexplained Weight Loss</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Fever</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Fatigue/Tiredness</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Night Sweats</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Shortness of Breath</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Exposed to someone with active TB infection</Text>
                    </View>
                </View>

                <View style={{ marginBottom: 60 }}>
                    <Text style={{ fontSize: 10 }}>
                        I understand that if I begin exhibiting any of these symptoms or become 
                        exposed to someone with active TB, I will notify my employer and seek 
                        medical advice from a licensed independent practitioner for further follow up.
                    </Text>
                </View>

                {/* signature */}
                <View style={{ flexDirection: 'column', marginBottom: 30, width: '33%' }}>
                    <Image
                        style={{ position: 'relative', top: 5 }}
                        src={{ uri: signature }}
                    />

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text style={{ fontSize: 10 }}>Signature</Text>
                    </View>
                </View>

                {/* date */}
                <View style={{ fontSize: 10, width: '33%' }}>
                    <Text>{moment(issuedOn).format('MMM D, YYYY')}</Text>

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text>Date</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default AnnualTBAttestationPdf