import React from 'react'

import LoadingPane from '../Shared/LoadingPane'
import SelectMenu from '../Shared/SelectMenu'

import { states } from '../../utils/Constants'

const AccountEdit = (props) => {
    const { goToAccountRoute, handleChange, save, state } = props
    const { bio, email, file, firstName, lastName, loading, mobile, phoneNumber, profilePicture, specialties, specialtyOptions } = state

    let primarySpecialty = specialties.find(s => s.isPrimary)

    return (
        <div className='account-edit-view full-screen-view'>
            {loading ? <LoadingPane /> : null}

            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-back-button' onClick={goToAccountRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button' onClick={goToAccountRoute}>Back</button>
                }

                <div className='page-header'>Edit Account</div>

                {mobile ?
                    <div className='mobile-save-button' onClick={save}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-checkmark-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='save-button' onClick={save}>Save</button>
                }
            </div>

            <div className='content-frame scroll' style={{ top: mobile ? 48 : 60 }}>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='header-frame'>
                        <div className='header'>Account Details</div>
                    </div>

                    <div className='profile-picture-frame'>
                        {file ?
                            <div className='profile-picture-icon'>
                                <img src={file.base64} alt='profile-pic-icon' />
                            </div>
                            :
                            profilePicture ?
                                <div className='profile-picture-icon'>
                                    <img src={profilePicture.url} alt='profile-pic-icon' />
                                </div>
                                :
                                <div className='profile-picture-icon'>
                                    <div className='initials'>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</div>
                                </div>
                        }

                        <div className='edit-photo-button-frame'>
                            <input
                                className='file-input'
                                type='file'
                                onChange={e => {
                                    let file = e.target.files[0],
                                        reader = new FileReader()

                                    reader.onload = (result) => {
                                        let supportedTypes = ['image/png', 'image/jpeg'],
                                            base64 = result.target.result,
                                            newFile = {
                                                base64,
                                                name: file.name,
                                                type: file.type
                                            }

                                        if (supportedTypes.includes(file.type)) {
                                            handleChange('file', newFile)
                                        } else {
                                            window.alert(`${file.type} is not supported.`)
                                        }
                                    }

                                    reader.readAsDataURL(file)
                                }}
                            />

                            <button className='edit-photo-button una'>Edit Photo</button>
                        </div>
                    </div>

                    <div className='profile-details-frame'>
                        <div className='key-value-frame'>
                            <div className='value-label'>First Name</div>

                            <input
                                className='key-value-input'
                                onChange={e => handleChange('firstName', e.target.value)}
                                placeholder='Enter first name'
                                value={firstName}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Last Name</div>

                            <input
                                className='key-value-input'
                                onChange={e => handleChange('lastName', e.target.value)}
                                placeholder='Enter last name'
                                value={lastName}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Primary Specialty</div>

                            <SelectMenu
                                className='key-value-menu'
                                defaultValue={primarySpecialty ? primarySpecialty.specialty.id : 'hint'}
                                onChange={e => handleChange('specialty', e.target.value)}
                                options={specialtyOptions.map(s => {
                                    return {
                                        id: s.id,
                                        value: s.name
                                    }
                                })}
                                placeholder='Select Specialty'
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Email</div>

                            <input
                                className='key-value-input'
                                onChange={e => handleChange('email', e.target.value)}
                                placeholder='Enter email'
                                value={email}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Phone</div>

                            <input
                                className='key-value-input'
                                maxLength={14}
                                onChange={e => {
                                    const formatNumber = (num) => {
                                        let cleaned = ('' + num).replace(/\D/g, ''),
                                            match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

                                        if (match) return '(' + match[1] + ') ' + match[2] + '-' + match[3]

                                        return null
                                    }

                                    let value = formatNumber(e.target.value)

                                    if (value) {
                                        e.target.value = value
                                    }

                                    handleChange('phoneNumber', value || e.target.value)
                                }}
                                placeholder='Enter phone number'
                                value={phoneNumber || ""}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Street 1</div>

                            <input
                                className='key-value-input'
                                onChange={e => handleChange('homeStreet1', e.target.value)}
                                placeholder='Enter street 1'
                                value={state.homeStreet1 || ''}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Street 2</div>

                            <input
                                className='key-value-input'
                                onChange={e => handleChange('homeStreet2', e.target.value)}
                                placeholder='Enter street 2'
                                value={state.homeStreet2 || ''}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>City</div>

                            <input
                                className='key-value-input'
                                onChange={e => handleChange('homeCity', e.target.value)}
                                placeholder='Enter city'
                                value={state.homeCity || ''}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>State</div>

                            <SelectMenu
                                className='key-value-menu'
                                defaultValue={state.homeState}
                                onChange={e => handleChange('homeState', e.target.value)}
                                options={states.map(state => {
                                    const { abbreviation, name } = state

                                    return {
                                        id: abbreviation,
                                        value: `${name} (${abbreviation})`
                                    }
                                })}
                                placeholder='Select State'
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label'>Zip</div>

                            <input
                                className='key-value-input'
                                onChange={e => handleChange('homeZip', e.target.value)}
                                placeholder='Enter zip'
                                value={state.homeZip || ''}
                            />
                        </div>

                        <div className='key-value-frame' style={{ width: '100%' }}>
                            <div className='value-label'>Full Bio</div>

                            <textarea
                                className='key-value-textarea'
                                onChange={e => handleChange('bio', e.target.value)}
                                placeholder='Enter bio'
                                value={bio || ''}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountEdit