import React from "react";

const TestComplete = (props) => {
  const { goToResultsRoute, goToCredentialsRoute, state } = props;
  const { mobile, title, test } = state;
  return (
    <div className="test-complete-view full-screen-view">
      <div className="toolbar">
        <div className="page-header bold">Test Complete</div>
      </div>

      <div className="content-frame">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="checkmark-success-icon">
            <img
              src={require("../../theme/assets/checkmark-success-icon-2x.png")}
              alt="checkmark-success-icon"
            />
          </div>

          <div className="header">Congratulations,</div>

          <div className="subheader">
            You have completed the <b>{`"${title}"`}</b> Test.
          </div>

          <div className="button-frame">
            {test.testType === 'self_report' ? (
              ""
            ) : (
              <button
                className="action-button bg-una"
                onClick={goToResultsRoute}
              >
                View Your Results
              </button>
            )}

            <button
              className="action-button bg-grey-2"
              onClick={goToCredentialsRoute}
            >
              Back to Credentials
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestComplete;
