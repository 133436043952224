import { gql } from 'apollo-boost'

const UserTestUpdateMutation = gql`
    mutation UserTestUpdateMutation($input: TestUserTestUpdateUpdateInput) {
        userTestUpdate(input: $input) {
            errors {
                message
            }
            result {
                id
                status
            }
            success
        }
    }
`

export default UserTestUpdateMutation