import { gql } from 'apollo-boost'

const CourseChaptersQuery = gql`
    query CourseChaptersQuery($id: ID, $course: ID) {
        user(id: $id) {
            id
            courseTaken(id: $course) {
                id
                course {
                    id
                    artworkUrl
                    artworkThumbnailUrl
                    chapters {
                        id
                        contentFormatted
                        questions {
                            id
                            answersForTest
                            rank
                            title
                        }
                        rank
                        title
                    }
                    desc
                    title
                }
            }
        }
    }
`

export default CourseChaptersQuery