import { gql } from "apollo-boost";

const ReferenceQuery = gql`
  query ReferenceQuery($id: ID) {
    reference(id: $id) {
      id
      city
      endDate
      firstName
      invitationNote
      lastName
      organization
      relationship
      email
      phone
      state
      startDate
      status
      title
      token
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export default ReferenceQuery;
