import { gql } from 'apollo-boost'

const UserSpecialtyCreateMutation = gql`
    mutation UserSpecialtyCreateMutation($input: UserSpecialtyAddInput) {
        userSpecialtyAdd(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserSpecialtyCreateMutation