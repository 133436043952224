import { gql } from 'apollo-boost'

const PasswordForgottenMutation = gql`
    mutation PasswordForgottenMutation($input: UserPasswordForgottenInput) {
        passwordForgotten(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default PasswordForgottenMutation