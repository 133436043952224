import { gql } from 'apollo-boost'

const UserTestRetakeMutation = gql`
    mutation UserTestRetakeMutation($input: TestUserTestRetakeRetakeInput) {
      userTestRetake(input: $input) {
            errors {
                message
            }
            result {
                id
                status
            }
            success
        }
    }
`

export default UserTestRetakeMutation