import { gql } from 'apollo-boost'

const UserTestFinishMutation = gql`
    mutation UserTestFinishMutation($input: IDInput) {
        userTestFinish(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserTestFinishMutation