import React from 'react'

import DatePicker from '../Shared/DatePicker'
import LoadingPane from '../Shared/LoadingPane'
import SelectMenu from '../Shared/SelectMenu'

import STRINGS from '../../localization'

const SpecialtyEdit = (props) => {
    const { back, handleChange, save, state } = props
    const { loading, mobile, options, specialty } = state

    return (
        <div className='specialty-edit-view full-screen-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-button bg-grey-2' onClick={() => specialty.id ? handleChange('edit', false) : back()}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button bg-grey-2' onClick={() => specialty.id ? handleChange('edit', false) : back()}>{STRINGS.cancel}</button>
                }

                <div className='page-title capitalize'>{`${specialty.id ? STRINGS.edit : STRINGS.add} ${STRINGS.specialty}`}</div>

                {mobile ?
                    <div className='mobile-button save bg-aqua' onClick={save}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-checkmark-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <div className='button-frame'>
                        <button className={`save-button ${specialty.startedOn && specialty.specialty.id ? 'bg-aqua' : 'bg-disabled'}`} onClick={save}>{STRINGS.save}</button>
                    </div>
                }
            </div>

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.title}</div>

                        <SelectMenu
                            className='key-value-menu'
                            defaultValue={specialty.id ? specialty.specialty.id : 'hint'}
                            onChange={e => handleChange('specialty.specialty.id', e.target.value)}
                            options={options.map(s => {
                                return {
                                    id: s.id,
                                    value: s.name
                                }
                            })}
                            placeholder='Select Specialty'
                        />
                    </div>

                    <div className='key-value-frame middle'>
                        <div className='value-label'>Started On</div>

                        <DatePicker
                            defaultValue={specialty.startedOn}
                            onChange={date => handleChange('specialty.startedOn', date)}
                        />
                    </div>

                    <div className='key-value-frame'></div>
                </div>
            </div>
        </div>
    )
}

export default SpecialtyEdit