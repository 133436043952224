import { gql } from 'apollo-boost'

const CourseStartQuery = gql`
    query CourseStartQuery($id: ID, $course: ID) {
        user(id: $id) {
            id
            courseTaken(id: $course) {
                id
                answers {
                    id
                    question {
                        id
                    }
                    value
                }
                course {
                    id
                    artworkUrl
                    artworkThumbnailUrl
                    chapters {
                        id
                        contentFormatted
                        questions {
                            id
                            answersForTest
                            chapter {
                                id
                                contentFormatted
                                rank
                                title
                            }
                            rank
                            title
                        }
                        rank
                        title
                    }
                    desc
                    title
                }
                status
            }
        }
    }
`

export default CourseStartQuery