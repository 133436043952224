import React from 'react'
import { graphql } from 'react-apollo'

import { flowRight as compose } from 'lodash'
import Cookie from 'js-cookie'

import UserQuery from '../../graphql/queries/Course/Chapters'

import CourseChapters from '../../components/Course/Chapters'

import LoadingPane from '../../components/Shared/LoadingPane'

class CourseChaptersContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            onload: true
        }
    }

    componentDidMount() {
        this.setup()

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate() {
        this.setup()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    setup = () => {
        const { match, userQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            let urlChapter = match.params.chapter,
                chapter = user.courseTaken.course.chapters.find(c => c.id === urlChapter)

            this.setState({
                ...user.courseTaken,
                chapter: urlChapter ? chapter : null,
                onload: false
            })
        }
    }

    goToChapterRoute = (chapter) => {
        const { match } = this.props

        let route = `/courses/${match.params.course}/chapters/${chapter.id}`

        this.goToRoute(route)
    }

    goToCourseStartRoute = (chapter) => {
        const { match } = this.props

        let route = `/courses/${match.params.course}/start`

        this.goToRoute(route)
    }

    goToRoute = (route) => {
        this.props.history.push(route)
    }

    goToCredentialsRoute = () => {
        const { history } = this.props

        let org = Cookie.get('org'),
            url = (org ? `/organizations/${org}/credentials` : '/credentials')

        history.push(url)
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleResize = () => {
        this.handleChange('mobile', window.innerWidth < 1000)
    }

    render() {
        const { onload } = this.state

        return onload ?
            <LoadingPane />
            :
            <CourseChapters
                goToChapterRoute={this.goToChapterRoute}
                goToCourseStartRoute={this.goToCourseStartRoute}
                goToCredentialsRoute={this.goToCredentialsRoute}
                goToRoute={this.goToRoute}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
                course: props.match.params.course
            }
        })
    })
)(CourseChaptersContainer)