import React from "react";

import LoadingPane from "../Shared/LoadingPane";

import FormDate from "./Form/Date";
import FormInput from "./Form/Input";
import FormMenu from "./Form/Menu";

const Reference = (props) => {
  const { handleChange, save, state } = props;
  const {
    endDate,
    loading,
    invitationNote,
    isValid,
    mobile,
    relationship,
    startDate,
    firstName,
    lastName,
    user
  } = state;

  return (
    <div className={mobile ? "reference-mobile-view" : "reference-view"}>
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)", zIndex: 1001 }} />
      ) : null}

      <div className="content-frame">
        <div className="centered-frame">
          <div className="una-logo">
            <img
              src={require("../../theme/assets/una-logo-2x.png")}
              alt="una-logo"
            />
          </div>

          {mobile ? (
            <div className="header-frame">
              <div className="header-label bold">{`${user.firstName} ${user.lastName}`}</div>
              <div className="header-label">has requested you as a</div>
              <div className="header-label">
                reference for their UNA profile!
              </div>
            </div>
          ) : (
            <div className="header-frame">
              <div className="header-label bold">{`${user.firstName} ${user.lastName}`}</div>
              <div className="header-label">
                has requested you as a reference for their UNA profile!
              </div>
            </div>
          )}

          <div
            className={invitationNote ? "note-label" : "hide"}
          >{`Note from user: "${invitationNote}"`}</div>

          <div className="form-frame">
            <div className="form-header-label">
              Please fill out some of your information
            </div>

            <FormInput
              {...props}
              header="First Name"
              hint="Enter your first name"
              stateKey="firstName"
            />

            <FormInput
              {...props}
              className="middle"
              header="Last Name"
              hint="Enter your last name"
              stateKey="lastName"
            />

            <FormInput
              {...props}
              header="Email"
              hint="Enter your email address"
              stateKey="email"
            />

            <FormInput
              {...props}
              header="Phone Number"
              hint="Enter your phone number"
              stateKey="phone"
            />

            <FormInput
              {...props}
              className="middle"
              header="Organization Title"
              hint="Title of organization you worked with them at"
              stateKey="organization"
            />

            <FormMenu
              {...props}
              header={`Relationship to ${user.firstName}`}
              hint="Select relationship"
              options={[
                { value: "change_nurse", label: "Change Nurse" },
                { value: "educator", label: "Educator" },
                {
                  value: "hr_manager_/_director",
                  label: "HR Manager/Director",
                },
                { value: "nurse_manager", label: "Nurse Manager" },
                { value: "peer_/_co-worker", label: "Peer/Co-Worker" },
                { value: "subordinate", label: "Subordinate" },
                { value: "supervisor", label: "Supervisor" },
                { value: "other", label: "Other (fill in)" },
              ]}
              stateKey="relationship"
            />

            <FormInput
              {...props}
              className={relationship === "other" ? "" : "hide"}
              header="Other Relationship"
              hint="Enter relationship"
              stateKey="other"
            />
          </div>

          <div className="form-frame">
            <div className="form-header-label">Employment information</div>

            <FormDate
              {...props}
              defaultValue={startDate}
              header={`${user.firstName}'s Start Date of Employment`}
              stateKey="startDate"
            />

            <FormDate
              {...props}
              className="middle"
              defaultValue={endDate}
              header={`${user.firstName}'s End Date of Employment`}
              stateKey="endDate"
            />

            <FormMenu
              {...props}
              header={`Is ${user.firstName} Eligible for rehire?`}
              hint="Select Answer"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              stateKey="eligibleForRehire"
            />
          </div>

          <div className="form-frame" style={{ marginBottom: 20 }}>
            <div className="form-header-label">{`Rate ${user.firstName} on the following criteria`}</div>

            <FormMenu
              {...props}
              header="Professionalism"
              hint="Select Answer"
              options={[
                { value: "0", label: "Unable to answer" },
                { value: "1", label: "Varies from time to time" },
                { value: "2", label: "Consistently positive" },
                { value: "3", label: "Stellar" },
              ]}
              stateKey="professionalism"
            />

            <FormMenu
              {...props}
              className="middle"
              header="Communication with others"
              hint="Select Answer"
              options={[
                { value: "0", label: "Unable to answer" },
                { value: "1", label: "Painfully quiet" },
                { value: "2", label: "Collaborative" },
                { value: "3", label: "Role-model for others" },
              ]}
              stateKey="communication"
            />

            <FormMenu
              {...props}
              header="Clinical Competency"
              hint="Select Answer"
              options={[
                { value: "0", label: "Unable to answer" },
                { value: "1", label: "Still learning" },
                { value: "2", label: "Gets the job done" },
                { value: "3", label: "Can serve as a preceptor" },
              ]}
              stateKey="clinicalCompetancy"
            />

            <FormMenu
              {...props}
              className="technology"
              header="Technology Use"
              hint="Select Answer"
              options={[
                { value: "0", label: "Unable to answer" },
                { value: "1", label: "Hand holding required" },
                { value: "2", label: "Savvy/catches on easily" },
                { value: "3", label: "Should have been an engineer" },
              ]}
              stateKey="technologyCompetancy"
            />

            <FormMenu
              {...props}
              className="middle"
              header={`Attendance & Punctuality`}
              hint="Select Answer"
              options={[
                { value: "0", label: "Unable to answer" },
                { value: "4", label: "Large opportunity for improvement" },
                { value: "2", label: "Mostly reliable and punctual" },
                { value: "1", label: "Consistently reliable and punctual" },
              ]}
              stateKey="attendancePunctuality"
            />

            <div
              className="form-input-frame"
              style={{
                width: "100%",
                marginTop: mobile ? 0 : 10,
                marginBottom: 0,
              }}
            >
              <div className="form-input-header">{`Is there any other information you would like to share about ${user.firstName}?`}</div>

              <textarea
                className="form-text-input"
                onChange={(e) => handleChange("otherDetails", e.target.value)}
                placeholder="Enter additional information"
              />
            </div>
          </div>

          <div className="version">Version 10.4.0</div>
        </div>
      </div>

      <div className="button-frame">
        <button
          className={`submit-reference-button ${isValid ? "" : "hint"}`}
          onClick={save}
        >
          Submit Reference
        </button>
      </div>
    </div>
  );
};

export default Reference;
