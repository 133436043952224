import React from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export default class AutoCompleteSearch extends React.Component {
    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    handleOnSelect = (item) => {
        this.props.onChange(item)
    }

    render() {
        const { defaultValue, maxResults, placeholder, items } = this.props
        
        return (
            <ReactSearchAutocomplete 
                items={items}
                inputSearchString={defaultValue}
                maxResults={maxResults || 10}
                onSearch={this.handleOnSearch}
                onSelect={this.handleOnSelect}
                placeholder={placeholder}
                showIcon={false}
                styling={{
                    border: '1px solid #DBDBDB',
                    borderRadius: '4px',
                    boxShadow: 'none',
                    height: '32px',
                    fontSize: '12px',
                    fontFamily: 'Soleil',
                    lineColor: '#DBDBDB',
                    clearIconMargin: '3px 10px 0 0'
                }}
            />
        )
    }
}