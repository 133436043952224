import { gql } from 'apollo-boost'

const ReferenceUpdateMutation = gql`
    mutation ReferenceUpdateMutation($input: ReferenceUpdateInput) {
        referenceUpdate(input: $input) {
            errors {
                message
            }
            result {
                id
                status
            }
        }
    }
`

export default ReferenceUpdateMutation