import React from 'react'

import DatePicker from '../../Shared/DatePicker'
import ModalPane from '../../Shared/ModalPane'

import STRINGS from '../../../localization'

const RenewDocumentPane = (props) => {
    const { documentRenew, handleChange, resetDocumentRenewal, state } = props
    const { isModal2 } = state

    return (
        <ModalPane
            className='document-renew-pane'
            content={hide => (
                <div className='full-screen-view'>
                    <div className='modal-toolbar'>
                        <button className='modal-toolbar-button bg-grey-2' onClick={hide}>{STRINGS.cancel}</button>

                        <div className='modal-toolbar-header capitalize'>{`${STRINGS.renew} ${STRINGS.document}`}</div>

                        <button
                            className={`modal-toolbar-button ${state.renewIssuedOn && state.renewExpiresOn ? 'bg-aqua' : 'bg-disabled'}`}
                            onClick={() => documentRenew(hide)}
                        >{STRINGS.save}</button>
                    </div>

                    <div className='form-frame'>
                        <div className='key-value-frame'>
                            <div className='value-label capitalize'>{STRINGS.issueDate}</div>

                            <DatePicker
                                onChange={date => handleChange('renewIssuedOn', date)}
                            />
                        </div>

                        <div className='key-value-frame'>
                            <div className='value-label capitalize'>{STRINGS.issueExpireDate}</div>

                            <DatePicker
                                future={true}
                                futureAmt={5}
                                onChange={date => handleChange('renewExpiresOn', date)}
                            />
                        </div>

                        <div className='key-value-frame last'>
                            <div className='value-label'>{STRINGS.photoOfLicense}</div>

                            <div className={`key-value-file-input ${state.renewFile ? '' : 'bg-placeholder'}`}>
                                <input
                                    className='file-input'
                                    onChange={e => {
                                        let file = e.target.files[0],
                                            reader = new FileReader()

                                        reader.onload = (response) => {
                                            handleChange('renewFile', {
                                                base64: response.target.result,
                                                name: file.name,
                                                type: file.type
                                            })
                                        }

                                        reader.readAsDataURL(file)
                                    }}
                                    type='file'
                                />

                                <div className='file-label ellipsis'>{state.renewFile ? state.renewFile.name : STRINGS.selectFile}</div>

                                <button className={`file-button capitalize ${state.renewFile ? 'bg-grey-2' : 'bg-aqua'}`}>{STRINGS.chooseFile}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            show={isModal2}
            hide={resetDocumentRenewal}
        />
    )
}

export default RenewDocumentPane