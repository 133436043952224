import React from 'react'
import { graphql } from 'react-apollo'

import { flowRight as compose, set } from 'lodash'
import Cookie from 'js-cookie'
import moment from 'moment'

import UserQuery from '../../graphql/queries/Specialty'
import UserSpecialtyCreateMutation from '../../graphql/mutations/User/Specialty/Create'
import UserSpecialtyRemoveMutation from '../../graphql/mutations/User/Specialty/Remove'
import UserSpecialtyUpdateMutation from '../../graphql/mutations/User/Specialty/Update'

import Specialty from '../../components/Specialty'

import LoadingPane from '../../components/Shared/LoadingPane'

class SpecialtyContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isModal: false,
            mobile: window.innerWidth < 1000,
            onload: true
        }
    }

    componentDidMount() {
        this.setup()

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate() {
        this.setup()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    setup = () => {
        const { match, userQuery: { loading, specialties, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            let id = match.params.specialty

            this.setState({
                ...user,
                edit: (id === 'create') ? true : false,
                onload: false,
                options: specialties,
                specialty: (id === 'create') ? { isPrimary: false, specialty: {} } : user.specialties.find(s => s.id === id)
            })
        }
    }

    back = () => {
        this.props.history.goBack()
    }

    handleChange = (key, value) => {
        this.setState(set(this.state, key, value))
    }

    handleResize = () => {
        this.handleChange('mobile', window.innerWidth < 1000)
    }

    save = () => {
        const { specialty: { id, isPrimary, specialty, startedOn } } = this.state

        if (specialty.id && startedOn) {
            const { userSpecialtyCreateMutation, userSpecialtyUpdateMutation } = this.props

            this.setState({
                loading: true
            }, () => {
                let mutation = (id ? userSpecialtyUpdateMutation : userSpecialtyCreateMutation),
                    data = {
                        specialtyId: specialty.id,
                        startedOn: moment(startedOn, 'YYYY-MM-DD').toDate(),
                        isPrimary
                    }

                if (id) {
                    data.id = id
                } else {
                    data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME)
                }

                mutation({
                    variables: {
                        input: data
                    }
                }).then(response => {
                    const key = Object.keys(response.data)[0]
                    const { errors, success } = response.data[key]

                    if (success) {
                        this.back()
                    } else {
                        this.handleChange('loading', false)

                        window.alert(errors[0].message)
                    }
                })
            })
        }
    }

    specialtyRemove = (hide) => {
        const { match, userSpecialtyRemoveMutation } = this.props

        this.setState({
            loading: true
        }, () => {
            userSpecialtyRemoveMutation({
                variables: {
                    input: {
                        id: match.params.specialty
                    }
                }
            }).then(response => {
                const { errors, success } = response.data.userSpecialtyRemove

                this.setState({
                    loading: false
                }, () => {
                    if (success) {
                        setTimeout(() => {
                            hide()

                            setTimeout(() => this.back(), 450)
                        }, 200)
                    } else {
                        window.alert(errors[0].message)
                    }
                })
            })
        })
    } 

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <Specialty
                back={this.back}
                handleChange={this.handleChange}
                save={this.save}
                specialtyRemove={this.specialtyRemove}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: () => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME)
            }
        })
    }),
    graphql(UserSpecialtyCreateMutation, { name: 'userSpecialtyCreateMutation' }),
    graphql(UserSpecialtyRemoveMutation, { name: 'userSpecialtyRemoveMutation' }),
    graphql(UserSpecialtyUpdateMutation, { name: 'userSpecialtyUpdateMutation' })
)(SpecialtyContainer)