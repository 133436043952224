import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import LoadingPane from "../../components/Shared/LoadingPane";
import SessionVerifyTokenMutation from "../../graphql/mutations/Session/VerifyToken";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Cookie from "../../utils/js-cookie.overrides";



const TokenLogin = (props) => {
  const { sessionVerifyTokenMutation } = props;
  const { token } = useParams();

  const goToBaseRoute = () => {
    window.location.href = "/";
  };

  if (token) {
    sessionVerifyTokenMutation({
      variables: {
        tok: token
      },
    }).then((response) => {
      const {
        data: { sessionVerifyToken },
      } = response;

      Cookie.remove(process.env.REACT_APP_COOKIE_NAME);
      Cookie.remove(`${process.env.REACT_APP_COOKIE_NAME}-token`);
      Cookie.remove("org");

      if (sessionVerifyToken && sessionVerifyToken.user) {
        Cookie.set(
          process.env.REACT_APP_COOKIE_NAME,
          sessionVerifyToken.user.id
        );
        Cookie.set(
          `${process.env.REACT_APP_COOKIE_NAME}-token`,
          sessionVerifyToken.token
        );
        if (sessionVerifyToken.user.roles[0].org.id) {
          Cookie.set('org', sessionVerifyToken.user.roles[0].org.id);
        }

        const email = sessionVerifyToken.user.email
        const password_token = sessionVerifyToken.passwordToken

        if (password_token) {
          window.location.href = `/getting_started?e=${encodeURIComponent(email)}&token=${password_token}`
        } else {
          goToBaseRoute();
        }

      } else {
        console.log('invalid token');
        goToBaseRoute();
      }
    });
  }


  return (
    <LoadingPane />
  )
}


export default compose(
  graphql(SessionVerifyTokenMutation, {
    name: "sessionVerifyTokenMutation",
    options: (props) => ({
      client: window.client2,
    }),
  })
)(TokenLogin);
