import React from 'react'

import moment from 'moment'

const Test = (props) => {
    const { goToCredentialsRoute, state } = props
    const { answers, categories, mobile, testTaken } = state

    let isRule1 = testTaken.test.desc ? testTaken.test.desc.includes('__rule-1__') : false

    return (
        <div className='test-view full-screen-view'>
            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-back-button' onClick={goToCredentialsRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-back-arrow-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button' onClick={goToCredentialsRoute}>Back</button>
                }

                {mobile ?
                    <div className='page-header ellipsis bold'>{testTaken.title}</div>
                    :
                    <div className='page-header ellipsis'><b>{testTaken.title}</b>{` - Test - ${moment(testTaken.endedAt).format('MMM D, YYYY')}`}</div>
                }
            </div>

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='score-details-frame'>
                        <div className='score-header'>OVERALL SCORE</div>

                        <div className='score-frame'>
                            <div className='score'>{`${testTaken.score}% -`}</div>
                            <div className={`score ${testTaken.passed ? 'green' : 'orange'}`}>{testTaken.passed ? 'Pass' : 'Did Not Pass'}</div>
                        </div>
                    </div>

                    <div className={isRule1 ? 'rule-label' : 'hidden'}>A <b>failing</b> score indicates the need for follow up with an optometrist for a more comprehensive test to diagnose color vision deficiency.</div>

                    <div className='categories-frame'>
                        <div className='category-header'>CATEGORY BREAKDOWN</div>

                        <ul className='categories-list-view'>
                            {categories.map(category => {
                                const { id, questions, title } = category

                                let correct = questions.filter(q => answers[q.id])
                                
                                return (
                                    <li className='category-cell' key={id}>
                                        <div className='category-label bold ellipsis'>{title}</div>
                                        <div className='category-label'>{`${correct.length} of ${questions.length} Correct`}</div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Test