import React from 'react'
import { graphql } from 'react-apollo'
import { flowRight as compose, set } from 'lodash'

import Cookie from 'js-cookie'

import UserQuery from '../../../graphql/queries/Document'
import DocumentCreateMutation from '../../../graphql/mutations/Document/Create'
import DocumentRemoveMutation from '../../../graphql/mutations/Document/Remove'
import DocumentUpdateMutation from '../../../graphql/mutations/Document/Update'

import Document from '../../../components/Documents/Document'

import LoadingPane from '../../../components/Shared/LoadingPane'

class CertificationDocumentContainer extends React.Component {
    constructor(props) {
        super(props)

        const { match: { params: { document, type } }, mobile } = props
        
        this.state = {
            current: false,
            edit: (document === 'create'),
            kind: type.slice(0, type.length - 1),
            isModal: false,
            isModal2: false,
            loading: false,
            mobile,
            onload: (document !== 'create')
        }
    }

    componentDidMount() {
        const { userQuery: { loading, refetch } } = this.props
        const { onload } = this.state

        this._isMounted = true
        
        if (onload && !loading) {
            refetch().then(() => this.setup())
        } else {
            this.setup()
        }
    }

    componentDidUpdate(prevProps) {
        const { mobile } = this.props

        this.setup(prevProps.mobile !== mobile)
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    setup = (mobileDidChange) => {
        const { mobile, userQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            this.setState({
                ...user.document,
                current: user.document.expiredOn ? false : true,
                mobile: mobileDidChange ? mobile : this.state.mobile,
                onload: false
            })

        } else if (mobileDidChange) {
            this.handleChange('mobile', mobile)
        }
    }

    back = () => {
        const { history } = this.props

        if (history.location.state && history.location.state.previous) {
            history.push(history.location.state.previous)
        } else {
            history.push('/credentials')
        }
    }

    documentRemove = (hide) => {
        const { documentRemoveMutation } = this.props

        this.setState({
            loading: true
        }, () => {
            documentRemoveMutation({
                variables: {
                    input: {
                        id: this.state.id
                    }
                }
            }).then(response => {
                const { errors, success } = response.data.documentRemove

                if (success) {
                    setTimeout(() => {
                        hide()

                        setTimeout(() => this.back(), 450)
                    }, 200)
                } else {
                    this.handleChange('loading', false)

                    window.alert(errors[0].message)
                }
            })
        })
    }

    handleChange = (key, value) => {
        if (this._isMounted) this.setState(set(this.state, key, value))
    }

    save = (data) => {
        const { documentCreateMutation, documentUpdateMutation } = this.props
        const { id } = this.state

        if (data) {
            this.setState({
                loading: true
            }, async () => {
                let mutation = (id ? documentUpdateMutation : documentCreateMutation)

                // check if document exists
                if (id) {
                    data.id = id
                } else {
                    data.userId = Cookie.get(process.env.REACT_APP_COOKIE_NAME)
                }

                mutation({
                    variables: {
                        input: data
                    }
                }).then(response => {
                    const key = Object.keys(response.data)[0]
                    const { errors, success } = response.data[key]

                    if (success) {
                        this.back()
                    } else {
                        this.handleChange('loading', false)

                        window.alert(errors[0].message)
                    }
                })
            })
        }
    }

    saveAttachment = (file) => {
        return new Promise((success) => {
            let xhr = new XMLHttpRequest(),
                fileName = file.name,
                config = {
                    type: `image/${fileName.split('.')[1]}`
                },
                f = new File([file.base64], fileName, config),
                formData = new FormData()

            formData.append('file', f)

            xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL)

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    success(JSON.parse(xhr.response))
                }
            }

            xhr.send(formData)
        })
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <Document
                {...this.props}
                back={this.back}
                documentRemove={this.documentRemove}
                handleChange={this.handleChange}
                save={this.save}
                saveAttachment={this.saveAttachment}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
                document: props.match.params.document
            }
        })
    }),
    graphql(DocumentCreateMutation, { name: 'documentCreateMutation' }),
    graphql(DocumentRemoveMutation, { name: 'documentRemoveMutation' }),
    graphql(DocumentUpdateMutation, { name: 'documentUpdateMutation' })
)(CertificationDocumentContainer)