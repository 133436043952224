import React from 'react'

import ModalPane from '../../Shared/ModalPane'

const ChangePasswordPane = (props) => {
    const { handleChange, resetModal, resetPassword, state } = props
    const { isModal, mobile } = state

    return (
        <ModalPane
            className={mobile ? 'reset-password-pane mobile' : 'reset-password-pane'}
            content={hide => (
                <div className='reset-password-content'>
                    <div className='toolbar'>
                        <button className='cancel-button' onClick={hide}>Cancel</button>

                        <div className='page-header'>Change Password</div>

                        <button className='save-button' onClick={() => resetPassword(hide)}>Save</button>
                    </div>

                    <div className='reset-password-content-frame'>
                        <div className='form-frame'>
                            <div className='form-input-frame'>
                                <div className='input-title'>Current Password</div>

                                <input
                                    className='form-input'
                                    onChange={e => handleChange('password', e.target.value)}
                                    placeholder='Enter current password'
                                    type='password'
                                />
                            </div>

                            <div className='form-input-frame'>
                                <div className='input-title'>New Password</div>

                                <input
                                    className='form-input'
                                    onChange={e => handleChange('password1', e.target.value)}
                                    placeholder='Enter new password'
                                    type='password'
                                />
                            </div>

                            <div className='form-input-frame password-2'>
                                <div className='input-title'>Re-Enter New Password</div>

                                <input
                                    className='form-input'
                                    onChange={e => handleChange('password2', e.target.value)}
                                    placeholder='Confirm new password'
                                    type='password'
                                />
                            </div>

                            <div className={state.error ? 'error-label una' : 'hide'}>{state.error}</div>
                        </div>
                    </div>
                </div>
            )}
            hide={resetModal}
            show={isModal}
        />
    )
}

export default ChangePasswordPane