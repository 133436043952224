import React from "react";

import _ from "lodash";
import moment from "moment";

import DatePicker from "../../../Shared/DatePicker";
import LoadingPane from "../../../Shared/LoadingPane";
import ModalPane from "../../../Shared/ModalPane";
import SelectMenu from "../../../Shared/SelectMenu";

import STRINGS from "../../../../localization";
import FileInput from "../../../Control/FileInput";
import DocumentDisplay from "../../../Control/DocumentDisplay";

const Vaccination = (props) => {
  return props.state.edit ? <Edit {...props} /> : <Read {...props} />;
};

const Read = (props) => {
  const { back, documentRemove, handleChange, state, types } = props;
  const {
    loading,
    isModal,
    imageUrl,
    imageBackUrl,
    mobile,
    title,
    verificationData,
    user
  } = state;

  let vaccination = types.find((o) => o.id === title);

  return (
    <div className="document-read-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${_.startCase(
          state.kind
        )}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className="mobile-button delete bg-una"
              onClick={() => handleChange("isModal", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/trash-can-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>

            <div
              className="mobile-button edit bg-grey-2"
              onClick={() => handleChange("edit", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/edit-mobile-button-teal-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className="save-button bg-una capitalize"
              onClick={() => handleChange("isModal", true)}
            >
              {STRINGS.delete}
            </button>

            <button
              className="save-button bg-aqua capitalize"
              onClick={() => handleChange("edit", true)}
            >
              {STRINGS.edit}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame scroll">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.title}</div>

            {vaccination ? (
              <div className="value-label capitalize bold">
                {vaccination.value}
              </div>
            ) : (
              <div className="value-label capitalize hint italic">
                {STRINGS.notProvided}
              </div>
            )}
          </div>

          <div className="read-value-frame empty" />

          <div className="read-value-frame empty" />

          <div className="read-value-frame">
            <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 1st ${STRINGS.vaccination}`}</div>
            <div className="value-label capitalize bold">
              {moment(verificationData.date1).format("MMM D, YYYY")}
            </div>
          </div>

          <div className={verificationData.date2 ? "read-value-frame" : "hide"}>
            <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 2nd ${STRINGS.vaccination}`}</div>
            <div className="value-label capitalize bold">
              {moment(verificationData.date2).format("MMM D, YYYY")}
            </div>
          </div>

          {title === "hepB" && verificationData.date3 ? (
            <div className="read-value-frame">
              <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 3rd ${STRINGS.vaccination}`}</div>
              <div className="value-label capitalize bold">
                {moment(verificationData.date3).format("MMM D, YYYY")}
              </div>
            </div>
          ) : (
            <div className="read-value-frame empty" />
          )}

          <div className="document-images-frame">
            <DocumentDisplay
              docUrl={imageUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title}`}
            />

            <DocumentDisplay
              docUrl={imageBackUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title} second`}
            />
            <DocumentDisplay
              docUrl={verificationData.image1Url}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title} third`}
            />
          </div>
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.documentDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.cancel}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.delete}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange("isModal", false)}
      />
    </div>
  );
};

const Edit = (props) => {
  const { back, handleChange, handleTitle, match, save, state, types } = props;
  const {
    image,
    imageBack,
    loading,
    imageUrl,
    imageBackUrl,
    mobile,
    title,
    verificationData,
  } = state;

  let existing = match.params.document !== "create",
    validations = {
      covid_19: verificationData.date1 && (image || imageUrl),
      hepB:
        verificationData.date1 &&
        verificationData.date2 &&
        verificationData.date3 &&
        (image || imageUrl),
      influenza: verificationData.date1 && (image || imageUrl),
      mmr:
        verificationData.date1 && verificationData.date2 && (image || imageUrl),
      tdap: verificationData.date1 && (image || imageUrl),
      varicella:
        verificationData.date1 && verificationData.date2 && (image || imageUrl),
    },
    singleDate = title === "influenza" || title === "tdap",
    valid = validations[title];

  return (
    <div className="document-edit-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div
            className="mobile-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button
            className="back-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${
          existing ? STRINGS.edit : STRINGS.add
        } ${_.startCase(state.kind)}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className={`mobile-button save ${
                valid ? "bg-aqua" : "bg-disabled"
              }`}
              onClick={() => save(valid)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/button-checkmark-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className={`save-button ${valid ? "bg-aqua" : "bg-disabled"}`}
              onClick={() => save(valid)}
            >
              {STRINGS.save}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="key-value-frame">
            <div className="value-label capitalize">{STRINGS.type}</div>

            <SelectMenu
              className="key-value-menu"
              onChange={handleTitle}
              options={types}
              placeholder={`${STRINGS.select} ${STRINGS.vaccination} ${STRINGS.type}`}
              value={title || "hint"}
            />
          </div>

          <div className="key-value-frame empty" />

          <div className="key-value-frame empty" />

          <div className={title ? "flex-frame" : "hidden"}>
            <div className="key-value-frame">
              <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 1st ${STRINGS.vaccination}`}</div>

              <DatePicker
                defaultValue={verificationData.date1}
                future={true}
                futureAmt={9}
                onChange={(date) =>
                  handleChange("verificationData.date1", date)
                }
              />
            </div>

            <div
              className={`key-value-frame ${singleDate ? "empty" : ""}`}
              style={{ marginBottom: 12 }}
            >
              <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 2nd ${STRINGS.vaccination}`}</div>

              <DatePicker
                defaultValue={verificationData.date2}
                future={true}
                futureAmt={9}
                onChange={(date) =>
                  handleChange("verificationData.date2", date)
                }
              />
            </div>

            {title === "hepB" ? (
              <div className="key-value-frame">
                <div className="value-label capitalize">{`${STRINGS.date} ${STRINGS.of} 3rd ${STRINGS.vaccination}`}</div>

                <DatePicker
                  defaultValue={verificationData.date3}
                  future={true}
                  futureAmt={9}
                  onChange={(date) =>
                    handleChange("verificationData.date3", date)
                  }
                />
              </div>
            ) : (
              <div className="key-value-frame empty" />
            )}

            <div className="key-value-frame">
              <div className="value-label capitalize">{`${STRINGS.image} 1`}</div>
              <FileInput
                handleChange={handleChange}
                fileType={"image"}
                image={image}
                imageUrl={imageUrl}
                strings={STRINGS}
              />
            </div>

            <div
              className={`key-value-frame ${
                singleDate || title !== "hepB" ? "hide" : ""
              }`}
            >
              <div className="value-label capitalize">{`${STRINGS.image} 2`}</div>
              <FileInput
                handleChange={handleChange}
                fileType={"imageBack"}
                image={imageBack}
                imageUrl={imageBackUrl}
                strings={STRINGS}
              />
            </div>

            <div
              className={`key-value-frame ${
                singleDate || title !== "hepB" ? "hide" : ""
              }`}
            >
              <div className="value-label capitalize">{`${STRINGS.image} 3`}</div>
              <FileInput
                handleChange={handleChange}
                fileType={"verificationData.image1"}
                image={verificationData.image1}
                imageUrl={verificationData.image1Url}
                strings={STRINGS}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vaccination;
