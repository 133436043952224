import React from 'react'
import { graphql } from 'react-apollo'

import { flowRight as compose } from 'lodash'
import Cookie from 'js-cookie'

import UserQuery from '../../graphql/queries/Checklist'

import Checklist from '../../components/Checklist/Category'
import ChecklistScore from '../../ChecklistScore';
import LoadingPane from '../../components/Shared/LoadingPane'

class ChecklistContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            onload: true
        }
    }

    componentDidMount() {
        const { userQuery: { loading, refetch } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            refetch().then(this.setup())
        } else {
            this.setup()
        }

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate() {
        this.setup()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    setup = () => {
        const { userQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            let status = user.checklistTaken.status

            if (status === 'finished') {
                const { answers, checklist } = user.checklistTaken

                const answersHash = {};

                answers.forEach(answer => answersHash[answer.question.id] = parseFloat(answer.value));

                const checklistClone = ChecklistScore.calculateAvgChecklistScore(checklist, answersHash);

                this.setState({
                    ...user.checklistTaken,
                    checklist: checklistClone,
                    onload: false
                })
            } else {
                window.alert(status)

                this.props.history.push('')
            }
        }
    }

    goToChecklistRoute = () => {
        const { history, match } = this.props

        history.push(`/checklists/${match.params.checklist}`)
    }

    goToCategorySectionRoute = (section) => {
        const { history, match } = this.props

        history.push(`/checklists/${match.params.checklist}/categories/${match.params.category}/sections/${section}`)
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleResize = () => {
        this.handleChange('mobile', window.innerWidth < 1000)
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <Checklist
                goToChecklistRoute={this.goToChecklistRoute}
                goToCategorySectionRoute={this.goToCategorySectionRoute}
                match={this.props.match}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
                checklist: props.match.params.checklist
            }
        })
    })
)(ChecklistContainer)