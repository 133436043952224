import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import client from "./graphql/ApolloClient";
import client2 from "./graphql/AppolloClient2";

import App from "./containers/main";

import "./theme";

import * as serviceWorker from "./serviceWorker";

//do not do this!  temporary stopgap to be removed when we move to our backend.
window.client2 =
  process.env.REACT_APP_ENVIRONMENT.toUpperCase() === 'DEVELOPMENT'
    ? client2
    : client;
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
