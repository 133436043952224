import React from 'react'

import _ from 'lodash'

import STRINGS from '../../../../../localization'

const HepBDeclination = (props) => {
    const { state: { kind } } = props

    return (
        <div className='key-value-frame full'>
            <div className='form-frame'>
                <div className='form-name'>{STRINGS[_.camelCase(kind)]}</div>

                <div className='form-label line-break'>I understand that due to my occupational exposure to blood or other potentially infectious materials I may be at risk of acquiring hepatitis B virus (HBV) infection.</div>

                <div className='form-label line-break'>I have been given the opportunity to be vaccinated with hepatitis B vaccine, at no charge to me; however, I decline hepatitis B vaccination at this time.</div>

                <div className='form-label line-break'>I understand that by declining this vaccine I continue to be at risk of acquiring hepatitis B, a serious disease.</div>

                <div className='form-label'>If, in the future I continue to have occupational exposure to blood or other potentially infectious materials and I want to be vaccinated with hepatitis B vaccine, I can receive the vaccination series at no charge to me, paid for by my employer.</div>

            </div>

        </div>
    )
}

export default HepBDeclination