import React from 'react'
import PropTypes from 'prop-types'

class ModalPane extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            animate: false,
            mobile: window.innerWidth < 1000
        }
    }

    componentDidMount() {
        this._isMounted = true

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate(prevProps) {
        const that = this
        const { show } = this.props

        if (prevProps.show !== show) {
            if (show) {
                setTimeout(() => that.animateUp(), 100)
            } else {
                setTimeout(() => that.animateDown(), 100)
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false

        window.removeEventListener('resize', this.handleResize)
    }

    animateUp = () => {
        if (this._isMounted) this.setState({ animate: true })
    }

    animateDown = () => {
        if (this._isMounted) this.setState({ animate: false })
    }

    handleContentClick = (e) => {
        e.stopPropagation()
    }

    handleResize = () => {
        this.setState({ mobile: window.innerWidth < 1000 })
    }

    hide = () => {
        this.animateDown()

        setTimeout(() => this.props.hide(), 300)
    }

    render() {
        const { className, content, disableExit, show } = this.props
        const { animate, mobile } = this.state

        if (show) {
            return (
                <div className={`modal-pane ${className ? className : ""}`}>
                    <div className='background' onClick={disableExit ? null : this.hide} />

                    <div
                        className={`modal-pane-content layout-center scroll ${mobile ? 'mobile' : ''}`}
                        onClick={(e) => this.handleContentClick(e)}
                        style={{ transform: animate ? 'translate(-50%,-50%)' : `translate(-50%,${window.innerHeight}px)` }}
                    >
                        {content(this.hide)}
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

ModalPane.propTypes = {
    className: PropTypes.string,
    content: PropTypes.func,
    hide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
}

export default ModalPane;