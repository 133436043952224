import React from "react";

import _ from "lodash";

import LoadingPane from "../../../Shared/LoadingPane";
import ModalPane from "../../../Shared/ModalPane";

import STRINGS from "../../../../localization";
import FileInput from "../../../Control/FileInput";
import DocumentDisplay from "../../../Control/DocumentDisplay";

const LetterOfRecommendation = (props) => {
  return props.state.edit ? <Edit {...props} /> : <Read {...props} />;
};

const Read = (props) => {
  const { back, documentRemove, handleChange, state } = props;
  const { loading, isModal, imageUrl, imageBackUrl, mobile,user,title } = state;

  return (
    <div className="document-read-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${_.startCase(
          state.kind
        )}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className="mobile-button delete bg-una"
              onClick={() => handleChange("isModal", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/trash-can-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>

            <div
              className="mobile-button edit bg-grey-2"
              onClick={() => handleChange("edit", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/edit-mobile-button-teal-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className="save-button bg-una capitalize"
              onClick={() => handleChange("isModal", true)}
            >
              {STRINGS.delete}
            </button>

            <button
              className="save-button bg-aqua capitalize"
              onClick={() => handleChange("edit", true)}
            >
              {STRINGS.edit}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame scroll">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="document-images-frame">
            <DocumentDisplay
              docUrl={imageUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title}`}
            />

            <DocumentDisplay
              docUrl={imageBackUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title} back`}
            />
          </div>
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.documentDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.cancel}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.delete}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange("isModal", false)}
      />
    </div>
  );
};

const Edit = (props) => {
  const { back, handleChange, match, save, state } = props;
  const { image, imageBack, loading, imageUrl, imageBackUrl, mobile } = state;

  let existing = match.params.document !== "create",
    valid = image || imageBack;

  return (
    <div className="document-edit-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div
            className="mobile-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button
            className="back-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${
          existing ? STRINGS.edit : STRINGS.add
        } ${_.startCase(state.kind)}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className={`mobile-button save ${
                valid ? "bg-aqua" : "bg-disabled"
              }`}
              onClick={save}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/button-checkmark-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className={`save-button ${valid ? "bg-aqua" : "bg-disabled"}`}
              onClick={save}
            >
              {STRINGS.save}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="key-value-frame">
            <div className="value-label capitalize">{`${STRINGS.image} 1`}</div>
            <FileInput
              handleChange={handleChange}
              fileType={"image"}
              image={image}
              imageUrl={imageUrl}
              strings={STRINGS}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{`${STRINGS.image} 2`}</div>
            <FileInput
              handleChange={handleChange}
              fileType={"imageBack"}
              image={imageBack}
              imageUrl={imageBackUrl}
              strings={STRINGS}
            />
          </div>

          <div className="key-value-frame empty" />
        </div>
      </div>
    </div>
  );
};

export default LetterOfRecommendation;
