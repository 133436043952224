import React, { useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import Checkout from "./Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentQuery from "../../graphql/queries/Payment";

const stripePromise = loadStripe("pk_test_51Ovi1S2MrtBWlJDlnMhArfYvm61xcLwPem0llEnjjTeIxlEtyCbGSsYT5a7ej7OKrwXadoFwYoBeCT4qDIsnzedc00SUXj1Zfc");

const PaymentCheckoutContainer = (props) => {
  const [onload, setOnLoad] = useState(true)
  const [clientSecret, setClientSecret] = useState()
  const [productData, setProductData] = useState()

  const {
    paymentQuery: { loading, payment },
  } = props

  if (onload && !loading) {
    if (payment?.clientSecret) {
      setOnLoad(false)
      setClientSecret(payment?.clientSecret);
      setProductData(payment?.productMetadata);
    }
  }

  const appearance = {
    theme: 'night',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <Checkout
            productData={productData}
            clientSecret={clientSecret}
          />
        </Elements>
      )}

    </div>
  )
}

export default compose(
  graphql(PaymentQuery, {
    name: 'paymentQuery',

    options: () => {
      const paymentIntentId = new URLSearchParams(window.location.search).get(
        "payment_intent"
      );
      return {
        variables: {
          paymentId: paymentIntentId
        }
      }
    }
  })
)(PaymentCheckoutContainer)