import React from 'react'
import { graphql } from 'react-apollo'
import { flowRight as compose } from 'lodash'

import Cookie from 'js-cookie'

import UserQuery from '../../graphql/queries/Roles'

import Roles from '../../components/Roles'

import LoadingPane from '../../components/Shared/LoadingPane'

class RolesContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            onload: true,
            role: {}
        }
    }

    componentDidMount() {
        this.setup()

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate() {
        this.setup()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    setup = () => {
        const { history, userQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            if (user.roles.length > 1) {
                this.setState({
                    ...user,
                    onload: false
                })
            } else {
                let role = user.roles[0]

                Cookie.set('org', role.org.id);

                history.push(`/organizations/${role.org.id}/credentials`)
            }
        }
    }

    goToCredentialsRoute = () => {
        const { history } = this.props
        const { role } = this.state

        if (role.org.id) {
            Cookie.set('org', role.org.id);

            history.push(`/organizations/${role.org.id}/credentials`)
        }
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleResize = () => {
        this.handleChange('mobile', window.innerWidth < 1000)
    }
    
    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <Roles
                goToCredentialsRoute={this.goToCredentialsRoute}
                handleChange={this.handleChange}
                state={this.state}
            />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: () => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME)
            }
        })
    })
)(RolesContainer)