import React from 'react'

import moment from 'moment'

import LoadingPane from '../Shared/LoadingPane'
import ModalPane from '../Shared/ModalPane'

import STRINGS from '../../localization'

const SpecialtyRead = (props) => {
    const { back, handleChange, specialtyRemove, state } = props
    const { loading, isModal, mobile, specialty } = state

    return (
        <div className='specialty-read-view full-screen-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-button bg-grey-2' onClick={back}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button bg-grey-2' onClick={back}>{STRINGS.cancel}</button>
                }

                <div className='page-title capitalize'>{`${STRINGS.view} ${STRINGS.specialty}`}</div>

                {mobile ?
                    <div className='button-frame'>
                        {specialty.isPrimary ?
                            null
                            :
                            <div className='mobile-button delete bg-una' onClick={() => handleChange('isModal', true)}>
                                <div className='mobile-button-icon'>
                                    <img src={require('../../theme/assets/buttons/trash-can-icon-2x.png')} alt='button-x-icon' />
                                </div>
                            </div>
                        }

                        <div className='mobile-button edit bg-grey-2' onClick={() => handleChange('edit', true)}>
                            <div className='mobile-button-icon'>
                                <img src={require('../../theme/assets/edit-mobile-button-teal-2x.png')} alt='button-x-icon' />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='button-frame'>
                        {specialty.isPrimary ? 
                            null 
                            : 
                            <button className='save-button capitalize bg-una' onClick={() => handleChange('isModal', true)}>{STRINGS.delete}</button>
                        }

                        <button className='save-button capitalize bg-aqua' onClick={() => handleChange('edit', true)}>{STRINGS.edit}</button>
                    </div>
                }
            </div>

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.title}</div>
                        <div className='value-label capitalize bold'>{specialty.name}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.startedOn}</div>
                        <div className='value-label capitalize bold'>{moment(specialty.startedOn).format('MMM DD, YYYY')}</div>
                    </div>

                    <div className='read-value-frame empty'></div>
                </div>
            </div>

            <ModalPane
                className='specialty-modal-pane'
                content={hide => (
                    <div>
                        <div className='modal-header'>Are you sure you want to delete this specialty?</div>

                        <div className='modal-button-frame'>
                            <button className='modal-button bg-grey-2' onClick={hide}>{STRINGS.cancel}</button>
                            <button className='modal-button bg-una' onClick={() => specialtyRemove(hide)}>{STRINGS.save}</button>
                        </div>
                    </div>
                )}
                show={isModal}
                hide={() => handleChange('isModal', false)}
            />
        </div>
    )
}

export default SpecialtyRead