// import React from "react";
// import Benefits from "./Benefits";
// import PriceCard from "./PriceCard";
// import "./Subscribe.scss";
// import Navigation from "../Credentials/Navigation";
// import SubscriptionsList from "../../graphql/queries/Subscriptions";
// import { useQuery } from "react-apollo";

// import { graphql } from "react-apollo";
// import { flowRight as compose } from "lodash";

// import PaymentIntentCreateMutation from "../../graphql/mutations/Subscription/PaymentIntent";
// import { useHistory } from "react-router-dom"

// const SubscribePage = (props) => {
//   const { state } = props.location;

//   const mobile = state && state.mobile;

//   const history = useHistory();

//   const { data } = useQuery(SubscriptionsList, {
//     variables: { status: ["active"] },
//   });

//   let subscription = null;
//   if (data && data.subscriptions && data.subscriptions.edges.length > 0) {
//     subscription = data.subscriptions.edges[0].node;
//   }

//   const handlePaymentIntent = (subs_id) => {
//     let { paymentIntentCreateMutation } = props

//     let data = {
//       productType: 'subscription',
//       productId: subs_id
//     }

//     paymentIntentCreateMutation({
//       variables: {
//         input: data,
//       }
//     }).then((res) => {
//       const key = Object.keys(res.data)[0];
//       const { errors, success, result } = res.data[key];

//       if (success) {
//         history.replace(`/subscribe/checkout?payment_intent=${result.orderId}`)
//       } else {
//         window.alert(errors[0].message);
//       }
//     })
//   }

//   const overflowStyle = {
//     overflowY: 'auto',
//   };

//   return (
//     <div className="account-view full-screen-view">
//       <Navigation {...props} />

//       <div
//         className={`content-frame scroll ${mobile ? "mobile" : ""}`}
//         style={{ top: mobile ? 48 : 60 }}
//       >
//         <div className="BlockCenter">
//           <div className="pricePage">
//           <Benefits style={overflowStyle} />
//             {subscription && (
//               <>
//                 <PriceCard
//                   className="card0"
//                   bestValue="Best Value"
//                   month={subscription.title}
//                   discountValue="100$"
//                   monthValue={subscription.amount}
//                   creditEarn={subscription.creditEarn}
//                   ValueMonth="912"
//                   permonth="12"
//                   subscription={subscription}
//                   onClick={() => handlePaymentIntent(subscription.id)}
//                 />
//                 <PriceCard
//                   className="card1"
//                   ValueStyle="NoValue"
//                   month="1 Month"
//                   monthValue="120$"
//                   ValueMonth="120$"
//                   permonth=""
//                   subscription={subscription}
//                   onClick={() => handlePaymentIntent(subscription.id)}
//                 />
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default compose(
//   graphql(PaymentIntentCreateMutation, { name: 'paymentIntentCreateMutation' })
// )(SubscribePage)

import React from "react";
import Benefits from "./Benefits";
import PriceCard from "./PriceCard";
import "./Subscribe.scss";
import Navigation from "../Credentials/Navigation";
import SubscriptionsList from "../../graphql/queries/Subscriptions";
import { useQuery } from "react-apollo";

import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import PaymentIntentCreateMutation from "../../graphql/mutations/Subscription/PaymentIntent";
import { useHistory } from "react-router-dom";

const SubscribePage = (props) => {
  const { state } = props.location;

  const mobile = state && state.mobile;

  const history = useHistory();

  const { data: subscriptionsData } = useQuery(SubscriptionsList, {
    variables: { status: ['active'] },
  });

  let subscription = null;
  let subscriptions = null;
  if (
    subscriptionsData &&
    subscriptionsData.subscriptions &&
    subscriptionsData.subscriptions.edges.length > 0
  ) {
    subscription = subscriptionsData.subscriptions.edges[0].node;
    subscriptions = subscriptionsData.subscriptions.edges.filter(
      (sub, index) => {
        return sub.node.id != subscription.id;
      },
    );
  }

  const handlePaymentIntent = (subs_id) => {
    let { paymentIntentCreateMutation } = props;

    let data = {
      productType: "subscription",
      productId: subs_id,
    };

    paymentIntentCreateMutation({
      variables: {
        input: data,
      },
    }).then((res) => {
      const key = Object.keys(res.data)[0];
      const { errors, success, result } = res.data[key];

      if (success) {
        history.replace(`/subscribe/checkout?payment_intent=${result.orderId}`);
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  return (
    <div className="account-view full-screen-view">
      <Navigation {...props} />

      <div className={`content-frame scroll ${mobile ? "mobile" : ""}`}>
        <div className="BlockCenter">
          <div className="pricePage">
            <Benefits />
            <div
              className="desc-content"
              style={{ height: "550px", overflowY: "auto" }}
            >
              {/* {subscription &&
                subscription.map((sub) => (
                  <PriceCard
                    key={sub.node.id}
                    // className="card0"
                    bestValue={sub.node.status === "active" ? "Best Value" : ""}
                    month={sub.node.title}
                    // discountValue={sub.node.status === "active" ? "100$" : ""}
                    monthValue={sub.node.amount}
                    creditEarn={sub.node.creditEarn}
                    // ValueMonth="912"
                    // permonth="12"
                    // subscription={sub}
                    onClick={() => handlePaymentIntent(sub.node.id)}
                  />
                ))} */}
              {subscription && (
                <>
                  <PriceCard
                    className="card0"
                    bestValue="Best Value"
                    month={subscription.title}
                    discountValue="100$"
                    monthValue={subscription.amount}
                    creditEarn={subscription.creditEarn}
                    ValueMonth={`${(
                      subscription.amount /
                      parseInt(subscription.validity?.split(" ")[0])
                    ).toFixed(2)}`}
                    permonth={subscription.validity?.split(" ")[0]}
                    subscription={subscription}
                    onClick={() => handlePaymentIntent(subscription.id)}
                  />
                </>
              )}
              {subscriptions &&
                subscriptions.map(({ node }, index) => (
                  <PriceCard
                    className={`card${index + 1}`}
                    ValueStyle="NoValue"
                    month={node.title}
                    monthValue={node.amount}
                    creditEarn={node.creditEarn}
                    ValueMonth={`${(
                      node.amount / parseInt(node.validity?.split(" ")[0])
                    ).toFixed(2)}`}
                    permonth={node.validity?.split(" ")[0]}
                    subscription={node}
                    onClick={() => handlePaymentIntent(node.id)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  graphql(PaymentIntentCreateMutation, { name: "paymentIntentCreateMutation" })
)(SubscribePage);
