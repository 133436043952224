import React from 'react'
import { Image, Document, Page, Text, View } from '@react-pdf/renderer'

import _ from 'lodash'
import moment from 'moment'

import STRINGS from '../../../../../localization'

const FluDeclinationPdf = (state) => {
    const { kind, issuedOn, signature } = state

    return (
        <Document>
            <Page size='A4' style={{ padding: 40 }}>
                {/* header */}
                <View style={{ textAlign: 'center', marginBottom: 15 }}>
                    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{STRINGS[_.camelCase(kind)]}</Text>
                </View>

                {/* subheader */}
                <View style={{ marginTop: 15, marginBottom: 5 }}>
                    <Text style={{ fontSize: 10, fontWeight: 800 }}>
                        I acknowledge that I understand the following:
                    </Text>
                </View>

                {/* list view */}
                <View style={{ display: 'flex', flexDirection: 'column', marginBottom: 25 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Chronic Cough</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>I understand that, by refusing such vaccination, it is my professional licensing obligation to wear a surgical mask during each direct patient contract in the performance of my professional duties at any health care facility during any declared period in which flu is widespread.  The health care facility is required to provide the surgical face masks.</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>I understand that the consequence for failing to do so shall result in a one hundred-dollar ($100) fine for each violation.</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Failing to do so may also result in a complaint of Unprofessional Conduct being presented to the licensing board that has authority over my professional license.</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>I understand that such licensing complaint, if proven, may result in a sanction such as reprimand, or suspension or revocation of my professional license.</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>No healthcare worker shall be required to explain his or her refusal to obtain an annual seasonal influenza vaccination, nor shall any health care facility inquire into the basis of such refusal.</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>I understand that this Declination is required annually and will be submitted to any client facility/health care facility I am assigned to.</Text>
                    </View>
                </View>

                <View style={{ marginBottom: 20 }}>
                    <Text style={{ fontSize: 10, fontWeight: 800, marginBottom: 5 }}>
                        New York:
                    </Text>

                    <Text style={{ fontSize: 10 }}>
                        Any employee in New York is required to abide by the Public Health Law.
                        The law requires that during the influenza season healthcare workers not vaccinated against the flu to wear a surgical mask while in areas where patients or residents may be present.
                        This would include, but not be limited to, patient rooms, nurses' stations, hallways and elevators where patients might be present, cafeterias if patients may be present (except when the unvaccinated healthcare worker is eating), and patients' homes when providing home care.
                        As per the regulation masks should be changed after leaving the room or completing care of a patient/resident on isolation precautions, whenever it is soiled or might have become soiled, and per the protocol of the facility/residence/agency.
                    </Text>

                    <Text style={{ fontSize: 10 }}>
                        Other states or client facilities may enact the same requirements as the New York regulation. Employees are required to abide by the policies and procedures in their respective state.
                    </Text>
                </View>

                {/* signature */}
                <View style={{ flexDirection: 'column', marginBottom: 30, width: '33%' }}>
                    <Image
                        style={{ position: 'relative', top: 5 }}
                        src={{ uri: signature }}
                    />

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text style={{ fontSize: 10 }}>Signature</Text>
                    </View>
                </View>

                {/* date */}
                <View style={{ fontSize: 10, width: '33%' }}>
                    <Text>{moment(issuedOn).format('MMM D, YYYY')}</Text>

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text>Date</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default FluDeclinationPdf