import { gql } from 'apollo-boost'

const UserCourseAnswerUpdateMutation = gql`
    mutation UserCourseAnswerUpdateMutation($input: CourseAnswerUpdateUpdateInput) {
        userCourseAnswerUpdate(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserCourseAnswerUpdateMutation