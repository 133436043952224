import React from 'react'

import _ from 'lodash'
import moment from 'moment'

import AutoComplete from '../../Shared/AutoComplete'
import Checkbox from '../../Shared/Checkbox'
import DatePicker from '../../Shared/DatePicker'
import LoadingPane from '../../Shared/LoadingPane'
import ModalPane from '../../Shared/ModalPane'
import SelectMenu from '../../Shared/SelectMenu'

import STRINGS from '../../../localization'

import { positionTitles } from '../../../utils/Constants'

const EducationDocument = (props) => {
    return props.state.edit ? <Edit {...props} /> : <Read {...props} />
}

const Read = (props) => {
    const { back, documentRemove, handleChange, state } = props
    const { city, endedOn, isModal, kind, loading, mobile, name, positionTitle, specialties, startedOn } = state

    let position = positionTitles.find(p => p.id === positionTitle),
        specialty = specialties.find(s => s.id === kind)

    return (
        <div className='document-read-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-button bg-grey-2' onClick={back}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button bg-grey-2' onClick={back}>{STRINGS.cancel}</button>
                }

                <div className='page-title'>{`${STRINGS.view} ${STRINGS.workExperience}`}</div>

                {mobile ?
                    <div className='button-frame'>
                        <div className='mobile-button delete bg-una' onClick={() => handleChange('isModal', true)}>
                            <div className='mobile-button-icon'>
                                <img src={require('../../../theme/assets/buttons/trash-can-icon-2x.png')} alt='button-x-icon' />
                            </div>
                        </div>

                        <div className='mobile-button edit bg-grey-2' onClick={() => handleChange('edit', true)}>
                            <div className='mobile-button-icon'>
                                <img src={require('../../../theme/assets/edit-mobile-button-teal-2x.png')} alt='button-x-icon' />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='button-frame'>
                        <button className='save-button bg-una capitalize' onClick={() => handleChange('isModal', true)}>{STRINGS.delete}</button>
                        <button className='save-button bg-aqua capitalize' onClick={() => handleChange('edit', true)}>{STRINGS.edit}</button>
                    </div>
                }
            </div>

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.facilityName}</div>
                        <div className='value-label capitalize bold'>{name}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.city}</div>
                        <div className='value-label capitalize bold'>{city}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.state}</div>
                        <div className='value-label capitalize bold'>{state.state}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.positionTitle}</div>
                        <div className='value-label capitalize bold'>{position.value}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.department}</div>
                        <div className={`value-label capitalize ${specialty ? 'bold' : 'hint italic'}`}>{specialty ? specialty.name : STRINGS.notProvided}</div>
                    </div>

                    <div className='read-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.dateRange}</div>
                        <div className='value-label capitalize bold'>{`${moment(startedOn).format('MMM YYYY')} - ${endedOn ? moment(endedOn).format('MMM YYYY') : STRINGS.present}`}</div>
                    </div>

                    <div className='read-value-frame empty' />
                </div>
            </div>

            <ModalPane
                className='document-delete-pane'
                content={hide => (
                    <div>
                        <div className='modal-header'>{STRINGS.workExperienceDeletePaneMessage}</div>

                        <div className='modal-button-frame'>
                            <button className='modal-button bg-grey-2' onClick={hide}>{STRINGS.cancel}</button>
                            <button className='modal-button bg-una' onClick={() => documentRemove(hide)}>{STRINGS.delete}</button>
                        </div>
                    </div>
                )}
                show={isModal}
                hide={() => handleChange('isModal', false)}
            />

        </div>
    )
}

const Edit = (props) => {
    const { back, handleChange, handlePlace, match, save, state } = props
    const { current, endedOn, kind, loading, mobile, name, positionTitle, specialties, startedOn } = state

    let existing = (match.params.document !== 'create'),
        valid = (name && positionTitle && startedOn && (endedOn || current))

    return (
        <div className='document-edit-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            <div className='toolbar'>
                {mobile ?
                    <div className='mobile-button bg-grey-2' onClick={() => existing ? handleChange('edit', false) : back()}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>
                    :
                    <button className='back-button bg-grey-2' onClick={() => existing ? handleChange('edit', false) : back()}>{STRINGS.cancel}</button>
                }

                <div className='page-title'>{`${existing ? STRINGS.edit : STRINGS.add} ${STRINGS.workExperience}`}</div>

                {mobile ?
                    <div className='button-frame'>
                        <div className={`mobile-button save ${valid ? 'bg-aqua' : 'bg-disabled'}`} onClick={save}>
                            <div className='mobile-button-icon'>
                                <img src={require('../../../theme/assets/buttons/button-checkmark-icon-2x.png')} alt='button-x-icon' />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='button-frame'>
                        <button className={`save-button ${valid ? 'bg-aqua' : 'bg-disabled'}`} onClick={save}>{STRINGS.save}</button>
                    </div>
                }
            </div>

            <div className='content-frame'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.facilityName}</div>

                        <AutoComplete
                            className='key-value-input'
                            defaultValue={name || ''}
                            onChange={handlePlace}
                            placeholder={`${STRINGS.enter} ${STRINGS.facilityName}`}
                        />
                    </div>

                    {/* <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.city}</div>

                        <input
                            className='key-value-input'
                            onChange={e => handleChange('city', e.target.value)}
                            placeholder={`${STRINGS.enter} ${STRINGS.city}`}
                            value={city || ''}
                        />
                    </div> */}

                    {/* <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.state}</div>

                        <SelectMenu
                            className='key-value-menu'
                            onChange={e => handleChange('state', e.target.value)}
                            options={states.map(state => {
                                const { abbreviation, name } = state

                                return {
                                    id: abbreviation,
                                    value: `${name} (${abbreviation})`
                                }
                            })}
                            placeholder={STRINGS.selectState}
                            value={state.state || 'hint'}
                        />
                    </div> */}

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.positionTitle}</div>

                        <SelectMenu
                            className='key-value-menu'
                            onChange={e => handleChange('positionTitle', e.target.value)}
                            options={_.sortBy(positionTitles, 'value')}
                            placeholder={`${STRINGS.select} ${STRINGS.positionTitle}`}
                            value={positionTitle || 'hint'}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.department}</div>

                        <SelectMenu
                            className='key-value-menu'
                            onChange={e => handleChange('kind', e.target.value)}
                            options={specialties.map(s => {
                                return {
                                    id: s.id,
                                    value: s.name
                                }
                            })}
                            placeholder={`${STRINGS.select} ${STRINGS.department}`}
                            value={kind || 'hint'}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.startDate}</div>

                        <DatePicker
                            defaultValue={startedOn}
                            onChange={date => handleChange('startedOn', date)}
                        />
                    </div>

                    <div className='key-value-frame'>
                        <div className='value-label capitalize'>{STRINGS.endDate}</div>

                        <div className={current ? 'disabled' : 'hide'}/>

                        <DatePicker
                            defaultValue={endedOn}
                            onChange={date => handleChange('endedOn', date)}
                        />

                        <div className='checkbox-frame'>
                            <Checkbox
                                checked={current}
                                onChange={checked => handleChange('current', checked)}
                            />

                            <div className='checkbox-label'>{STRINGS.iCurrentlyWorkHere}</div>
                        </div>
                    </div>

                    <div className='key-value-frame empty'></div>

                </div>
            </div>
        </div>
    )
}

export default EducationDocument