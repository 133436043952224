import React from 'react'

import _ from 'lodash'

import STRINGS from '../../../../../localization'

const PreliminaryBackgroundRelease = (props) => {
    const { state: { kind } } = props

    return (
        <div className='key-value-frame full'>
            <div className='form-frame'>
                <div className='form-name'>{STRINGS[_.camelCase(kind)]}</div>

                <div className='form-label bold'>References:</div>

                <div className='form-label line-break'>
                    I hereby authorize the company and its agents to make such investigations 
                    and inquiries into my employment/contract and educational history and other related 
                    matters as may be necessary in arriving at an employment/contract decision. 
                    I hereby release employers/contractors, schools, and other persons from all liability in 
                    responding to inquires connected with my preliminary background release, and I specifically 
                    authorize the release of information by any schools, businesses, individuals, services or 
                    other entities listed by me in this form. Furthermore, 
                    I authorize the company and its agents to release any reference information to clients who 
                    request such information for purposes of evaluating my credentials and qualifications.
                </div>

                <div className='form-label bold'>Temporary/Contract Position:</div>

                <div className='form-label line-break'>
                    If employed as a temporary employee, I understand that I may 
                    be an employee of the company and not of any client. If employed, I further understand 
                    that my position is not guaranteed for any specific time and may be terminated at any time 
                    for any reason. I further understand that a contract will exist between the company and each 
                    client to whom I may be assigned which will require the client to pay a fee to the company in 
                    the event that I accept direct employment/contract with the client. I agree to notify the company 
                    immediately should I be offered direct position by a client (or by referral of the client to any 
                    subsidiary or affiliated company), either for a permanent, temporary (including assignments through 
                    another agency), or consulting positions during my assignment or after my assignment has ended.
                </div>

                <div className='form-label line-break'>
                    I hereby authorize, by signing this document, this organization to obtain search 
                    information involving preliminary screening to include Office of Investigator General (OIG), 
                    System of Award Management (SAM), National Sex Offender, and Professional State Licensure Verification 
                    by the Company at any time after receipt of this authorization and throughout my pre-hire process, and 
                    I agree that a facsimile (“fax”), electronic or photographic copy of this Authorization shall be as valid 
                    as the original. I hereby agree and understand that a copy of the preliminary screening information is 
                    available at any time via a written request, to include a valid and active email address, submitted 
                    to an active Company representative and will be provided by the Company, per request, as an electronic 
                    copy of the requested document.
                </div>
            </div>

        </div>
    )
}

export default PreliminaryBackgroundRelease