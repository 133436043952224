import { gql } from 'apollo-boost'

const UserUpdateMutation = gql`
    mutation UserUpdateMutation($input: UserUpdateUpdateInput) {
        userUpdate(input: $input) {
            errors {
                message
            }
            result {
                id
                attachments(labels: ["avatar"]) {
                    id
                    label
                    url
                }
                bio
                email
                firstName
                homeStreet1
                homeStreet2
                homeCity
                homeState
                homeZip
                lastName
                phoneNumber
                profileCompletion
                specialties {
                    id
                    isPrimary
                    name
                    specialty {
                        id
                    }
                }
            }
            success
        }
    }
`

export default UserUpdateMutation