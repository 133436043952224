import React from 'react'

import moment from 'moment'

import SelectMenu from '../../Shared/ReferenceSelectMenu'

export default class FormDate extends React.Component {
    constructor(props) {
        super(props)

        let defaultValue = props.state[props.stateKey]

        this.state = {
            month: defaultValue ? moment(defaultValue).month() : null,
            year: defaultValue ? moment(defaultValue).year() : null,
        }
    }

    createYears = (min, max, reverse) => {
        let arr = []

        while (min <= max) {
            arr.push({ value: min, label: min })

            min++
        }

        return reverse ? arr.reverse() : arr
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        }, () => {
            const { handleChange, stateKey } = this.props
            const { month, year } = this.state

            if (month && year) {
                handleChange(stateKey, moment(`${month}/${year}`, 'MM/YYYY'))
            }
        })
    }

    render() {
        const { className, header } = this.props
        const { month, year } = this.state

        return (
            <div className={`form-input-frame${className ? ` ${className}` : ''}`}>
                <div className={`form-input-header ${(month && year) ? 'black' : 'red'}`}>{header}</div>

                <div className='form-date-frame'>
                    <SelectMenu
                        controlStyle={{
                            float: 'left',
                            width: '43%',
                            marginRight: '2%'
                        }}
                        options={[
                            { value: 1, label: 'Jan' },
                            { value: 2, label: 'Feb' },
                            { value: 3, label: 'Mar' },
                            { value: 4, label: 'Apr' },
                            { value: 5, label: 'May' },
                            { value: 6, label: 'Jun' },
                            { value: 7, label: 'Jul' },
                            { value: 8, label: 'Aug' },
                            { value: 9, label: 'Sept' },
                            { value: 10, label: 'Oct' },
                            { value: 11, label: 'Nov' },
                            { value: 12, label: 'Dec' }
                        ]}
                        onChange={e => this.handleChange('month', e.value)}
                        placeholder='Month'
                        value={month}
                    />

                    <SelectMenu
                        controlStyle={{
                            float: 'left',
                            width: '55%'
                        }}
                        defaultValue={year}
                        options={this.createYears(1950, new Date().getFullYear(), true)}
                        onChange={e => this.handleChange('year', e.value)}
                        placeholder='Year'
                        value={year}
                    />
                </div>
            </div>
        )
    }
}

// const FormDate = (props) => {
//     const { handleChange, header, hint, options, state, stateKey, width } = props

//     let value = state[stateKey]

//     return (
//         <div className='form-input-frame'>
//             <div className={`form-input-header ${value ? 'black' : 'red'}`}>{header}</div>

//             <div className='form-date-frame'>
//                 <SelectMenu
//                     controlStyle={{
//                         float: 'left',
//                         width: '43%',
//                         marginRight: '2%'
//                     }}
//                     options={[
//                         { value: 1, label: 'Jan' },
//                         { value: 2, label: 'Feb' },
//                         { value: 3, label: 'Mar' },
//                         { value: 4, label: 'Apr' },
//                         { value: 5, label: 'May' },
//                         { value: 6, label: 'Jun' },
//                         { value: 7, label: 'Jul' },
//                         { value: 8, label: 'Aug' },
//                         { value: 9, label: 'Sept' },
//                         { value: 10, label: 'Oct' },
//                         { value: 11, label: 'Nov' },
//                         { value: 12, label: 'Dec' }
//                     ]}
//                     onChange={e => handleChange(stateKey, e.value)}
//                     placeholder='Month'
//                     value={value}
//                 />

//                 <SelectMenu
//                     controlStyle={{
//                         float: 'left',
//                         width: '55%'
//                     }}
//                     options={createYears(1950, new Date().getFullYear(), true)}
//                     onChange={e => handleChange(stateKey, e.value)}
//                     placeholder='Year'
//                     value={value}
//                 />
//             </div>
//         </div>
//     )
// }

// function 

// export default FormDate