import React from "react";
import { Route, Switch } from "react-router-dom";
import { graphql } from "react-apollo";

import { flowRight as compose } from "lodash";
import Cookie from "../utils/js-cookie.overrides";
import jwt from "jsonwebtoken";
import moment from "moment";

import SessionQuery from "../graphql/queries";

import Account from "../containers/Account";
import AccountEdit from "../containers/Account/Edit";
import Checklist from "../containers/Checklist";
import ChecklistComplete from "../containers/Checklist/Complete";
import ChecklistStart from "../containers/Checklist/Start";
import ChecklistCategory from "../containers/Checklist/Category";
import ChecklistSection from "../containers/Checklist/Section";
import Course from "../containers/Course";
import CourseComplete from "../containers/Course/Complete";
import CourseChapters from "../containers/Course/Chapters";
import CourseChapter from "../containers/Course/Chapter";
import CourseStart from "../containers/Course/Start";
import Credentials from "../containers/Credentials";
import Documents from "../containers/Documents";
import ForgotPassword from "../containers/ForgotPassword";
import Login from "../containers/Login";
import NotFound from "../containers/NotFound";
import PasswordReset from "../containers/PasswordReset";
import Roles from "../containers/Roles";
import Specialty from "../containers/Specialty";
import Test from "../containers/Test";
import TestComplete from "../containers/Test/Complete";
import TestStart from "../containers/Test/Start";
import Reference from "../containers/Reference/";
import SubscribePage from "../components/Subscribe";
import ViewSubscriptionDetails from '../components/Subscribe/ViewSubscriptionDetails'
import LoadingPane from "../components/Shared/LoadingPane";
import PaymentCheckout from "./PaymentCheckout";
import TokenLogin from "./TokenLogin";

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      loading: true,
      onload: true,
      mobile: window.innerWidth < 1000,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      sessionQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    let route = window.location.pathname,
      allowed = ["/", "/getting_started", "/forgot_password", "/reference", "/token"];

    const isReference = route.indexOf("/reference") >= 0;
    const isToken = route.indexOf("/token") >= 0

    if (onload && !loading) {
      this.setState(
        {
          authenticated: user ? true : false,
        },
        () => {
          if (this.state.authenticated) {
            let decodedJwt = jwt.decode(
              Cookie.get(`${process.env.REACT_APP_COOKIE_NAME}-token`)
            ),
              expDate = moment(new Date(decodedJwt.exp * 1000));

            if (moment().isAfter(expDate)) {
              this.clearCookies();

              window.location.reload();
            } else if (route === "/") {
              window.location.href = "/roles";

              setTimeout(() => this.handleChange("loading", false), 250);
            } else {
              this.handleChange("loading", false);
            }
          } else if (!allowed.includes(route) && !isReference && !isToken) {
            this.clearCookies();
            this.goToBaseRoute();
          } else if (allowed.includes(route) && isToken) {
            this.handleChange("loading", false);
          }
          else {
            this.clearCookies();
            this.handleChange("loading", false);
          }

          this.handleChange("onload", false);
        }
      );
    }
  };

  clearCookies = () => {
    Cookie.remove(process.env.REACT_APP_COOKIE_NAME);
    Cookie.remove(`${process.env.REACT_APP_COOKIE_NAME}-token`);
    Cookie.remove("org");
  };

  goToBaseRoute = () => {
    window.location.href = "/";

    this.handleChange("loading", false);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleResize = () => {
    this.setState({ mobile: window.innerWidth < 1000 });
  };

  render() {
    const { authenticated, loading, onload, mobile } = this.state;

    return onload ? (
      <LoadingPane />
    ) : (
      <div>
        {loading ? <LoadingPane /> : null}

        {authenticated ? (
          <Switch>
            <Route exact path="/token/:token" component={TokenLogin} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/edit" component={AccountEdit} />
            <Route exact path="/subscribe" component={SubscribePage} />
            <Route exact path="/subscriptiondetails" component={ViewSubscriptionDetails} />

            <Route exact path="/subscribe/checkout" component={PaymentCheckout} />

            <Route exact path="/checklists/:checklist" component={Checklist} />
            <Route
              exact
              path="/checklists/:checklist/complete"
              component={ChecklistComplete}
            />
            <Route
              exact
              path="/checklists/:checklist/categories/:category"
              component={ChecklistCategory}
            />
            <Route
              exact
              path="/checklists/:checklist/categories/:category/sections/:section"
              component={ChecklistSection}
            />
            <Route
              exact
              path="/checklists/:checklist/start"
              component={ChecklistStart}
            />

            <Route exact path="/courses/:course" component={Course} />
            <Route
              exact
              path="/courses/:course/complete"
              component={CourseComplete}
            />
            <Route
              exact
              path="/courses/:course/start"
              component={CourseStart}
            />
            <Route
              exact
              path="/courses/:course/chapters"
              component={CourseChapters}
            />
            <Route
              exact
              path="/courses/:course/chapters/:chapter"
              component={CourseChapter}
            />

            <Route exact path="/credentials" component={Credentials} />

            <Route
              exact
              path="/organizations/:id/:tab"
              render={(props) => {
                const {
                  match: {
                    params: { tab },
                  },
                } = props;

                return tab === "account" ? (
                  <Account {...props} />
                ) : (
                  <Credentials {...props} />
                );
              }}
            />

            <Route
              exact
              path="/organizations/:id/account/edit"
              component={AccountEdit}
            />

            <Route
              exact
              path="/documents/:type/:document"
              render={(props) => (
                <Documents
                  {...props}
                  mobile={mobile}
                  user={this.props.sessionQuery.user}
                />
              )}
            />

            <Route exact path="/getting_started" component={PasswordReset} />

            <Route exact path="/specialties/:specialty" component={Specialty} />

            <Route exact path="/tests/:test" component={Test} />
            <Route
              exact
              path="/tests/:test/complete"
              component={TestComplete}
            />
            <Route exact path="/tests/:test/start" component={TestStart} />
            <Route exact path="/reference/:referenceId" component={Reference} />
            <Route exact path="/roles" component={Roles} />
            <Route exact path="/" component={Login} />
            <Route path="" component={NotFound} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={Login} />

            <Route exact path="/token/:token" component={TokenLogin} />
            <Route exact path="/credentials" component={Credentials} />
            <Route exact path="/reference/:referenceId" component={Reference} />
            <Route
              exact
              path="/getting_started"
              render={(props) => (
                <PasswordReset {...props} client2={this.props.client2} />
              )}
            />

            <Route
              exact
              path="/forgot_password"
              render={(props) => (
                <ForgotPassword {...props} client2={this.props.client2} />
              )}
            />
            <Route exact path="/roles" component={LoadingPane} />
            <Route path="" component={NotFound} />
          </Switch>
        )}
      </div>
    );
  }
}

export default compose(
  graphql(SessionQuery, {
    name: "sessionQuery",
    options: () => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
      },
    }),
  })
)(Main);
