import React from 'react'

import SelectMenu from '../../Shared/ReferenceSelectMenu'

const FormMenu = (props) => {
    const { className, handleChange, header, hint, options, state, stateKey } = props

    let value = state[stateKey]

    return (
        <div className={`form-input-frame${className ? ` ${className}` : ''}`}>
            <div className={`form-input-header ${value ? 'black' : 'red'}`}>{header}</div>

            <SelectMenu
                options={options}
                onChange={e => handleChange(stateKey, e.value)}
                placeholder={hint}
                value={value}
            />
        </div>
    )
}

export default FormMenu