import React from 'react'
import { Image, Document, Link, Page, Text, View } from '@react-pdf/renderer'

import moment from 'moment'

const AnnualEducationAttestationPdf = (state) => {
    const { issuedOn, signature } = state
    
    return (
        <Document>
            <Page size='A4' style={{ padding: 40 }}>
                {/* header */}
                <View style={{ textAlign: 'center', marginBottom: 15 }}>
                    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{`Annual Attestation of Core Competency Education & Orientation`}</Text>
                </View>

                {/* subheader */}
                <View style={{ margin: '15px 0px' }}>
                    <Text style={{ fontSize: 10 }}>
                        I acknowledge that I have received training on and always have access to an 
                        online updated copy of the Annual Education and Core Competency Education, which 
                        contains information and verification of procedures included but not limited
                        to the following:    
                    </Text>
                </View>

                {/* list view */}
                <View style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>{`Abuse & Neglect`}</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Age Specific Care</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Body Mechanics</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>COVID 19</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Cultural Diversity</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Emergency Preparedness</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Environmental Safety</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Ethics</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Fire Safety</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Hazard Communication</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>HIPAA</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>{`Infection Control & Bloodborne Pathogens`}</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>National Patient Safety Goals</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Pain Management</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Patient Rights</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Patient Safety Systems</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Sexual Harassment</Text>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2.5 }}>
                        <Text style={{ fontSize: 12 }}>{'\u2022'}</Text>
                        <Text style={{ fontSize: 10, marginLeft: 5 }}>Workplace Violence</Text>
                    </View>
                </View>

                <View style={{ marginBottom: 60 }}>
                    <Link 
                        src='https://drive.google.com/drive/u/0/folders/1PBRvMP9lpd9vdV4aRNIVikwabQD-W9qJ'
                        style={{ color: '#FF5A5F', fontSize: 10 }}
                    >View Modules</Link>
                </View>

                {/* signature */}
                <View style={{ flexDirection: 'column', marginBottom: 30, width: '33%' }}>
                    <Image
                        style={{ position: 'relative', top: 5 }}
                        src={{ uri: signature }}
                    />

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text style={{ fontSize: 10 }}>Signature</Text>
                    </View>
                </View>

                {/* date */}
                <View style={{ fontSize: 10, width: '33%' }}>
                    <Text>{moment(issuedOn).format('MMM D, YYYY')}</Text>

                    <View style={{ borderTop: '1pt solid black' }}>
                        <Text>Date</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default AnnualEducationAttestationPdf