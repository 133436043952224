import React from 'react'

import { orderBy } from 'lodash'

import LoadingPane from '../Shared/LoadingPane'

const ChecklistRunner = (props) => {
    const { handleAnswer, handleCategories, handleChange, handleSection, refs, state } = props
    const { answers, category, expand, loading, sIndex, qIndex, mobile } = state

    let section = orderBy(category.sections, 'rank')[sIndex],
        question = orderBy(section.questions, 'rank')[qIndex]
    
    return (
        <div className='checklist-runner-view full-screen-view'>
            {loading ? <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} /> : null}

            {mobile ?
                <div className='toolbar mobile'>
                    <div className='mobile-back-button' onClick={() => handleCategories(false)}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-x-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>

                    <div className='page-header-frame'>
                        <div className='page-header bold ellipsis'>{category.title}</div>
                        <div className='page-subheader ellipsis'>{`Skills Checklist - Section ${section.rank} of ${category.sections.length}`}</div>
                    </div>
                </div>
                :
                <div className='toolbar'>
                    <button className='back-button' onClick={() => handleCategories(false)}>Back</button>

                    <div className='page-header ellipsis'><b>{category.title}</b>{` - Section ${section.rank} of ${category.sections.length}`}</div>
                </div>
            }

            {mobile ?
                <div className='secondary-toolbar mobile' style={{ height: expand ? 36 + (question.scoreValues.length * 48) : 36 }}>
                    <div className='scoring-key-frame pointer' onClick={() => handleChange('expand', !expand)}>
                        <div className='scoring-key-label'>Scoring Key</div>

                        <div className={expand ? 'arrow-detail-icon up' : 'arrow-detail-icon'}>
                            <img src={require('../../theme/assets/arrow-detail-icon-2x.png')} alt='arrow-detail-icon' />
                        </div>
                    </div>

                    <ul className='score-keys-list-view'>
                        {question.scoreValues.map((scoreValue, i) => {
                            return (
                                <li className='score-key-cell' key={i}>
                                    <div className='score-key-label bold aqua'>{`(${scoreValue})`}</div>
                                    <div className='score-key-label'>{question.scoreLabels[i]}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                :
                <div className='secondary-toolbar'>
                    <ul className='score-keys-list-view'>
                        {(question.scoreValues || []).map((scoreValue, i) => {
                            return (
                                <li className='score-key-cell' key={i}>
                                    <div className='score-key-label bold aqua'>{`(${scoreValue})`}</div>
                                    <div className='score-key-label'>{question.scoreLabels[i]}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }

            <div className={mobile ? 'content-frame mobile' : 'content-frame'}>
                <div className='question-title-frame'>
                    <div className='question-title una' ref={ref => {
                        if (ref) {
                            let lineHeight = mobile ? 26 : 31.2,
                                numOfLines = Math.round(ref.clientHeight / lineHeight)

                            if (numOfLines === 1) ref.classList.add('center')
                            else ref.classList.remove('center')
                        }
                    }}>{section.title}</div>
                </div>

                <div className='answers-list-frame scroll' ref={ref => refs.list = ref}>
                    <ul className='answers-list-view'>
                        {orderBy(section.questions, 'rank').map((q, i) => {
                            const { id, title } = q

                            let answer = answers[id]

                            return (
                                <li className='answer-cell' key={id} ref={ref => refs[`node-${i}`] = ref}>
                                    <div className='answer-title'>{title}</div>

                                    <ul className='scoring-frame'>
                                        <li
                                            className={(answer && answer.value === "1") ? 'scoring-button selected' : 'scoring-button'}
                                            onClick={() => handleAnswer(id, "1", i + 1)}
                                        >1</li>

                                        <li
                                            className={(answer && answer.value === "2") ? 'scoring-button selected' : 'scoring-button'}
                                            onClick={() => handleAnswer(id, "2", i + 1)}
                                        >2</li>

                                        <li
                                            className={(answer && answer.value === "3") ? 'scoring-button selected' : 'scoring-button'}
                                            onClick={() => handleAnswer(id, "3", i + 1)}
                                        >3</li>

                                        <li
                                            className={(answer && answer.value === "4") ? 'scoring-button selected' : 'scoring-button'}
                                            onClick={() => handleAnswer(id, "4", i + 1)}
                                        >4</li>
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>

            <div className={mobile ? 'button-frame mobile' : 'button-frame'}>
                <button className='action-button bg-una' onClick={handleSection}>Next Section</button>
            </div>
        </div>
    )
}

export default ChecklistRunner