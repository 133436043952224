import { gql } from 'apollo-boost'

const CourseQuery = gql`
    query CourseQuery($id: ID, $course: ID) {
        user(id: $id) {
            id
            courseTaken(id: $course) {
                id
                answers {
                    id
                    chapter {
                        id
                        rank
                    }
                    question {
                        id
                        rank
                        title
                    }
                    isCorrect
                }
                endedAt
                passed
                score
                title
            }
        }
    }
`

export default CourseQuery