import React from 'react'

const LoadingPane = (props) => {
    return (
        <div className='loading-pane' style={props.style}>
            <div className='layout-center'>
                <div className='loader' />
            </div>
        </div>
    )
}

export default LoadingPane