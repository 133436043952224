import React from "react";
import SignatureCanvas from "react-signature-canvas";



import _ from "lodash";

import AnnualEducationAttestation from "./AnnualEducationAttestation";
import AnnualTbAttestation from "./AnnualTBAttestation";
import FluDeclination from "./FluDeclination";
import HepBDeclination from "./HepBDeclination";
import PreliminaryBackgroundRelease from "./PreliminaryBackgroundRelease";
import VaricellaDeclination from "./VaricellaDeclination";

import DatePicker from "../../../Shared/DatePicker";
import LoadingPane from "../../../Shared/LoadingPane";
import ModalPane from "../../../Shared/ModalPane";
import DocumentDisplay from "../../../Control/DocumentDisplay";
import STRINGS from "../../../../localization";

const forms = {
  annualEducationAttestation: AnnualEducationAttestation,
  annualTbAttestation: AnnualTbAttestation,
  fluDeclination: FluDeclination,
  hepBDeclination: HepBDeclination,
  preliminaryBackgroundRelease: PreliminaryBackgroundRelease,
  varicellaDeclination: VaricellaDeclination,
};

const Signature = (props) => {
  
  return props.state.edit ? <Edit {...props} /> : <Read {...props} />;
};

const Read = (props) => {
  const { back, documentRemove, handleChange, state } = props;
  const { loading, isModal, imageUrl, mobile,title,user } = state;

  return (
    <div className="document-read-view pdf" style={{ background: "#FAFAFA" }}>
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${_.startCase(
          state.kind
        )}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className="mobile-button delete bg-una"
              onClick={() => handleChange("isModal", true)}
              style={{ marginRight: 0 }}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/trash-can-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className="save-button bg-una capitalize"
              onClick={() => handleChange("isModal", true)}
              style={{ marginRight: 0 }}
            >
              {STRINGS.delete}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame scroll">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <DocumentDisplay
            docUrl={imageUrl}
            link={true}
            title={`${user.firstName}_${user.lastName}_${title}`}
          />
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.documentDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.cancel}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.delete}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange("isModal", false)}
      />
    </div>
  );
};

const Edit = (props) => {
  const { back, handleChange, match, refs, save, state } = props;
  const { kind, loading, issuedOn, mobile, signature } = state;

  // dynamic form data
  const Form = forms[_.camelCase(kind)];

  let existing = match.params.document !== "create",
    valid = issuedOn && signature;

  return (
    <div className="document-edit-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div
            className="mobile-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            <div className="mobile-button-icon">
              <img
                src={require("../../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button
            className="back-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${STRINGS.add} ${STRINGS.form}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className={`mobile-button save ${
                valid ? "bg-aqua" : "bg-disabled"
              }`}
              onClick={save}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../../theme/assets/buttons/button-checkmark-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className={`save-button ${valid ? "bg-aqua" : "bg-disabled"}`}
              onClick={save}
            >
              {STRINGS.save}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="pdf-form-frame" ref={(ref) => (refs.pdf = ref)}>
            <Form {...props} />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{STRINGS.dateReceived}</div>

            <DatePicker
              defaultValue={issuedOn}
              onChange={(date) => handleChange("issuedOn", date)}
            />
          </div>

          <div className="key-value-frame empty" />

          <div className="key-value-frame empty" />

          <div className="key-value-frame full">
            <div className="flex-frame">
              <div className="value-label capitalize">{`${STRINGS.employeeSignature} -`}</div>
              <div className="value-label italic hint">
                {STRINGS.employeeSignatureHint}
              </div>
            </div>

            <div className="signature-frame">
              <div
                className="signature-input-frame"
                ref={(ref) => (refs.signatureInput = ref)}
              >
                <SignatureCanvas
                  canvasProps={{
                    className: "signature-canvas",
                    width: refs.signatureInput
                      ? refs.signatureInput.getBoundingClientRect().width
                      : 0,
                    height: refs.signatureInput
                      ? refs.signatureInput.getBoundingClientRect().height
                      : 0,
                  }}
                  onEnd={() =>
                    handleChange(
                      "signature",
                      refs.signatureCanvas.getTrimmedCanvas().toDataURL()
                    )
                  }
                  ref={(ref) => (refs.signatureCanvas = ref)}
                />

                <div className="signature-border" />
              </div>

              <button
                className="clear-signature-button red bold"
                onClick={() => {
                  handleChange("signature", null);
                  refs.signatureCanvas.clear();
                }}
              >
                {STRINGS.clearSignature}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
