import { gql } from 'apollo-boost'

const DocumentCreateMutation = gql`
    mutation DocumentCreateMutation($input: DocumentCreateCreateInput) {
        documentCreate(input: $input) {
            errors {
                message
            }
            result {
                id
            }
            success
        }
    }
`

export default DocumentCreateMutation