import React from 'react'

const SelectMenu = (props) => {
    const { className, defaultValue, onChange, options, placeholder, value } = props

    let hint = { id: 'hint', value: placeholder }, 
        arr = placeholder ? [hint, ...options] : options

    return (
        <div className={`select-menu-frame ${className} ${(value === 'hint') ? 'bg-placeholder' : ''}`}>
            <select
                className='select-menu'
                defaultValue={defaultValue}
                onChange={onChange}
                value={value}
            >
                {arr.map(o => {
                    const { id, value } = o

                    return (
                        <option
                            key={id}
                            disabled={id === 'hint'}
                            value={id}
                        >
                            {value}
                        </option>
                    )
                })}
            </select>

            <div className='menu-icon'>
                <img src={require('../../theme/assets/menu-down-arrow-2x.png')} alt='menu-icon' />
            </div>
        </div>
    )
}

export default SelectMenu