import { gql } from 'apollo-boost'

const DocumentReferenceQuery = gql`
    query DocumentReferenceQuery($id: ID, $reference: ID) {
        user(id: $id) {
            id
            reference(id: $reference) {
                id
                eligibleForRehire
                email
                endDate
                firstName
                invitationNote
                lastName
                organization
                phone
                startDate
                status
                title
            }
        }
    }
`

export default DocumentReferenceQuery