import { gql } from 'apollo-boost'

const ChecklistStartQuery = gql`
    query ChecklistStartQuery($id: ID, $checklist: ID) {
        user(id: $id) {
            id
            checklistTaken(id: $checklist) {
                id
                answers {
                    id
                    question {
                        id
                        rank
                        scoreValues
                        title
                    }
                    value
                }
                checklist {
                    id
                    categories {
                        id
                        scoreLabels
                        scoreValues
                        sections {
                            id
                            questions {
                                id
                                rank
                                scoreLabels
                                scoreValues
                                title
                            }
                            rank
                            title
                        }
                        rank
                        title
                    }
                    title
                }
                endedAt
                status
                title
            }
        }
    }
`

export default ChecklistStartQuery