import React from 'react'

import _ from 'lodash'

import Award from '../../../../components/Documents/Document/Award'

export default class AwardContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.state,
            edit: props.match.params.document === 'create',
            isModal: false,
            loading: false,user:props.user
        }
    }

    componentDidUpdate() {
        if (!_.isEqual(this.props.mobile, this.state.mobile)) {
            this.handleChange('mobile', this.props.state.mobile)
        }
    }

    documentRemove = (hide) => {
        this.setState({
            loading: true
        }, () => this.props.documentRemove(hide))
    }

    handleChange = (key, value) => {
        this.setState(_.set(this.state, key, value))
    }

    save = () => {
        const { save, saveAttachment } = this.props
        const { documentNumber, kind, image, imageBack, issuingState, issuedOn } = this.state

        let data = {
            documentNumber,
            kind,
            issuedOn,
            issuingState,
            title: 'Award'
        }

        if (documentNumber && issuingState && issuedOn) {
            this.setState({
                loading: true
            }, async () => {
                if (image) data.image = await saveAttachment(image)

                if (imageBack) data.imageBack = await saveAttachment(imageBack)
                
                save(data)
            })
        }
    }

    render() {
        return (
            <Award
                {...this.props}
                documentRemove={this.documentRemove}
                handleChange={this.handleChange}
                save={this.save}
                state={this.state}
            />
        )
    }
}