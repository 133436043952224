import { gql } from 'apollo-boost'

const RolesQuery = gql`
    query RolesQuery($id: ID) {
        user(id: $id) {
            id
            roles {
                id
                role
                org {
                    id
                    attachment(label: "pdf_logo") {
                        id
                        url
                    }
                    brandColor
                    title
                }
            }
        }
    }
`

export default RolesQuery