import React from 'react'
import { graphql } from 'react-apollo'

import { flowRight as compose } from 'lodash'
import Cookie from 'js-cookie'

import TestCompleteQuery from '../../graphql/queries/Test/Complete'

import TestComplete from '../../components/Test/Complete'

import LoadingPane from '../../components/Shared/LoadingPane'

class TestCompleteContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            onload: true
        }
    }

    componentDidMount() {
        this.setup()

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate() {
        this.setup()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    setup = () => {
        const { testCompleteQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            this.setState({
                ...user.testTaken,
                onload: false
            })
        }
    }

    goToCredentialsRoute = () => {
        const { history } = this.props

        let searchParams = new URLSearchParams(history.location.search),
            org = Cookie.get('org'),
            url = (org ? `/organizations/${org}/credentials` : '/credentials'),
            bundle = searchParams.get('bundle')

        if (org && bundle) {
            history.push(`/organizations/${org}/bundles/${bundle}`)
        } else {
            history.push(url)
        }
    }

    goToResultsRoute = () => {
        const { history, match } = this.props

        history.push(`/tests/${match.params.test}`)
    }

    goToRoute = (route) => {
        this.props.history.push(route)
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleResize = () => {
        this.handleChange('mobile', window.innerWidth < 1000)
    }

    render() {
        const { onload } = this.state

        return onload ?
            <LoadingPane />
            :
            <TestComplete
                goToCredentialsRoute={this.goToCredentialsRoute}
                goToResultsRoute={this.goToResultsRoute}
                goToRoute={this.goToRoute}
                state={this.state}
            />
    }
}

export default compose(
    graphql(TestCompleteQuery, {
        name: 'testCompleteQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
                test: props.match.params.test
            }
        })
    })
)(TestCompleteContainer)