import { gql } from 'apollo-boost'

const UserCourseFinishMutation = gql`
    mutation UserCourseFinishMutation($input: IDInput) {
        userCourseFinish(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default UserCourseFinishMutation