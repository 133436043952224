import { gql } from 'apollo-boost'

const UserTestShareExistingResultMutation = gql`
    mutation UserTestLinkReferenceMutation($input: TestUserTestLinkReferenceInput) {
        userTestLinkReference(input: $input) {
            errors {
                message
            }
            result
            success
        }
    }
`

export default UserTestShareExistingResultMutation