import React from 'react'

class ProgressBar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            color: this.setupColor(props.pct)
        }
    }

    componentDidMount() {
        setTimeout(() => {
            let pct = this.props.pct,
                i = 0

            while (i < 4) {
                if (pct > 25) {
                    this.handleChange(i, 100)
                } else {
                    this.handleChange(i, Math.round((pct / 25) * 100))
                }

                pct -= 25

                i++
            }
        }, 100)
    }

    setupColor = (score) => {
        if (score > 50 && score <= 80) {
            return 'blue'
        } else if (score > 80) {
            return 'green'
        } else if (score < 20) {
            return 'una'
        } else {
            return 'orange'
        }
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    render() {
        const { mobile } = this.props

        return (
            <div className={mobile ? 'progress-bar-view mobile' : 'progress-bar-view'}>
                <div className='progress-block'>
                    <div className={`fill first ${this.state.color}`} style={{ width: `${this.state[0]}%` }} />
                </div>

                <div className='progress-block'>
                    <div className={`fill second ${this.state.color}`} style={{ width: `${this.state[1]}%` }} />
                </div>

                <div className='progress-block'>
                    <div className={`fill third ${this.state.color}`} style={{ width: `${this.state[2]}%` }} />
                </div>

                <div className='progress-block'>
                    <div className={`fill fourth ${this.state.color}`} style={{ width: `${this.state[3]}%` }} />
                </div>
            </div>
        )
    }
}

export default ProgressBar