import React from 'react'

import _ from 'lodash'

import ProgressBar from '../Shared/ProgressBar'

const ChecklistCategory = (props) => {
    const { goToChecklistRoute, goToCategorySectionRoute, match, state } = props
    const { checklist, mobile } = state

    let category = checklist.categories.find(c => c.id === match.params.category)

    return (
        <div className='checklist-view full-screen-view'>
            {mobile ?
                <div className='toolbar mobile'>
                    <div className='mobile-back-button' onClick={goToChecklistRoute}>
                        <div className='mobile-button-icon'>
                            <img src={require('../../theme/assets/buttons/button-back-arrow-icon-2x.png')} alt='button-x-icon' />
                        </div>
                    </div>

                    <div className='page-header ellipsis'>{category.title}</div>
                </div>
                :
                <div className='toolbar'>
                    <button className='back-button' onClick={goToChecklistRoute}>Back</button>

                    <div className='page-header ellipsis'>{category.title}</div>
                </div>
            }

            <div className='content-frame scroll'>
                <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
                    <div className='section-frame'>
                        <div className='section-header'>overall score</div>

                        <div className='score-frame'>
                            <div className='score-label'>{category.score.toFixed(1)}</div>
                            {/* <div className='score-label'>{`${score.toFixed(1)} -`}</div> */}
                            {/* {scoreColor((rawScore / rawTotal) * 100)} */}
                        </div>
                    </div>

                    <div className='section-frame categories'>
                        <div className='section-header'>section breakdown</div>

                        <ul className='categories-list-view'>
                            {_.orderBy(category.sections, ['rank']).map(section => {
                                const { id, pct, score, title } = section

                                return (
                                    <li className='category-cell pointer' key={id} onClick={() => goToCategorySectionRoute(id)}>
                                        <div className={mobile ? 'category-label bold' : 'category-label ellipsis bold'}>{title}</div>

                                        <div className='category-score-frame'>
                                            <div className='category-label'>{score.toFixed(1)}</div>

                                            <ProgressBar mobile={mobile} pct={pct} />

                                            <div className='arrow-detail-icon'>
                                                <img src={require('../../theme/assets/arrow-detail-icon-2x.png')} alt='arrow-detail-icon' />
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

// function scoreColor(score) {
//     let color, label

//     if (score > 50 && score <= 80) {
//         color = '#24A4DB'
//         label = 'Good'
//     } else if (score > 80) {
//         color = '#4DD865'
//         label = 'Excellent'
//     } else if (score < 20) {
//         color = '#FF5A5F'
//         label = 'Poor'
//     } else {
//         color = '#EB903D'
//         label = 'Fair'
//     }

//     return <div className='score-label' style={{ color, marginLeft: 5 }}>{label}</div>
// }

export default ChecklistCategory