import React from 'react'
import { graphql } from 'react-apollo'

import { every, flowRight as compose, orderBy } from 'lodash'
import Cookie from 'js-cookie'
import smoothscroll from 'smoothscroll-polyfill';

import UserQuery from '../../graphql/queries/Checklist/Start'
import UserChecklistStartMutation from '../../graphql/mutations/User/Checklist/Start'
import UserChecklistFinishMutation from '../../graphql/mutations/User/Checklist/Finish'
import ChecklistAnswerCreateMutation from '../../graphql/mutations/Checklist/Answer/Create'
import ChecklistAnswerUpdateMutation from '../../graphql/mutations/Checklist/Answer/Update'

import ChecklistRunner from '../../components/Checklist/Runner'
import ChecklistStart from '../../components/Checklist/Start'

import LoadingPane from '../../components/Shared/LoadingPane'

var timer

const refs = {}

class ChecklistStartContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isComplete: false,
            expand: false,
            mobile: window.innerWidth < 1000,
            onload: true,
            secs: 0
        }
    }

    componentDidMount() {
        const { userQuery: { loading, refetch } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            refetch().then(this.setup())
        } else {
            this.setup()
        }

        // safari scroll to view animation polyfill
        smoothscroll.polyfill();

        timer = setInterval(() => this.handleChange('secs', this.state.secs + 1), 1000)
        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate() {
        this.setup()
    }


    componentWillUnmount() {
        clearInterval(timer)
        window.removeEventListener('resize', this.handleResize)
    }

    setup = () => {
        const { match, userQuery: { loading, user } } = this.props
        const { onload } = this.state

        if (onload && !loading) {
            let data = user.checklistTaken

            if (data.status === 'finished') {
                this.goToRoute(`/checklists/${match.params.checklist}/complete`)
            } else if (data.status === 'untaken') {
                this.startChecklist(user)
            } else {
                this.setupChecklist(user)
            }
        }
    }

    setupChecklist = (user) => {
        const { answers, checklist } = user.checklistTaken

        let answerHash = {},
            complete = [],
            incomplete = []

        answers.forEach(answer => {
            const { id, question, value } = answer

            answerHash[question.id] = { id, value }
        })

        checklist.categories.forEach(c => {
            let questions = c.sections.map(s => s.questions).flat()

            if (every(questions.map(q => answerHash[q.id]))) {
                complete.push(c)
            } else {
                incomplete.push(c)
            }
        })

        this.setState({
            ...user.checklistTaken,
            answers: answerHash,
            complete,
            incomplete,
            onload: false
        }, () => {
            if (incomplete.length === 0) this.handleCategories(true)
        })
    }

    goToCredentialsRoute = () => {
        const { history } = this.props

        let org = Cookie.get('org'),
            url = (org ? `/organizations/${org}/credentials` : '/credentials')

        history.push(url)
    }

    goToRoute = (route) => {
        this.props.history.push(route)
    }

    handleAnswers = (arr, success) => {
        const { checklistAnswerCreateMutation, checklistAnswerUpdateMutation } = this.props
        const { answers, id } = this.state

        let questionId = arr[0],
            answer = answers[questionId]

        if (arr.length === 0) {
            this.setState({
                answers,
                loading: false
            })

            if (success) success()
        } else if (answer.dirty) {
            let mutation = (answer && answer.id) ? checklistAnswerUpdateMutation : checklistAnswerCreateMutation,
                data = (answer && answer.id) ?
                    {
                        id: answer.id,
                        value: answer.value
                    }
                    :
                    {
                        userChecklistId: id,
                        questionId,
                        value: parseInt(answer.value),
                        secs: answer.secs
                    }
            
            mutation({
                variables: {
                    input: data
                }
            }).then(res => {
                let key = Object.keys(res.data)[0],
                    response = res.data[key]

                if (response.success) {
                    answers[questionId] = response.result

                    this.handleAnswers(arr.slice(1, arr.length), success)
                } else {
                    this.handleChange('loading', false)
                    
                    window.alert(response.errors[0].message)
                }
            })
        } else {
            this.handleAnswers(arr.slice(1, arr.length), success)
        }
    }

    handleAnswer = (questionId, value, nodeIndex) => {
        const { answers, mobile, secs } = this.state
        const elem = refs[`node-${nodeIndex}`]

        answers[questionId] = {
            ...answers[questionId],
            dirty: true,
            secs,
            value
        }

        // scroll to next node
        if (elem) {
            let scrollOffset = 0

            for (var i = 0; i < nodeIndex; i++) {
                scrollOffset += (refs[`node-${i}`].getBoundingClientRect().height + (mobile ? 12 : 30))
            }
            
            refs.list.scroll({ top: scrollOffset, behavior: 'smooth' })
        }

        this.setState({
            answers,
            secs: 0
        })
    }

    handleCategories = (finish) => {
        const { match, history, userChecklistFinishMutation } = this.props
        const { answers, id, incomplete, complete } = this.state

        let arr = [...complete, ...incomplete],
            completed = [],
            incompleted = [],
            searchParams = new URLSearchParams(history.location.search)

        arr.forEach(c => {
            let questions = c.sections.map(s => s.questions).flat()

            if (every(questions.map(q => answers[q.id]))) {
                completed.push(c)
            } else {
                incompleted.push(c)
            }
        })

        if (incompleted.length === 0 && finish) {
            userChecklistFinishMutation({
                variables: {
                    input: {
                        id
                    }
                }
            }).then(response => {
                const { data: { userChecklistFinish: { errors, success } } } = response

                if (success) {
                    this.goToRoute(`/checklists/${match.params.checklist}/complete${searchParams.get('bundle') ? `?bundle=${searchParams.get('bundle')}` : ''}`)
                } else {
                    window.alert(errors[0].message)
                }
            })
        } else {
            this.setState({
                category: null,
                complete: orderBy(completed, 'rank'),
                incomplete: orderBy(incompleted, 'rank')
            })
        }
    }

    handleCategory = (category) => {
        this.setState({
            category,
            sIndex: 0,
            qIndex: 0
        })
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleResize = () => {
        this.handleChange('mobile', window.innerWidth < 1000)
    }

    handleSection = () => {
        const { answers, category, sIndex } = this.state

        let nextIndex = (sIndex + 1)

        this.setState({
            loading: true
        }, () => this.handleAnswers(Object.keys(answers), () => {
            if (category.sections[nextIndex]) {
                refs.list.scroll({ top: 0, behavior: 'smooth' })

                setTimeout(() => this.setState({
                    loading: false,
                    sIndex: this.state.sIndex + 1
                }), 300)

            } else {
                this.handleCategories(true)
            }
        }))
    }

    startChecklist = (user) => {
        const { userChecklistStartMutation } = this.props

        userChecklistStartMutation({
            variables: {
                input: {
                    id: user.checklistTaken.id
                }
            }
        }).then(response => {
            const { data: { userChecklistStart: { errors, success } } } = response

            if (success) {
                this.setupChecklist(user)
            } else {
                window.alert(errors[0].message)
            }
        })
    }

    render() {
        const { category, onload } = this.state

        return onload ?
            <LoadingPane />
            :
            category ?
                <ChecklistRunner
                    handleAnswer={this.handleAnswer}
                    handleCategories={this.handleCategories}
                    handleChange={this.handleChange}
                    handleSection={this.handleSection}
                    refs={refs}
                    state={this.state}
                />
                :
                <ChecklistStart
                    goToCredentialsRoute={this.goToCredentialsRoute}
                    handleCategory={this.handleCategory}
                    handleChange={this.handleChange}
                    state={this.state}
                />
    }
}

export default compose(
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
                checklist: props.match.params.checklist
            }
        })
    }),
    graphql(ChecklistAnswerCreateMutation, { name: 'checklistAnswerCreateMutation' }),
    graphql(ChecklistAnswerUpdateMutation, { name: 'checklistAnswerUpdateMutation' }),
    graphql(UserChecklistStartMutation, { name: 'userChecklistStartMutation' }),
    graphql(UserChecklistFinishMutation, { name: 'userChecklistFinishMutation' })
)(ChecklistStartContainer)