import React from 'react'

import moment from 'moment'

import SelectMenu from '../Shared/SelectMenu'

export default class DatePicker extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.setupDate(true)
        }
    }

    componentDidUpdate(prevProps) {
        const { defaultValue } = this.props
        
        if (prevProps.defaultValue !== defaultValue) {
            this.setupDate()
        }
    }

    setupDate = (onload) => {
        const { defaultValue } = this.props

        let date = moment(defaultValue, 'YYYY-MM-DD'),
            data = {
                day: defaultValue ? date.date() : 'hint',
                days: this.renderOptions(1, 31),
                month: defaultValue ? (date.month() + 1) : 'hint',
                year: defaultValue ? date.year() : 'hint'
            }

        if (onload) {
            return data
        } else {
            this.setState({ ...data })
        }
    }

    checkDate = () => {
        const { day, month, year } = this.state

        if (day !== 'hint' && month !== 'hint' && year !== 'hint') {
            this.props.onChange(`${year}-${(month > 9) ? month : `0${month}`}-${(day > 9) ? day : `0${day}`}`)
        }
    }

    handleChange = (key,value) => {
        this.setState({
            [key]: value
        }, () => this.checkDate())
    }

    handleMonth = (month) => {
        const { day, year } = this.state
    
        let numOfDays = moment(`${(year === 'hint') ? moment().year() : year}-${month}`, 'YYYY-MM').daysInMonth()
        
        this.setState({
            days: this.renderOptions(1, numOfDays),
            day: (parseInt(day) > numOfDays) ? 'hint' : day,
            month
        }, () => this.checkDate())
    }

    handleYear = (year) => {
        const { day, month } = this.state

        let numOfDays = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

        this.setState({
            days: this.renderOptions(1, numOfDays),
            day: (parseInt(day) > numOfDays) ? 'hint' : day,
            year
        }, () => this.checkDate())
    }

    renderOptions = (min, max, reverse) => {
        let arr = []

        while (min <= max) {
            arr.push({ id: min, value: min })

            min++
        }

        return reverse ? arr.reverse() : arr
    }

    render() {
        const { className, future, futureAmt } = this.props
        const { days, day, month, year } = this.state

        return (
            <div className={`date-picker${className ? ` ${className}` : ''}`}>
                <SelectMenu
                    className={`months-menu ${(month === 'hint') ? 'bg-placeholder' : ''}`}
                    onChange={e => this.handleMonth(e.target.value)}
                    options={moment.monthsShort().map((month, i) => ({ id: (i + 1), value: month }))}
                    placeholder='MM'
                    value={month}
                />

                <SelectMenu
                    className={`days-menu middle ${(day === 'hint') ? 'bg-placeholder' : ''}`}
                    onChange={e => this.handleChange('day', e.target.value)}
                    options={days}
                    placeholder='DD'
                    value={day}
                />

                <SelectMenu
                    className={`years-menu ${(year === 'hint') ? 'bg-placeholder' : ''}`}
                    onChange={e => this.handleYear(e.target.value)}
                    options={this.renderOptions(1920, moment().add((future ? futureAmt : 0), 'years').year(), true)}
                    placeholder='YYYY'
                    value={year}
                />
            </div>
        )
    }
}