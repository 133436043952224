import React from "react";
import Benefits from "./Benefits";
import PriceCard from "./PriceCard";
import "./Subscribe.scss";
import Navigation from "../Credentials/Navigation";
import SubscriptionsList from "../../graphql/queries/Subscriptions";
import { useQuery } from "react-apollo";

import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import PaymentIntentCreateMutation from "../../graphql/mutations/Subscription/PaymentIntent";
import { useHistory } from "react-router-dom";

const ViewSubscriptionDetails = (props) => {
  const { state } = props.location;

  const mobile = state && state.mobile;

  const history = useHistory();

  const { data } = useQuery(SubscriptionsList, {
    variables: { status: ["active"] },
  });

  let subscription = null;
  if (data && data.subscriptions && data.subscriptions.edges.length > 0) {
    subscription = data.subscriptions.edges[0].node;
  }

  return (
    <div className="account-view full-screen-view">
      <Navigation {...props} subscription={subscription} />

      <div
        className={`content-frame scroll ${mobile ? "mobile" : ""}`}
        style={{ top: mobile ? 48 : 60 }}
      >
        <div className="BlockCenter">
          <div className="pricePage">
            <Benefits  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  graphql(PaymentIntentCreateMutation, { name: "paymentIntentCreateMutation" })
)(ViewSubscriptionDetails);
