import { PaymentElement } from "@stripe/react-stripe-js";
import React from "react";

const CheckoutForm = (props) => {
  const { handleSubmit, elements, message, paymentElementOptions, stripe, isLoading } = props

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="submit-btn">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

export default CheckoutForm