import React from "react";
import { graphql } from "react-apollo";

import { flowRight as compose } from "lodash";
import Cookie from "../../utils/js-cookie.overrides";

import UserQuery from "../../graphql/queries/Account";

import AttachmentCreateMutation from "../../graphql/mutations/Attachment/Create";
import AttachmentUpdateMutation from "../../graphql/mutations/Attachment/Update";
import UserSpecialtyCreateMutation from "../../graphql/mutations/User/Specialty/Create";
import UserSpecialtyUpdateMutation from "../../graphql/mutations/User/Specialty/Update";
import UserUpdateMutation from "../../graphql/mutations/User/Update";

import AccountEdit from "../../components/Account/Edit";

import LoadingPane from "../../components/Shared/LoadingPane";

class AccountEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: window.innerWidth < 1000,
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();

    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setup = () => {
    const {
      userQuery: { loading, specialties, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...user,
        onload: false,
        profilePicture: user.attachments[0],
        phoneNumber: this.formatPhoneNumber(user.phoneNumber),
        specialtyOptions: specialties,
      });
    }
  };

  formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, ""),
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  };

  goToAccountRoute = () => {
    const { history, match } = this.props;

    history.push(
      match.params.id ? `/organizations/${match.params.id}/account` : "/account"
    );
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleResize = () => {
    this.handleChange("mobile", window.innerWidth < 1000);
  };

  resetResults = (result) => {
    this.setState(
      {
        ...result,
      },
      this.goToAccountRoute
    );
  };

  save = () => {
    const { userUpdateMutation } = this.props;
    const {
      bio,
      email,
      file,
      firstName,
      homeStreet1,
      homeStreet2,
      homeCity,
      homeState,
      homeZip,
      id,
      lastName,
      phoneNumber,
      specialty,
    } = this.state;

    // eslint-disable-next-line
    const emailRegex = new RegExp(
      /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    );

    // eslint-disable-next-line
    const phoneRegex =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

    if (!emailRegex.test(email)) window.alert("Email is not valid.");
    else if (!phoneRegex.test(phoneNumber))
      window.alert("Phone number is not valid.");
    else {
      this.setState(
        {
          loading: true,
        },
        () => {
          userUpdateMutation({
            variables: {
              input: {
                id,
                bio,
                email,
                firstName,
                lastName,
                homeStreet1,
                homeStreet2,
                homeCity,
                homeState,
                homeZip,
                phoneNumber,
              },
            },
          }).then((response) => {
            const {
              data: {
                userUpdate: { errors, result },
              },
            } = response;

            if (errors) {
              window.alert(errors[0].message);
              this.setState({
                loading: false,
              });
            } else if (file) {
              this.saveAttachment(result);
            } else if (specialty) {
              this.saveSpecialty(result);
            } else {
              this.resetResults(result);
            }
          });
        }
      );
    }
  };

  saveAttachment = (result) => {
    const { attachmentCreateMutation, attachmentUpdateMutation } = this.props;
    const { attachments, file, id, specialty } = this.state;

    let that = this,
      xhr = new XMLHttpRequest(),
      fileName = file.name,
      config = {
        type: `image/${fileName.split(".")[1]}`,
      },
      f = new File([file.base64], fileName, config),
      formData = new FormData();

    formData.append("file", f);

    xhr.open("POST", process.env.REACT_APP_ATTACHMENT_URL);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        let response = JSON.parse(xhr.response),
          profileImage = attachments.find((a) => a.label === "avatar"),
          mutation = profileImage
            ? attachmentUpdateMutation
            : attachmentCreateMutation,
          data = profileImage
            ? {
                id: profileImage.id,
                attachment: response,
                label: "avatar",
              }
            : {
                attachment: response,
                userId: id,
                label: "avatar",
              };

        mutation({
          variables: {
            input: data,
          },
        }).then((res) => {
          let key = Object.keys(res.data)[0],
            response = res.data[key];

          if (response.success) {
            if (specialty) {
              that.saveSpecialty(result);
            } else {
              that.resetResults(result);
            }
          } else {
            window.alert(response.errors[0].message);
            this.setState({
              loading: false,
            });
          }
        });
      }
    };

    xhr.send(formData);
  };

  saveSpecialty = (result) => {
    const { userSpecialtyCreateMutation, userSpecialtyUpdateMutation } =
      this.props;
    const { id, specialties, specialty } = this.state;

    let primarySpecialty = specialties.find((s) => s.isPrimary),
      mutation = primarySpecialty
        ? userSpecialtyUpdateMutation
        : userSpecialtyCreateMutation,
      data = primarySpecialty
        ? { id: primarySpecialty.id, specialtyId: specialty }
        : {
            userId: id,
            specialtyId: specialty,
            startedOn: new Date().toISOString(),
            isPrimary: true,
          };

    mutation({
      variables: {
        input: data,
      },
    }).then((res) => {
      let key = Object.keys(res.data),
        response = res.data[key];

      if (response.success) {
        this.resetResults(result);
      } else {
        window.alert(response.errors[0].message);
        this.setState({
          loading: false,
        });
      }
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <AccountEdit
        goToAccountRoute={this.goToAccountRoute}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: "userQuery",
    options: () => ({
      variables: {
        id: Cookie.get(process.env.REACT_APP_COOKIE_NAME),
      },
    }),
  }),
  graphql(AttachmentCreateMutation, { name: "attachmentCreateMutation" }),
  graphql(AttachmentUpdateMutation, { name: "attachmentUpdateMutation" }),
  graphql(UserUpdateMutation, { name: "userUpdateMutation" }),
  graphql(UserSpecialtyCreateMutation, { name: "userSpecialtyCreateMutation" }),
  graphql(UserSpecialtyUpdateMutation, { name: "userSpecialtyUpdateMutation" })
)(AccountEditContainer);
