import React from "react";

import _ from "lodash";
import moment from "moment";

import LetterOfRecommendation from "../../../../components/Documents/Document/LetterOfRecommendation";

export default class LetterOfRecommendationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.match.params.document === "create",
      isModal: false,
      loading: false,
      user: props.user,
    };
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange("mobile", this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide)
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { save, saveAttachment } = this.props;
    const { kind, image, imageUrl, imageBack, imageBackUrl } = this.state;

    let image1 = image || imageUrl,
      image2 = imageBack || imageBackUrl,
      data = {
        documentNumber: image1 && image2 ? "2 photos" : "1 photo",
        kind,
        issuedOn: moment().format("YYYY-MM-DD"),
        issuingState: "n/a",
        title: "Letter of Recommendation",
      };

    if (image || imageBack) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          if (imageBack) data.imageBack = await saveAttachment(imageBack);

          save(data);
        }
      );
    }
  };

  render() {
    return (
      <LetterOfRecommendation
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}
