import { gql } from 'apollo-boost'

const TestAnswerUpdateMutation = gql`
    mutation TestAnswerUpdateMutation($input: TestAnswerUpdateUpdateInput) {
        testAnswerUpdate(input: $input) {
            errors {
                message
            }
            success
        }
    }
`

export default TestAnswerUpdateMutation