import { cloneDeep } from "lodash";

const ChecklistScore = {
  calculateAvgChecklistScore: (checklist, answers) => {
    let totalQuestionScore = 0;
    let totalMaxScore = 0;
    let questionCount = 0;

    const checklistClone = cloneDeep(checklist);
    checklistClone.answers = answers;
    checklistClone.categories.forEach((c) => {
      const values = c.sections
        .map((s) => s.questions.map((q) => parseInt(answers[q.id], 10) || 0))
        .flat();
      const score =
        values.length > 0 ? values.reduce((v, total) => (total += v)) : 0;
      const maxValues = c.sections
        .map((s) => s.questions.map((q) => Math.max(...q.scoreValues)))
        .flat();
      const maxScore =
        maxValues.length > 0 ? maxValues.reduce((v, total) => (total += v)) : 0;

      //---- CATEGORY ----//
      const categoryAvgScore =
        parseFloat((score / values.length).toFixed(1)) || 0;
      c.score = categoryAvgScore;
      c.pct = (score / maxScore) * 100;

      //---- SECTION ----//
      c.sections.forEach((s) => {
        const values = s.questions
          .map((q) => parseInt(answers[q.id], 10) || 0)
          .flat();
        const score = values.reduce((v, total) => (total += v));
        const maxValues = s.questions
          .map((q) => Math.max(...q.scoreValues))
          .flat();
        const maxScore = maxValues.reduce((v, total) => (total += v));

        const avgSectionScore = parseFloat((score / values.length).toFixed(1));
        s.score = avgSectionScore;
        s.pct = (score / maxScore) * 100;

        //---- QUESTION ----//
        s.questions.forEach((q) => {
          q.value = parseInt(answers[q.id], 10) || 0;
        });
        totalQuestionScore += score;
        totalMaxScore += maxScore;
        questionCount += values.length;
        
      });
    });
    checklistClone.avgQuestionScore = parseFloat(
      (totalQuestionScore / questionCount).toFixed(1),
    );
    checklistClone.questionPct = (totalQuestionScore / totalMaxScore) * 100;

    return checklistClone;
  }
}

export default ChecklistScore;