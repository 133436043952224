import React from 'react'

import Certification from './Certification'
import Document from './Document'
import Education from './Education'
import License from './License'
import Reference from './Reference'
import WorkExperience from './WorkExperience'

// import { documents } from '../../utils/Constants'

class DocumentsContainer extends React.Component {
    goToCredentialsRoute = () => {
        this.props.history.push('/credentials')
    }

    goToNotFoundRoute = () => {
        this.props.history.push('/error')
    }

    render() {
        const { match: { params: { type } } } = this.props

        if (type === 'certifications') {
            return <Certification {...this.props} />
        } else if (type === 'education') {
            return <Education {...this.props} />
        } else if (type === 'licenses') {
            return <License {...this.props} />
        } else if (type === 'references') {
            return <Reference {...this.props} />
        } else if (type === 'work_experiences') {
            return <WorkExperience {...this.props} />
        } else {
            return <Document 
                {...this.props} 
                goToCredentialsRoute={this.goToCredentialsRoute} 
            />
        }
    }
}

export default DocumentsContainer