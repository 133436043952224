import React from "react";

import moment from "moment";

import AutoComplete from "../../Shared/AutoComplete";
import DatePicker from "../../Shared/DatePicker";
import LoadingPane from "../../Shared/LoadingPane";
import ModalPane from "../../Shared/ModalPane";
import SelectMenu from "../../Shared/SelectMenu";
import DocumentDisplay from "../../Control/DocumentDisplay";

import STRINGS from "../../../localization";

const EducationDocument = (props) => {
  return props.state.edit ? <Edit {...props} /> : <Read {...props} />;
};

const Read = (props) => {
  const { back, documentRemove, handleChange, state } = props;
  const { imageUrl, isModal, issuerName, issuedOn, loading, mobile, title,user } =
    state;

  let degree = {
    associate: STRINGS.associateDegree,
    bachelor: STRINGS.bachelorDegree,
    doctor: STRINGS.doctoralDegree,
    master: STRINGS.masterDegree,
  };

  return (
    <div className="document-read-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require("../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${STRINGS.education}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className="mobile-button delete bg-una"
              onClick={() => handleChange("isModal", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../theme/assets/buttons/trash-can-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>

            <div
              className="mobile-button edit bg-grey-2"
              onClick={() => handleChange("edit", true)}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../theme/assets/edit-mobile-button-teal-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className="save-button bg-una capitalize"
              onClick={() => handleChange("isModal", true)}
            >
              {STRINGS.delete}
            </button>
            <button
              className="save-button bg-aqua capitalize"
              onClick={() => handleChange("edit", true)}
            >
              {STRINGS.edit}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame scroll">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.schoolName}</div>
            <div className="value-label capitalize bold">{issuerName}</div>
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{`${STRINGS.degree}/${STRINGS.program}`}</div>
            <div className="value-label capitalize bold">{degree[title]}</div>
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.graduationDate}
            </div>
            <div className="value-label capitalize bold">
              {moment(issuedOn).format("MMM DD, YYYY")}
            </div>
          </div>

          <div className="read-value-frame empty" />

          <div className="read-value-frame empty" />

          <div className="read-value-frame empty" />

          <div className="document-images-frame">
            <DocumentDisplay
              docUrl={imageUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title}`}
            />
          </div>
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.documentDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.cancel}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.delete}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange("isModal", false)}
      />
    </div>
  );
};

const Edit = (props) => {
  const { back, handleChange, handlePlace, match, save, state } = props;
  const { file, loading, imageUrl, issuerName, issuedOn, mobile, title } =
    state;

  let existing = match.params.document !== "create",
    valid = issuerName && title && issuedOn;

  return (
    <div className="document-edit-view">
      {loading ? (
        <LoadingPane style={{ background: "rgba(0,0,0,0.1)" }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div
            className="mobile-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            <div className="mobile-button-icon">
              <img
                src={require("../../../theme/assets/buttons/button-x-icon-2x.png")}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button
            className="back-button bg-grey-2"
            onClick={() => (existing ? handleChange("edit", false) : back())}
          >
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${
          existing ? STRINGS.edit : STRINGS.add
        } ${STRINGS.education}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className={`mobile-button save ${
                valid ? "bg-aqua" : "bg-disabled"
              }`}
              onClick={save}
            >
              <div className="mobile-button-icon">
                <img
                  src={require("../../../theme/assets/buttons/button-checkmark-icon-2x.png")}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className={`save-button ${valid ? "bg-aqua" : "bg-disabled"}`}
              onClick={save}
            >
              {STRINGS.save}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame">
        <div className={mobile ? "document-frame mobile" : "document-frame"}>
          <div className="key-value-frame">
            <div className="value-label capitalize">{STRINGS.schoolName}</div>

            <AutoComplete
              className="key-value-input"
              onChange={handlePlace}
              placeholder={`${STRINGS.enter} ${STRINGS.schoolName}`}
              defaultValue={issuerName || ""}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{`${STRINGS.degree}/${STRINGS.program}`}</div>

            <SelectMenu
              className="key-value-menu"
              value={title || "hint"}
              options={[
                { id: "associate", value: STRINGS.associateDegree },
                { id: "bachelor", value: STRINGS.bachelorDegree },
                { id: "master", value: STRINGS.masterDegree },
                { id: "doctor", value: STRINGS.doctoralDegree },
              ]}
              onChange={(e) => handleChange("title", e.target.value)}
              placeholder={`${STRINGS.selectDegreeOrProgram}`}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label">{STRINGS.graduationDate}</div>

            <DatePicker
              defaultValue={issuedOn}
              onChange={(date) => handleChange("issuedOn", date)}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label">{STRINGS.photoOfDiploma}</div>

            <div
              className={`key-value-file-input ${
                file || imageUrl ? "" : "bg-placeholder"
              }`}
            >
              <input
                className="file-input"
                onChange={(e) => {
                  const supportedTypes = [
                    "image/png",
                    "image/jpeg",
                    "application/pdf",
                  ];
                  const file = e.target.files[0];
                  const reader = new FileReader();

                  reader.onload = (response) => {
                    if (supportedTypes.includes(file.type)) {
                      handleChange('file', {
                        base64: response.target.result,
                        name: file.name,
                        type: file.type,
                      });
                    } else {
                      window.alert(`${file.type} is not supported.`);
                    }
                  };

                  reader.readAsDataURL(file);
                }}
                type="file"
              />

              <div className="file-label ellipsis">
                {file ? file.name : imageUrl ? imageUrl : STRINGS.selectFile}
              </div>

              <button
                className={`file-button capitalize ${
                  file ? "bg-grey-2" : "bg-aqua"
                }`}
              >
                {STRINGS.chooseFile}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationDocument;
